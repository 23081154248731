import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CrudService } from '../services/crud.service';
import { BuyingGroups } from '../models/buying-groups.model';
import { DxBoxModule, DxButtonModule, DxDataGridComponent, DxDataGridModule, DxLoadPanelModule, DxSelectBoxComponent, DxSelectBoxModule, 
  DxValidationSummaryModule, DxValidatorComponent, DxValidatorModule } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { PromotionType } from '../models/promotion-type.model';
import { NotifyService } from '../services/notify.service';

@Component({
    selector: 'app-import-promotion',
    templateUrl: './import-promotion.component.html',
    styleUrls: ['./import-promotion.component.css'],
    imports: [ DxBoxModule, DxSelectBoxModule, DxValidatorModule, DxButtonModule, DxDataGridModule, DxValidationSummaryModule, DxLoadPanelModule ],
    standalone: true
})
export class ImportPromotionComponent {
  @Input() promotion_name: string;
  @Input() promotion_id: number;
  @Output() importPromotionValues: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('buyingGroupsSelectBox', { static: false }) buyingGroupsSelectBox: DxSelectBoxComponent;
  @ViewChild('targetValidator', { static: false }) validator: DxValidatorComponent;
  apiUrl: string = environment.baseApiUrl;
  dataSource: any[] = [];
  buyingGroups: BuyingGroups[] = [];
  promotionTypes: PromotionType[] = [];
  buying_group_id: number = 0;
  ignore_slot_types: number = 0;
  buttonDisabled: boolean = true;
  saveButtonDisabled: boolean = true;
  loading: boolean = false;

  constructor(private crudService: CrudService,
    private notifyService: NotifyService ) {
    this.crudService.getData(`${this.apiUrl}buying-groups`).subscribe((data: any) => {
      this.buyingGroups = data
    });

    this.crudService.getData(`${this.apiUrl}promotion-types`).subscribe((data: any) => {
      this.promotionTypes = data
    });
  }

  onValueChanged(e: any) {
    this.buying_group_id = e.value;
    this.buttonDisabled = false;
  }

  onSetValue(e: any) {
    if(e.data.hasOwnProperty('promotion_type') && e.data.hasOwnProperty('description')) {
      this.saveButtonDisabled = false;
    }
  }

  onValueChangedIgnoreSlotTypes(e: any) {
    this.ignore_slot_types = e.value ? 1 : 0;
  }

  cancel() {
    this.buying_group_id = 0;
    this.ignore_slot_types = 0;
    this.dataSource = [];
    this.buyingGroupsSelectBox.instance.reset();
    this.buttonDisabled = true;
    this.saveButtonDisabled = true;
    this.importPromotionValues.emit(false);
  }

  async saveAndClose() {
    if (this.dataGrid.instance.hasEditData()) {
      this.saveButtonDisabled = true;
      this.loading = true;
      const data = this.dataGrid.instance.getVisibleRows();
      const insertPromises = data.map((item: any) => {
        return new Promise((resolve, reject) => {
          if (!(item.data.hasOwnProperty('promotion_type')) || !(item.data.hasOwnProperty('description'))) {
            this.notifyService.tellUser({ success: false, message: 'Please enter Promotion Type and Description' });
            return reject('Missing Promotion Type or Description');
          }
          if (item.data.promotion_type !== null && item.data.description !== null && item.data.start_date !== null && item.data.end_date !== null
            && item.data.buying_start !== null && item.data.buying_end !== null) {
            let foundGroup = this.buyingGroups.find((group: any) => group.id === this.buying_group_id);
            const account_code = foundGroup ? foundGroup.account_code : '';
            const data: any = {};
            data.promotion_id = this.promotion_id;
            data.promotion_type = item.data.promotion_type;
            data.description = item.data.description;
            data.promotion_start = item.data.start_date;
            data.promotion_end = item.data.end_date;
            data.buying_start = item.data.buying_start;
            data.buying_end = item.data.buying_end;
            data.buying_group_id = this.buying_group_id;
            data.account_code = account_code;
            data.promo_group_ids = item.data.promo_group_list;
            data.slot_type = item.data.slot_type;
            this.crudService.insertData(`${this.apiUrl}import-promotion`, data).subscribe(
              (data: any) => {
                resolve(data);
              },
              (error: any) => {
                console.log(error);
                reject(error);
              }
            );
          } else {
            resolve(null);
          }
        });
      });
  
      try {
        await Promise.all(insertPromises);
        console.log('All insert operations completed. Importing promotion details group...');
        this.crudService.newGetData(`${this.apiUrl}import-promotion-details-group?promotion_id=${this.promotion_id}`).subscribe();
        this.loading = false;
        this.cancel();
      } catch (error) {
        console.log('Error during insert operations:', error);
      }
    }
  }

  async getImportData() {
    try {
      this.dataSource = [];
      this.loading = true;
      const data = await this.crudService.getData(`${this.apiUrl}request-import-data?promotion_name=${encodeURIComponent(this.promotion_name.trim())}` +
        `&buying_group_id=${this.buying_group_id}&ignore_slot_type=${this.ignore_slot_types}`).toPromise();
      this.dataSource = data.data;
      this.dataGrid.instance.refresh();
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  importHeaders(e: any) {
    console.log(e);
    e.changes.forEach((change: any) => {
      const data: any = {};
      console.log(change);
      data.promotion_id = this.promotion_id;
      data.promotion_type = typeof change.data.promotion_type !== 'undefined' ? change.data.promotion_type : change.key.promotion_type;
      data.description = typeof change.data.description !== 'undefined' ? change.data.description : change.key.description;
      data.promotion_start = typeof change.data.start_date !== 'undefined' ? change.data.start_date : change.key.start_date;
      data.promotion_end = typeof change.data.end_date !== 'undefined' ? change.data.end_date : change.key.end_date;
      data.buying_start = typeof change.data.buying_start !== 'undefined' ? change.data.buying_start : change.key.buying_start;
      data.buying_end = typeof change.data.buying_end !== 'undefined' ? change.data.buying_end : change.key.buying_end;
      data.buying_group_id = this.buying_group_id;
      data.promo_group_ids = change.key.promo_group_list;
      console.log(data);
      try {
        this.crudService.insertData(`${this.apiUrl}import-promotion`, data).subscribe((data: any) => {
          console.log(data);
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

}
