import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import { DxButtonModule, DxDataGridModule, DxPopupComponent, DxPopupModule } from 'devextreme-angular';
import { NotifyService } from 'src/app/services/notify.service';
import { ProductSelectionComponent } from 'src/app/product-selection/product-selection.component';

@Component({
    selector: 'app-mix-match-item',
    templateUrl: './mix-match-item.component.html',
    styleUrls: ['./mix-match-item.component.css'],
    imports: [ DxDataGridModule, DxButtonModule, DxPopupModule, ProductSelectionComponent ],
    standalone: true
})
export class MixMatchItemComponent {
  @Input() key: number;
  @Input() level: number;
  @ViewChild('productSelection', {static: false}) productSelection: DxPopupComponent;
  private apiUrl : string = environment.baseApiUrl;
  items: any[] = [];
  itemsDataSource: DataSource;
  parent: string = 'mix-match'
  constructor(private crudService: CrudService, private notifyService: NotifyService) {}

  async ngAfterViewInit() {
    await this.loadData();
  }

  async loadData()
  {
    console.log('Key: ', this.key);
    const mixMatchLevels = await this.crudService.getData(`${this.apiUrl}mix-match-items?header_id=${this.key}&selection_level=${this.level}`).toPromise();
    this.items = mixMatchLevels;
    console.log('Items: ', this.items);
    this.itemsDataSource = await new DataSource({
      store: new ArrayStore({
        data: this.items
      })
    });
  }

  onRowRemoving = (e: any) => {
    this.crudService.deleteData(`${this.apiUrl}mix-match-items`, e.data.id)
      .subscribe((result: any) => {
        this.notifyService.tellUser(result);
      });
  }

  openProductSelection = (e: any) => {
    this.productSelection.instance.show();
  }

  closeProductSelection = () => {
    this.productSelection.instance.hide();
  }

}
