import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { CrudService } from '../services/crud.service';
import { WeekRangeService, WeekRange } from '../services/week-range.service';
import { environment } from 'src/environments/environment';
import { WeekRangeSharedService } from '../services/week-range-shared.service';
import { ConfigService } from '../services/config.service';
import { DateService } from '../services/date.service';
import { DxButtonModule, DxLookupModule } from 'devextreme-angular';

@Component({
    selector: 'app-week-range-selector',
    templateUrl: './week-range-selector.component.html',
    styleUrls: ['./week-range-selector.component.css'],
    imports: [ DxLookupModule, DxButtonModule ],
    standalone: true
})
export class WeekRangeSelectorComponent implements OnInit {
  @Output() weekRangeValues: EventEmitter<WeekRangeValues> = new EventEmitter<WeekRangeValues>();
  calendarWeeks: CalendarWeek[];
  weekRange: WeekRange[];
  private apiUrl: string = environment.baseApiUrl;
  isStartWeekSelected: boolean = false;
  isNumberOfWeeksSelected: boolean = false;
  selectedDayIndex: number = 0;
  dataStartDate: string = '';
  private displayExprCache: Map<string, string> = new Map();

  @ViewChild('startWeekLookup') startWeekLookup: any;
  @ViewChild('numberOfWeeks') numberOfWeeks: any;
item: string|Function|undefined;
getDisplayExpression:any;
  constructor(
    private crudService: CrudService,
    private weekRangeService: WeekRangeService,
    private weekRangeSharedService: WeekRangeSharedService,
    private configService: ConfigService,
    private dateService: DateService) 
    {
      this.getDisplayExpression = this.getDisplayExpr.bind(this);
    }

  async ngOnInit() {
    this.dataStartDate = await this.getDataStartDate();
    const take = 110;
    const skip = 0;
    this.calendarWeeks = await this.crudService.getData(`${this.apiUrl}calendar-weeks?limit=${take}&offset=${skip}&start_date=${this.dataStartDate}`).toPromise();
    localStorage.setItem('calendar-weeks', JSON.stringify(this.calendarWeeks));
    this.weekRange = this.weekRangeService.getWeekRange();

    this.startWeekLookup.valueChange.subscribe((value: boolean) => {
      this.isStartWeekSelected = value != null;
    });

    this.numberOfWeeks.valueChange.subscribe((value: boolean) => {
      this.isNumberOfWeeksSelected = value != null;
    });
    await this.getConfigString('WeekStartIndex');
  }

  async getDataStartDate() {
    let startDate = this.dateService.twoWeeksAgo(new Date());
    const config = await this.configService.GetConfigByKey('DataStartDate');
    if(config.length !== 0){
      startDate =  config[0].config_string;
    }
    return startDate;
  }

  async getConfigString(config: string) {
    try {
      const response = await this.configService.GetConfigByKey(config);
      if (response.length > 0 && response[0].config_string) {
        this.selectedDayIndex = parseInt(response[0].config_string, 10);
      } else {
        console.log('No config found or missing config_string');
        this.selectedDayIndex = 0;
      }
    } catch (error) {
      console.error('Failed to get config string:', error);
      this.selectedDayIndex = 0;
    }
  }

  getDisplayExpr = (item: CalendarWeek): string => {
    if (!item) {
      return '';
    }

    const cacheKey = `${item.week_number}-${item.start_date}-${item.end_date}`;
    if (this.displayExprCache.has(cacheKey)) {
      return this.displayExprCache.get(cacheKey)!;
    }

    const displayExpr = `w${item.week_number}: ${this.formatDateDayMonth(item.start_date)} - ${this.formatDateDayMonth(item.end_date)}`;
    this.displayExprCache.set(cacheKey, displayExpr);
    return displayExpr;
  }

  formatDateDayMonth = (date: string): string => {
    const dateParts = date.split('-');
    if (dateParts.length === 3) {
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      const newDate = new Date(year, month, day);
      newDate.setDate(newDate.getDate() + this.selectedDayIndex);
      return newDate.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year:"2-digit" });
    }
    return '01-01-2023';
  }

  updateGrid(startWeek: string, numberOfWeeks: number) {
    if (!this.isStartWeekSelected || !this.isNumberOfWeeksSelected) {
      return;
    }

    console.log(`Start week: ${startWeek}, Number of weeks: ${numberOfWeeks}`);
    const weekRangeValues = { startWeek, numberOfWeeks, close: true };
    this.weekRangeValues.emit(weekRangeValues);
    this.weekRangeSharedService.setSelectedWeekRange(weekRangeValues);
  }
}

export class CalendarWeek {
  id: number;
  week_number: number;
  start_date: string;
  end_date: string;
  year: string;
  created_at: string;
  updated_at: string;
}

export class WeekRangeValues {
  startWeek: string;
  numberOfWeeks: number;
  close: boolean;
}
