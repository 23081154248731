import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { ConfigService } from 'src/app/services/config.service';

import { LoadingService } from '../../report-loading.service';
import { DateService } from 'src/app/services/date.service';
import { CostServiceService } from 'src/app/services/cost-service.service';
const pdf = pdfMake;
pdf.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-promotion-confirmation-ilr',
  templateUrl: './promotion-confirmation-ilr.component.html',
  styleUrls: ['./promotion-confirmation-ilr.component.css']
})
export class PromotionConfirmationIlrComponent {
  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  sortedByTypeData: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  disclaimer: string = '';
  excludePrice:boolean;
  excludeEdlp:boolean;
  constructor(private dateService:DateService,
        private crudService: CrudService,
        private groupProductService: GroupProductService,
        private dateFormatService: DateFormatService,
        private base64Image: Base64Images,
        private reportStylesService: ReportStylesService,
        private configService: ConfigService,
        private loadingService: LoadingService,
        private costService: CostServiceService)
  {
    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');
  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }

    //
  }
pagePromotion: string = '';
promotionIndex: number = 0;
filtered_by:string = '';
currentStartDate:string;
currentEndDate:string;
  transformDataForPdfMake(promotions: any[], supplier_name:string): any[] {
    return promotions.flatMap(promotion => {  // Use flatMap to flatten the resulting array
      let headers: any[];

      let colspan: number;
      if(this.excludePrice == false){
      headers = [
          'Order Code', 'Product Description', 'Pack Qty','Buying Dates', 'Total Promo Allow', 'Inv Price', 'Multi Buy', 'Multi Buy Price', 'Price inc GST', 'GP%', 'Notes'
        ];
        colspan = 11;
      }
      else{
        headers = [
          'Order Code', 'Product Description', 'Pack Qty','Buying Dates', 'Total Promo Allow', 'Inv Price', 'Multi Buy', 'Notes'
        ];
        colspan = 8;
      }
      const styledHeaders = headers.map(header => ({ text: header, style: 'tableHeader' }));

      const supplierRow:any = [
        { colSpan: colspan, text: promotion.promotion, style: 'supplierStyle' },
        ...Array(colspan - 1).fill({ text: '', style: 'supplierStyle' })
    ];




          // Map over the products of the supplier

          promotion.products.sort((a: any, b: any) => a.product_name.localeCompare(b.product_name));
          const productRows = promotion.products.map((product: any) => {
            const allowance_group_filtered = Number(product.allowance_group).toFixed(2);
            let nuc:number = 0;

            // Convert string values to numbers
            const costExGst = parseFloat(product.cost_ex_gst);
            const allowanceTotal = parseFloat(product.allowance_total);
            const allowanceUniversal = parseFloat(product.allowance_universal);
            this.currentStartDate = product.promotion_start;
            this.currentEndDate = product.promotion_end;
            if (product.has_wet === 1) {
              if (!isNaN(costExGst) && !isNaN(allowanceTotal) && !isNaN(allowanceUniversal)) {
                  let calculation = costExGst - (allowanceTotal + allowanceUniversal);
                  nuc = Number(calculation + (0.29 * calculation));  // Keep as a number for any further calculations
                  let nucFormatted = nuc.toFixed(2);  // Use nucFormatted for display purposes only
              } else {
                  console.log('Invalid numeric values for calculation.');
              }
          } else {
              if (!isNaN(costExGst) && !isNaN(allowanceTotal) && !isNaN(allowanceUniversal)) {
                  nuc = Number(costExGst - (allowanceTotal + allowanceUniversal));  // Keep as a number
                  let nucFormatted = nuc.toFixed(2);  // Format for display
              } else {
                  console.log('Invalid numeric values for calculation.');
              }
          }
          let gpPecent = String(this.costService.calculateGpPercent(product)).replace('%', '');

          if(this.excludePrice == false){
            return [

                { text: product.product_code, style: 'bodyStyle', alignment: 'center' }, //order code
                { text: product.product_name, style: 'bodyStyle' }, // Product Description
                { text: product.packs_per_carton, style: 'bodyStyle', alignment: 'center' }, // Pack Qty
                { text: `${this.dateFormatService.changeDateFormat(product.buying_start)} - ${this.dateFormatService.changeDateFormat(product.buying_end)}`, style: 'bodyStyle',alignment: 'center' }, // Date
                { text: `$${Number(product.allowance_total).toFixed(2)}`, style: 'bodyStyle', alignment: 'center' }, // Total Promo Allow
                { text: `$${(nuc / product.packs_per_carton).toFixed(2)}`, style: 'bodyStyle', alignment: 'center' }, // Inv Price
                { text: product.multi_buy, style: 'bodyStyle', alignment: 'center' }, // Multi Buy
                { text: `${product.mix_match_price ? '$'+Number(product.mix_match_price).toFixed(2) : ''}`, style: 'bodyStyle', alignment: 'center' }, // Multi Buy Price
                { text: `${product.mix_match_price ? '$'+Number(product.rrp).toFixed(2) : '$'+Number(product.sell_price).toFixed(2)}`, style: 'bodyStyle', alignment: 'center' }, // Price inc GST
                { text: `${Math.round(Number(gpPecent)) || 0}%`, style: 'bodyStyle', alignment: 'center'}, // GP%
                { text: product.comment, style: 'bodyStyle' }, // Notes

            ];
          } else {
            return [

                { text: product.product_code, style: 'bodyStyle',alignment: 'center' }, //order code
                { text: product.product_name, style: 'bodyStyle' }, // Product Description
                { text: product.packs_per_carton, style: 'bodyStyle',alignment: 'center' }, // Pack Qty
                { text: `${this.dateFormatService.changeDateFormat(product.buying_start)} - ${this.dateFormatService.changeDateFormat(product.buying_end)}`, style: 'bodyStyle',alignment: 'center' }, // Date
                { text: `$${Number(product.allowance_total).toFixed(2)}`, style: 'bodyStyle',alignment: 'center' }, // Total Promo Allow
                { text: `$${(nuc / product.packs_per_carton).toFixed(2)}`, style: 'bodyStyle',alignment: 'center' }, // Inv Price
                { text: product.multi_buy, style: 'bodyStyle',alignment: 'center' }, // Multi Buy
                { text: product.comment, style: 'bodyStyle' },   // Notes
            ];
          }



          // Return the supplier row followed by its product rows

      });
        //const styledRows = allRows.map((row:any) => this.styleRow(row, 'bodyStyle'));
        const newTable =  [supplierRow, ...productRows];
        const subheader = {
          stack: [
            {
              columns: [
                {
                  text: `${this.name}`,
                  style: 'header',
                  alignment: 'left',
                },
                {
                    image: this.base64Image.getBase64Image('LL'),
                    width: 150,
                    alignment: 'right',
                    margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
                }

              ]
            }


            ]
          };


 const secondHeader = {
  stack: [
    {
      columns: [
        {
          text: `${this.promotionName}`,

          style: 'subheader',
          alignment: 'left',
          margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
        },
        {
          text: `${supplier_name}`,

        },
        {
          text: `${this.filtered_by}`,

        },
        {
          text: `Promotion Dates: ${this.dateFormatService.changeDateFormat(this.currentStartDate)} - ${this.dateFormatService.changeDateFormat(this.currentEndDate)}`,
          style: 'subheader',
          alignment: 'right',
          margin: [0, 0, 35, 0]  // Adjust margin as needed for the text
        }
      ]
    }
  ]
 }


let widths:any;

if(this.excludePrice == false){
  widths = [ 40, 'auto', 40, 90, 40, 40, 40, 40, 40, 'auto', '*'];
}
else{
  widths = [ 40, 'auto', 40, 90, 40, 40, 40, '*'];
}

if(this.promotionIndex < (promotions.length - 1)) {
  this.promotionIndex++;
        return [
          subheader,
          secondHeader,
          {
              table: {
                  headerRows: 1,
                  widths: widths,
                  body: [styledHeaders, ...newTable]
              },
              pageBreak: 'after',
              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                },

          }

        }

      ];
      }
      else{
        return [
          subheader,
          secondHeader,
          {
              table: {
                  headerRows: 1,
                  widths: widths,
                  body: [styledHeaders, ...newTable]
              },

              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                },

          }

        }

      ];
      }
    });

}

styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}

async getConfigString() {
  try {
      // Fetching configuration data
      const response = await this.configService.GetConfigByKey('ReportDisclaimer');

      // Check if the response array is not empty and has the property
      if (response.length > 0 && response[0].config_string) {
          this.disclaimer = "*" + response[0].config_string;
          console.log('Disclaimer set:', this.disclaimer);
      } else {
          console.log('No config found or missing config_string');
          this.disclaimer = '';
      }
  } catch (error) {
      console.error('Failed to get config string:', error);
  }
}


async pdfCreator(promotions: any[], supplier_name: string) {
  //await this.getConfigString();
  const  transformedDataArray = await this.transformDataForPdfMake(promotions, supplier_name);

  const styles: any = this.reportStylesService.getStyles();

  const documentDefinition:any = {
    pageMargins: [40, 40, 40, 65],
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `Promotion Confirmation ${this.promotionName}`,
      },

      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },

                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };
// Dynamically generate a filename based on specific data
  const filename = `Promotion_${this.promotionName}_${supplier_name}_${this.groupNameSelected}.pdf`;

  pdfMake.createPdf(documentDefinition).download(filename);
  this.promotionIndex = 0;
  this.loadingService.toggleLoading(false);

}
groupNameSelected:any = null;
async getPromoData(e: any){
  let promoDataEDLPChecked: any;
  this.disclaimer = e.disclaimer;
    await this.crudService.getData(`${this.apiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).subscribe((result: any) => {
      this.promotionName = result[0].promotion_name;
      this.excludePrice = e.excludePrice;
      this.excludeEdlp = e.excludeEdlp;
      this.groupNameSelected = e.groupSelectedName;
      const sortedHeaders = result.sort((a:any, b:any) => {
        const descriptionA = a.description || '';
        const descriptionB = b.description || '';
        return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
      });


      this.headers = sortedHeaders;
    console.log("headers:",this.headers);
    let requestURL = `${this.promoDetailsUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&supplier_code=${e.suppliers.value}`;

    if(this.headers[0].edlp == 1){
      requestURL = `${this.promoDetailsUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&supplier_code=${e.suppliers.value}`
    }
      if(!e.suppliers[1]){
        if(e.suppliers.value != null && e.groupSelected != null) {
          this.filtered_by = e.buyingGroupFiltered.buying_group_name;
          this.crudService.getData(requestURL).subscribe((result: any) => {

            if(result.data.length > 0){
              const firstHeaderId = result.data[0].promotion_header_id;
              promoDataEDLPChecked = result.data.map((promoProduct: any) => {
                  return {
                    ...promoProduct,
                    promotion_header_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
                  };
                });
              } else {
                return this.loadingService.toggleLoading(false);
              }




            let data = this.groupProductService.groupByAlphaNumeric(promoDataEDLPChecked, (product: any) => product.description, this.headers);

            // Step 1: Extract all promotionIds from the data, including duplicates
          const promotionIds = data.map((item:any) => item.promotionId.toString());

          // Step 2: Map each promotionId to its corresponding header by creating a mapping of promotionId to headers
          const headersMap = this.headers.reduce((map:any, header) => {
              map[header.id.toString()] = header;
              return map;
          }, {});

          // Step 3: Build the final headers list by iterating over the promotionIds and collecting the corresponding headers
          this.headers = promotionIds.map((id:any) => {
              return headersMap[id];
          }).filter((header:any) => header !== undefined); // Ensure no undefined headers are included if no match was found



          // Continue with PDF creation
          this.pdfCreator(data, result.data[0].supplier_name);

          });
        }
      }
      else{

        for(const element of e.suppliers) {
           new Promise(resolve => setTimeout(resolve, 600));
          if(e.groupSelected != null) {




          this.filtered_by = e.buyingGroupFiltered.buying_group_name;
          this.crudService.getData(`${this.promoDetailsUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&supplier_code=${element.supplier_code}`).subscribe((result: any) => {



            const firstHeaderId = result.data[0].promotion_header_id;
            promoDataEDLPChecked = result.data.map((promoProduct: any) => {
                return {
                  ...promoProduct,
                  promotion_header_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
                };
              });

              let data = this.groupProductService.groupByAlphaNumeric(promoDataEDLPChecked, (product: any) => product.description, this.headers);

              // Step 1: Extract all promotionIds from the data, including duplicates
          const promotionIds = data.map((item:any) => item.promotionId.toString());

          // Step 2: Map each promotionId to its corresponding header by creating a mapping of promotionId to headers
          const headersMap = this.headers.reduce((map:any, header) => {
              map[header.id.toString()] = header;
              return map;
          }, {});

          // Step 3: Build the final headers list by iterating over the promotionIds and collecting the corresponding headers
          this.headers = promotionIds.map((id:any) => {
              return headersMap[id];
          }).filter((header:any) => header !== undefined); // Ensure no undefined headers are included if no match was found


          // Continue with PDF creation
          this.pdfCreator(data, result.data[0].supplier_name);


          });
        }
    }

      }
    });





  }

@Input() selectedFilter: string = '';
@Input() selectedFilter2: string = '';
handleSelectedFilter(filter: string, filter2?:string) {
  this.selectedFilter = filter;
  if(filter2){


  this.selectedFilter2 = filter2;
  }
  }

}
