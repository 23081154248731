<div class="promotionGroupsGrid">
  <dx-data-grid #promotionGroupGrid
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [height]="'95%'">

    <dxo-editing
        mode="batch"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true">
    </dxo-editing>

    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="id" dataType="integer" [allowEditing]="false" width="60"></dxi-column>

    <!-- Promotion Group Name Column with Validator -->
    <dxi-column dataField="promotion_group_name" dataType="string" caption="Name">

        <dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>

    </dxi-column>

    <!-- Product Group Code Column with Validator -->
    <dxi-column dataField="planner_category_id" dataType="integer" caption="Planning Category">

        <dxi-validation-rule type="required" message="Planning category is required"></dxi-validation-rule>

      <dxo-lookup
        [dataSource]="promotionCategories"
        valueExpr="id"
        displayExpr="planning_category_name">
      </dxo-lookup>
    </dxi-column>

    <!-- Supplier Code Column with Validator -->
    <dxi-column dataField="supplier_code" dataType="string" caption="Supplier">

        <dxi-validation-rule type="required" message="Supplier is required"></dxi-validation-rule>

      <dxo-lookup
        [dataSource]="suppliers$ | async"
        valueExpr="supplier_code"
        displayExpr="supplier_name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="sell_price" dataType="number" [format]="currencyFormat" caption="Promo Price"></dxi-column>
    <dxi-column dataField="allowance" dataType="number" [format]="currencyFormat" caption="Allowance"></dxi-column>

    <ng-container *ngFor="let column of rebateNames$ | async">
      <dxi-column
        [dataField]="column.rebate_name"
        [format]="currencyFormat"
        [dataType]="'number'"
        [allowEditing]="true">
    </dxi-column>
    </ng-container>

    <dxi-column dataField="max_rebate_qty" dataType="number" caption="Max Rebate Qty"></dxi-column>
    <dxi-column dataField="active" dataType="boolean" caption="Active"></dxi-column>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
    <div *dxTemplate="let item of 'detail'">
        <app-promotion-group-items [key]="item.data.id"></app-promotion-group-items>
    </div>
  </dx-data-grid>
</div>
