<div class="grid">
<dx-data-grid #slotTypesGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  (onCellPrepared)="onCellPrepared($event)"
  [height]="'90%'"
  [loadPanel]="{ enabled: false }"
>
  <dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="code" dataType="string" caption="Code" width="15%">
    <dxi-validation-rule type="required" message="slotting-type id is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="name" dataType="string" caption="Name">
    <dxi-validation-rule type="required" message="slotting-type name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="colour" caption="Colour" editCellTemplate="colorCellTemplate" cellTemplate="colorBoxViewTemplate" width="70">
    <div *dxTemplate="let cellData of 'colorBoxViewTemplate'"></div>
    <div *dxTemplate="let cellData of 'colorCellTemplate'" style="text-align:center">  
      <dx-color-box [value]="cellData.value" (onValueChanged)="onColorValueChanged($event, cellData)"></dx-color-box>  
    </div>
  </dxi-column>
  <dxi-column dataField="week_count" dataType="number" caption="Weeks" width="80">
    <dxo-lookup [dataSource]="weekCount"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="span_promotion" dataType="boolean" caption="Span" width="70"></dxi-column>
  <dxi-column dataField="store_position_id" caption="Store Position" width="150">
    <dxo-lookup [dataSource]="storePositions" [allowClearing]="true" valueExpr="id" displayExpr="position_name"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
</dx-data-grid>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
