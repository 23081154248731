import { Component, ViewChild } from '@angular/core';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { NotifyService } from 'src/app/services/notify.service';
import { MixMatchLevelComponent } from '../mix-match-level/mix-match-level.component';

@Component({
    selector: 'app-mix-match',
    templateUrl: './mix-match.component.html',
    styleUrls: ['./mix-match.component.css'],
    imports: [ DxDataGridModule, MixMatchLevelComponent ],
    standalone: true
})
export class MixMatchComponent {
  @ViewChild("mixMatchGrid", { static: false }) public dataGrid: DxDataGridComponent;
  editMixMatch: boolean = false;
  dataSource: CustomStore;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private horizontalMenuService: HorizontalMenuService,
    private confirmationService:ConfirmationService,
    private crudService: CrudService,
    private notifyService:NotifyService) {
    this.horizontalMenuService.editMixMatch$.subscribe((value: boolean) => {
      this.editMixMatch = value;
      if(this.editMixMatch) {
        this.dataSource = new CustomStore<any>({
          key: 'id',
          load: async () => {
            return await new Promise((resolve) => {
              this.crudService.getData(`${this.apiUrl}mix-match-headers`).subscribe((result: any) => {
                resolve({data: result, totalCount: result.length});
              })
            })
          },
          insert: async (values) => {
            const userDetails = localStorage.getItem('userDetails');
            const parsedDetails = JSON.parse(userDetails || '{}');
            values.group_id = parsedDetails.data.group_id;
            values.location_id = parsedDetails.data.location_id;
            return await new Promise(async (resolve) => {
              await this.crudService.insertData(`${this.apiUrl}mix-match-headers`, values).subscribe((result: any) => {
                this.notifyService.tellUser(result);
                resolve(result);
              });
            });
          },
          update: async (key, values) => {
            return await new Promise(async (resolve) => {
              await this.crudService.updateData(`${this.apiUrl}mix-match-headers`, key, values).subscribe((result: any) => {
                this.notifyService.tellUser(result);
                resolve(result);
              });
            });
          },
          remove: async (key) => {
            return await new Promise(async (resolve) => {
              await this.crudService.deleteData(`${this.apiUrl}mix-match-headers`, key).subscribe((result: any) => {
                this.notifyService.tellUser(result);
                resolve(result);
              });
            });
          }
        });
      }
    });
  }

  onHiding(event: any) {
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value: boolean) => {
        this.horizontalMenuService.seteditMixMatch(value);
    });
    this.horizontalMenuService.seteditMixMatch(false);
  }

}
