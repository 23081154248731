
<div class="import-promotion">
    <dx-box direction="row" width="100%" class="box">
        <dxi-item ratio="0" baseSize="auto" class="padding">
            <div class="dx-field">
                <div class="dx-field-label">Buying Group</div>
                <dx-select-box
                    #buyingGroupsSelectBox
                    [dataSource]="buyingGroups"
                    displayExpr="buying_group_name"
                    valueExpr="id"
                    width="300px"
                    validationMessagePosition="left"
                    (onValueChanged)="onValueChanged($event)"
                >
                <dx-validator>
                    <dxi-validation-rule type="required" message="Buying Group is required"></dxi-validation-rule>
                </dx-validator>
            </dx-select-box>
            </div>
        </dxi-item>
        <dxi-item ratio="0" baseSize="auto" class="padding">
            <dx-button text="Suggest Promotions" type="default" width="200" [disabled]="buttonDisabled" (onClick)="getImportData()"></dx-button>
        </dxi-item>
        <dxi-item ratio="8" baseSize="auto"></dxi-item>

        <dx-validation-summary id="summary"></dx-validation-summary>
    </dx-box>
    <div class="data-grid-row">
      <!-- <dx-box direction="row" width="100%" class="box"> -->
        <dx-data-grid
          #dataGrid
          [dataSource]="dataSource"
          [height]="'490px'"
          (onEditingStart)="onSetValue($event)"
          (onSaving)="importHeaders($event)"
        >
        <dxo-toolbar>
          <dxi-item name="revertButton"></dxi-item>
        </dxo-toolbar>
        <dxo-editing
            mode="batch"
            [allowUpdating]="true">
        </dxo-editing>
        <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
        <dxi-column dataField="ref_id" caption="Ref ID" minWidth="90"></dxi-column>
        <dxi-column dataField="promotion_name" caption="Promotion Name" minWidth="180"></dxi-column>
        <dxi-column dataField="promotion_type" dataType="string" caption="Promotion Type" minWidth="250">
            <dxo-lookup
              [dataSource]="promotionTypes"
              valueExpr="id"
              displayExpr="name"></dxo-lookup>
          </dxi-column>
        <dxi-column dataField="description" caption="Description" minWidth="250"></dxi-column>
        <dxi-column dataField="slot_type" caption="Slot Type" minWidth="60"></dxi-column>
        <dxi-column dataField="start_date" dataType="date" caption="Start Date" minWidth="120" format="dd-MM-yyyy"></dxi-column>
        <dxi-column dataField="end_date" dataType="date" caption="End Date" minWidth="120" format="dd-MM-yyyy"></dxi-column>
        <dxi-column dataField="buying_start" dataType="date" caption="Buying Start" minWidth="120" format="dd-MM-yyyy"></dxi-column>
        <dxi-column dataField="buying_end" dataType="date" caption="Buying End" minWidth="120" format="dd-MM-yyyy"></dxi-column>
        <dxi-column dataField="promo_group_list" [visible]="false"></dxi-column>
    </dx-data-grid>
  </div>
  <!-- </dx-box> -->
  <dx-box direction="row" width="100%" class="box">
    <dxi-item ratio="0" baseSize="auto" class="padding">
        <dx-button  class="saveAndClose" text="Save & Close" type="default" width="150" [disabled]="saveButtonDisabled" (onClick)="saveAndClose()"></dx-button>
    </dxi-item>
    <dxi-item ratio="0" baseSize="auto" class="padding">
        <dx-button class="close" text="Close" type="default" width="150" (onClick)="cancel()"></dx-button>
    </dxi-item>
  </dx-box>
</div>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>
