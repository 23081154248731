import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { PromotionPeriod, PromotionPeriodRow } from 'src/app/models/promotion-period.model';
import { PromotionName } from 'src/app/models/promotion-name.model';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config.service';
import { DxDataGridComponent, DxDataGridModule, DxLoadPanelModule, DxSelectBoxModule } from 'devextreme-angular';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ChangeDetectorRef } from '@angular/core';
import { NotifyService } from 'src/app/services/notify.service';
import { DateService } from 'src/app/services/date.service';
import { PromotionService } from 'src/app/services/promotions-data-sync.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

interface PromoPeriodsResponse {
  data: any[];
}

@Component({
    selector: 'app-promotion-periods',
    templateUrl: './promotion-periods.component.html',
    styleUrls: ['./promotion-periods.component.css'],
    providers: [ DateService, DatePipe ],
    imports: [ DxDataGridModule, DxSelectBoxModule, DxLoadPanelModule ],
    standalone: true
})
export class PromotionPeriodsComponent{
  @ViewChild('promotionPeriodsGrid', { static: false }) promotionPeriodsGrid: DxDataGridComponent;
  editPromotionPeriods:boolean = false;
  dataSource: any;
  promoNames: PromotionName[] = [];
  daysOfWeek: any;
  selectedDayIndex: number;
  promoData:any;
  private apiUrl: string = environment.baseApiUrl;
  isLoadingData:boolean = false;
  startDate: string = '';
  promoSequences: { [promoId: string]: number[] } = {};
  constructor(private crudService: CrudService,
    private configService: ConfigService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private cdr: ChangeDetectorRef,
    private notifyService:NotifyService,
    private dateService: DateService, private promotionService:PromotionService, private route: ActivatedRoute,
    private router: Router) {
      this.horizontalMenuService.editPromotionPeriods$.subscribe((value: boolean) => {
        this.editPromotionPeriods = value;
      });
  }
  ngOnInit(){

      // Check if the current route is 'promotion-status'
      const currentRoute = this.route.snapshot.routeConfig?.path;
      if (currentRoute === 'promotion-periods') {
        this.initializeData();
      }


}

promotionNames:any = [];
calendarWeeks:any = [];
async loadPromotionNames() {
  try {
    const response = await this.crudService.getData(this.apiUrl + 'promotion-names').toPromise() as PromoPeriodsResponse;
    this.promotionNames = response.data;
    this.initializePromoDataStore();
  } catch (error) {
    console.error('Error loading promotion names:', error);
  }
}
initializePromoDataStore() {
  this.promoData = {
    store: new CustomStore<any>({
      key: 'id',
      load: () => this.promotionNames,
      byKey: (key) => {
        // Find the item in the preloaded data
        const items = this.promotionNames || [];
        const item = items.find((item:PromotionName) => item.id === key);
        // Return a resolved promise with the found item

        return Promise.resolve(item);
      }
    })
  };
}
initializeData() {
  this.isLoadingData = true;
  this.loadPromotionNames();
  this.dataSource = new CustomStore<any>({
    key: ['id', 'week_number', 'year'],
    load: async (loadOptions) => {
      const take = loadOptions.take || 50; // default to 50 records
      const skip = loadOptions.skip || 0; // default to the first set of records
      this.startDate = await this.getDataStartDate();
      const response = await this.crudService.getData(`${this.apiUrl}calendar-weeks?limit=${take}&offset=${skip}&start_date=${this.startDate}`).toPromise();
      this.calendarWeeks = response;
      this.isLoadingData = false;
      return response;
    },
    // insert: async (values: PromotionPeriod) => {
    //   const userDetails = localStorage.getItem('userDetails');
    //   const parsedDetails = JSON.parse(userDetails || '{}');
    //   values.group_id = parsedDetails.data.group_id;
    //   values.location_id = parsedDetails.data.location_id;
    //   return await new Promise(async (resolve) => {
    //     const result: any = await this.crudService.insertData(this.apiUrl, values)
    //     .subscribe((result: any) => {
    //       this.promotionService.requestRefreshGrid();
    //       resolve(result);
    //     });
    //   });
    // },
    update: async (key, values) => {
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');
      values.group_id = parsedDetails.data.group_id;
      values.location_id = parsedDetails.data.location_id;
      values.week_number = key.week_number;
      values.year = key.year;
      values.calendar_id = key.id;
      console.log('Values:', values);
      return await new Promise((resolve) => {
        const result: any = this.crudService.updateData(`${this.apiUrl}calendar-weeks`, key.id, values)
        .subscribe((result: any) => {
          this.promotionService.requestRefreshGrid();
          resolve(result);
        });
      });
    },
    remove: async (key) => {
      return await new Promise(async (resolve) => {
        const result: any = await this.crudService.deleteData(`${this.apiUrl}promotion-periods`, key)
        .subscribe((result: any) => {
          resolve(result);
        });
      });
    }
  });



  this.daysOfWeek = [{name: 'Monday',
                      number: 0
                    }, {
                      name: 'Tuesday',
                      number: 1
                    }, {
                      name: 'Wednesday',
                      number: 2
                    }, {
                      name: 'Thursday',
                      number: 3
                    }, {
                      name: 'Friday',
                      number: 4
                    }, {
                      name: 'Saturday',
                      number: 5
                    }, {
                      name: 'Sunday',
                      number: 6
                    }];
                    this.getWeekStartConfig();
}

loadData() {
 this.promotionPeriodsGrid.instance.refresh();
}
  setWeekStartConfig(e: any)
  {
    console.log('Week Start Index: ', e);
    if(e.value !== e.previousValue && e.previousValue !== '' && e.previousValue !== undefined) {
      this.configService.updateConfig('WeekStartIndex', e.value);
      this.selectedDayIndex = e.value;
      localStorage.setItem('selectedDayIndex', e.value.toString());
      this.promotionPeriodsGrid.instance.refresh();
    }
  }

  setDataStartDateConfig(e: any)
  {
    console.log('Data Start Date: ', e);
    if(e.value !== e.previousValue && e.previousValue !== '') {
      this.configService.updateConfig('DataStartDate', e.value.start_date);
      this.promotionPeriodsGrid.instance.refresh();
    }
  }

  getFormattedStartDate = (row: PromotionPeriodRow): string => {
    return this.formatDate(row.start_date);
  }

  getFormattedEndDate = (row: PromotionPeriodRow): string => {
    return this.formatDate(row.end_date);
  }

  formatDate = (date: string): string =>
  {
    const dateParts = date.split('-');
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const year = parseInt(dateParts[2], 10);
      const new_date = new Date(day, month, year);
      new_date.setDate(new_date.getDate() + this.selectedDayIndex);
      const formattedDate = new_date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });
      return formattedDate;
    }
    return '01-01-2023';
  }

  async getWeekStartConfig()
  {
    try {
      let option = [];
      const config = await this.configService.GetConfigByKey('WeekStartIndex');
      if(config.length !== 0){
        option = await this.daysOfWeek.filter((w: any) => w.number.toString() === config[0].config_string);
        this.selectedDayIndex = option[0].number;
        localStorage.setItem('selectedDayIndex', this.selectedDayIndex.toString());
      }
    }
    catch(err){
      console.log(err);
    }
  }

  async getDataStartDate() {
    let startDate = this.dateService.twoWeeksAgo(new Date());
    const config = await this.configService.GetConfigByKey('DataStartDate');
    if(config.length !== 0){
      startDate =  config[0].config_string;
    }
    return startDate;
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.promotionPeriodsGrid, (value:boolean) => this.horizontalMenuService.seteditPromotionPeriods(value))
        .then((canHide:boolean) => {
          //if canHide checks if use can hide profile popup.
          if(canHide){
            this.horizontalMenuService.seteditPromotionPeriods(false);
          }
        });
      }
  isValidSequenceForPromo(promoWeeks: number[]): boolean {
    // Sort the weeks for this promo
    const sortedWeeks = [...promoWeeks].sort((a, b) => a - b);

    // Check for continuity
    for (let i = 1; i < sortedWeeks.length; i++) {
        if (sortedWeeks[i] !== sortedWeeks[i - 1] + 1) {
            return false;
        }
    }
    return true;
}
savePromoSequencesToStorage(): void {
  if (!localStorage.getItem('promoSequences')) {
    localStorage.setItem('promoSequences', JSON.stringify(this.promoSequences));
  }
}
saveEditedPromoSequencesToStorage(): void {
    localStorage.setItem('tempPromoSequences', JSON.stringify(this.promoSequences));
}
isValidPromoSequences(): boolean {
  const allWeeks: number[] = [];
  // Check each promotion's week sequence to ensure it's continuous
  for (const promoId in this.promoSequences) {
      const sortedWeeks = [...this.promoSequences[promoId]].sort((a, b) => a - b);
      for (let i = 1; i < sortedWeeks.length; i++) {
          if (sortedWeeks[i] !== sortedWeeks[i - 1] + 1) {
              return false;
          }
      }
      // Collect all week numbers for the next validation step
      allWeeks.push(...sortedWeeks);
  }
  // Check that no week number appears in more than one promotion
  const uniqueWeeks = [...new Set(allWeeks)];
  return uniqueWeeks.length === allWeeks.length;
}
updatePromoSequences(weekId: number, newPromoId: number): boolean {
  // Remove the week number from its previous promotion's array
  for (const promoId in this.promoSequences) {
      const index = this.promoSequences[promoId].indexOf(weekId);
      if (index !== -1) {
          this.promoSequences[promoId].splice(index, 1);
          // If the promo ID has no weeks left, delete it from the sequences
          if (this.promoSequences[promoId].length === 0) {
              delete this.promoSequences[promoId];
          }
      }
  }
  // Add the week number to its new promotion's array
  if (!this.promoSequences[newPromoId]) {
      this.promoSequences[newPromoId] = [];
  }
  this.promoSequences[newPromoId].push(weekId);
  // Return the validity status
  return this.isValidPromoSequences();
}

 loadPromoSequencesFromStorage() {
  const calenderWeeks = JSON.parse(localStorage.getItem('calender-weeks') || '[]');
  for (const week of calenderWeeks) {
      if (week.promo_id) {
          if (!this.promoSequences[week.promo_id]) {
              this.promoSequences[week.promo_id] = [];
          }

          this.promoSequences[week.promo_id].push(week.id);
      }
  }
  for (const promoId in this.promoSequences) {
      this.promoSequences[promoId].sort((a, b) => a - b);
  }

}
 onEditCanceling(){
  this.promoSequences = JSON.parse(localStorage.getItem('promoSequences') || '{}');
  localStorage.removeItem('tempPromoSequences');

}
handleSequenceValidation = (e: any): boolean => {
  if (!this.promoSequences || Object.keys(this.promoSequences).length === 0) {
    this.promoSequences = JSON.parse(localStorage.getItem('promoSequences') || '{}');
}
  if (this.updatePromoSequences(e.data.id, e.value)) {
      this.saveEditedPromoSequencesToStorage();
      return this.validateAllPromotions().isValid;
  } else {

      return false;
  }
}
// This function will validate all promotions and update the validity state
validateAllPromotions(): { isValid: boolean; invalidPromoId?: string } {
  for (const promoId in this.promoSequences) {
      if (!this.isValidSequenceForPromo(this.promoSequences[promoId])) {
          return { isValid: false, invalidPromoId: promoId }; // return the invalid promotion ID
      }
  }
  return { isValid: true }; // all promotions are valid
}
}
