import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { LoadingService } from '../report-loading.service';
import { DateService } from 'src/app/services/date.service';
import { FilterComponent } from '../filter/filter.component';
import { DatePipe } from '@angular/common';

const pdf = pdfMake;
pdf.vfs = pdfFonts.vfs;

@Component({
    selector: 'app-pos-press-brief-report',
    templateUrl: './pos-press-brief-report.component.html',
    styleUrls: ['./pos-press-brief-report.component.css'],
    providers: [ DateService, DatePipe ],
    imports: [ FilterComponent ],
    standalone: true
})

export class PosPressBriefReportComponent {
  @Input() name: string;
  @Input() reportComponent: string;
  private mediaPlanningUrl: string = environment.baseApiUrl + 'media-planning';
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  dynamicColumns: any = [];
  standardKeys: any = [];
  mediaPlanData: any = [];
  disclaimer:string;
  constructor(private dateService:DateService,private crudService: CrudService,private groupProductService: GroupProductService,private loadingService: LoadingService,private reportStylesService: ReportStylesService, private base64Image: Base64Images, private dateFormatService: DateFormatService)
  {

    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');

  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }

    //
  }

  pagePromotion: string = '';
  promotionIndex: number = 0;
  transformDataForPdfMake(groupedMediaPlans: { [promotionName: string]: any[] }): any[] {
    const headerMapping = {
      promotion_group_name: 'Group Name',
      price: 'Price',
      'TBA': 'Additional Info'
      // Define any additional fixed mappings here
  };

  if (!groupedMediaPlans || Object.keys(groupedMediaPlans).length === 0) {
      console.error('No valid media plans available to transform.');
      return [];
  }

  let tables: any = [];
  const subheader = {
    stack: [
      {
        columns: [
          {
            text: `${this.name}`,
            style: 'header',
            alignment: 'left',
          },
          {
              image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
              width: 150,
              alignment: 'right',
              margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
          }

        ]
      }


      ]
    };
const secondHeader = {
stack: [
{
columns: [
  {
    text: `${this.filtered_by_promotion}`,

    style: 'subheader',
    alignment: 'left',
    margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
  },
  {
    text: `${this.filtered_by}`,

    style: 'subheader',
    alignment: 'right',
    margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
  }
]
}
]
}
  Object.entries(groupedMediaPlans).forEach(([promotionName, plans]) => {
      if (!plans.length) {
          console.error(`No plans available for promotion: ${promotionName}.`);
          return;
      }
 // Sort plans by 'promotion_group_name' within each promotion
 plans.sort((a:any, b:any) => this.groupProductService.naturalSorts(a, b, 'promotion_group_name'));
 
      const examplePlan = plans[0];
      if (!examplePlan) {
          console.error('Invalid plan structure.');
          return;
      }

      const dynamicHeaders = Object.keys(examplePlan).filter(key =>
          !this.standardKeys.includes(key) && examplePlan[key] !== undefined
      ).reduce((acc, key) => ({ ...acc, [key]: key.replace('_', ' ') }), {});

      const headers:any = {
          ...headerMapping,
          ...dynamicHeaders
      };

      const headerOrder = Object.keys(headers);
      const styledHeaders = headerOrder.map(header => ({ text: headers[header], style: 'tableHeader' }));

      let a4Total = 0;
      let c8Total = 0;
   // Adding a subheader row for promotion name and date range
   const promoDateRange = `${this.dateFormatService.changeDateFormat(examplePlan.promotion_start)} - ${this.dateFormatService.changeDateFormat(examplePlan.promotion_end)}`;
   const subHeaderRow = headerOrder.map(header => {
     if (header === 'promotion_group_name') return { text: `${promotionName} (${promoDateRange})`, style: 'subheaderStyle', colSpan: headerOrder.length, alignment: 'left' };
     return { text: '', style: '' };
   }).slice(0, 1);  // Only the first cell needs to display the text and span all columns
      let bodyRows = plans.map(plan => {
          return headerOrder.map(header => {
              let value = plan[header];
              value = value === null || value === undefined ? '' : value.toString();

              if (header === 'A4') a4Total += parseInt(value) || 0;
              if (header === 'C8') c8Total += parseInt(value) || 0;

              return { text: value, style: 'bodyStyle' };
          });
      });

      // Optionally, add rows to show totals
      const totalsRow = headerOrder.map(header => {
          if (header === 'A4') return { text: `Total: ${a4Total}`, style: 'bodyStyle' };
          if (header === 'C8') return { text: `Total: ${c8Total}`, style: 'bodyStyle' };
          return { text: '', style: 'bodyStyle' };
      });
      // Calculate widths: specific widths for static fields, '*' for dynamic fields
      const widths = headerOrder.map(header => {
        if (header in headerMapping) {
          switch (header) {
            case 'price':
              return 'auto'; // Short width for price
            default:
              return 'auto'; // Default width for known static fields
          }
        } else {
          return '*'; // Assign '*' to dynamic fields
        }
      });

      if(this.promotionIndex < (this.dataLength - 1)){
        this.promotionIndex++
        tables.push({
            table: {
                headerRows: 2,
                widths: widths,
                body: [styledHeaders, subHeaderRow, ...bodyRows, totalsRow] // Add totalsRow here
            },
            layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                    return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                }
            },
            pageBreak: 'after'
        });
      }
     
      else{
        tables.push({
          table: {
              headerRows: 2,
              widths: widths,
              body: [styledHeaders, subHeaderRow, ...bodyRows, totalsRow] // Add totalsRow here
          },
          layout: {
              hLineWidth: () => 0,
              vLineWidth: () => 0,
              hLineColor: () => 'white',
              vLineColor: () => 'white',
              fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
              }
          },
       
      });
      }
  });

    return [  subheader,
      secondHeader,
      tables];
}


styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}



pdfCreator(promotions: any) {
  const transformedDataArray = this.transformDataForPdfMake(promotions);
  this.promotionIndex = 0;
  const styles: any = this.reportStylesService.getStyles();


  const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,

      styles: styles,
      border: [false,false,false,false],

      dontBreakRows: false,
      info: {
        title: `POS and press brief report ${this.promotionName}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },
               
                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };
// Dynamically generate a filename based on specific data
  const filename = `Pos_Press_Brief_Report_${this.filtered_by_promotion}_${this.filtered_by}.pdf`;
  pdfMake.createPdf(documentDefinition).download(filename);
  this.loadingService.toggleLoading(false);
  this.dynamicColumns = [];
}

filtered_by: string = '';
filtered_by_promotion: string = '';
dataLength:number;
async getPromoData(e: any) {
  if (!e.groupSelected) {
    return;
  }
  this.disclaimer = e.disclaimer;
  this.filtered_by = e.filtered.buying_group_name;
  this.filtered_by_promotion = e.promotionNameSelected;

  // Fetch media plan data.
  let promoDataWithCoreRange:any;
 
  await this.crudService.getData(`${this.mediaPlanningUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&edlp`).subscribe((result: any) => {
    this.mediaPlanData = result;

    
    const firstHeaderId = result[0].promotion_header_id;

    promoDataWithCoreRange = result.map((promoProduct: any) => {
        return {
          ...promoProduct,
          promotion_header_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
        };
      });
      
    if (e.export == true) {
      
      this.crudService.getExportData(
        `${environment.baseApiUrl}export/media-planning?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`,
        {},
        'blob'
      ).subscribe(
        (response: Blob) => {
              // Create a Blob from the response
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              // Create a download link
              const downloadLink = document.createElement('a');
              const url = window.URL.createObjectURL(blob);
              downloadLink.href = url;
              downloadLink.download = 'Web_POS_Ticket_' + e.promotionNameSelected + '_' + this.filtered_by + '.xlsx';

              // Append the link, trigger the download, then remove the link
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(url);
          },
          (error:any) => {
              console.error('Error:', error);
          }
      );
    }
    // Define any keys that should be excluded from being treated as dynamic columns.
    this.standardKeys = [
      'promotion_period_id', 'promotion_header_id', 'promotion_group_id', 'promotion_group_name',
      'promotion_name', 'promotion_start', 'promotion_end', 'buying_group_name', 'price', 'store_position_name', 'planning_category_name'
    ];

    // Update dynamic columns based on media plan data.
    this.dynamicColumns = []; // Reset or initialize dynamicColumns array
    if (promoDataWithCoreRange && promoDataWithCoreRange.length > 0) {
      Object.keys(promoDataWithCoreRange[0]).forEach(key => {
        if (!this.standardKeys.includes(key) && !this.dynamicColumns.includes(`media_plan_${key}`)) {
          this.dynamicColumns.push(`media_plan_${key}`);
        }
      });
    }
    
    // Group data by 'promotion_name'
    const groupedData = promoDataWithCoreRange.reduce((acc:any, item:any) => {
        // Use promotion_name as the key to group.
        acc[item.promotion_name] = acc[item.promotion_name] || [];
        acc[item.promotion_name].push(item);
        return acc;
    }, {});

    this.dataLength = Object.keys(groupedData).length;
    this.pdfCreator(groupedData);

  
  });

}


@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }
}
