import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HorizontalMenuService } from '../services/horizontalmenu.service';
import { ConfirmationService } from '../services/confirmation.service';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { CrudService } from '../services/crud.service';
import { environment } from 'src/environments/environment';
import CustomStore from 'devextreme/data/custom_store';
import { PromotionService } from '../services/promotions-data-sync.service';
import { DateService } from '../services/date.service';
import { NotifyService } from '../services/notify.service';

interface ApiResponse {
  data: any[];
}

@Component({
    selector: 'app-rebate-details',
    templateUrl: './rebate-details.component.html',
    styleUrls: ['./rebate-details.component.css'],
    imports: [ DxDataGridModule ],
    standalone: true
})

export class RebateDetailsComponent implements AfterViewInit {
  @Output() rebateIDValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedPromoID: number;
  editRebate:boolean = false;

  dataSource:CustomStore;
  warehouses:any;
  private baseUrl: string = environment.baseApiUrl;
  @ViewChild("rebateGrid", { static: false }) public dataGrid: DxDataGridComponent;
  constructor(private cdr: ChangeDetectorRef,
              private horizontalMenuService: HorizontalMenuService,
              private confirmationService: ConfirmationService,
              private crudService: CrudService,
              private promotionService:PromotionService,
              private dateService:DateService,
              private notifyService:NotifyService) {

  }
ngAfterViewInit(): void {
  this.horizontalMenuService.editRebate$.subscribe((value: boolean) => {
    this.editRebate = value;
    this.cdr.detectChanges();
  });

}

loadData(){

this.dataSource = new CustomStore<any>({
  key: 'id',
  load: async () => {
    return await new Promise((resolve) => {

      this.crudService.getData(this.baseUrl + `rebate-dates?promotion_header_id=${this.selectedPromoID}`).subscribe((result: any) => {

        resolve(result);
      })
    })
},
insert: async (values: RebateValues) => {
  const userDetails = localStorage.getItem('userDetails');
  const parsedDetails = JSON.parse(userDetails || '{}');
  values.promotion_header_id = this.selectedPromoID;

  this.formatDates(values);
  return await new Promise(async (resolve) => {
    // Directly pass values as the data body for the POST request.
    await this.crudService.insertData(this.baseUrl + `rebate-dates`, values)
    .subscribe((result: any) => {
      this.promotionService.requestRefreshGrid();
      this.notifyService.tellUser(result);
      resolve(result.data);
    });
  });
      },
      update: async (key, values:RebateValues) => {
        return await new Promise(async (resolve) => {
        values.promotion_header_id = this.selectedPromoID;
        this.formatDates(values);
          await this.crudService.updateData(this.baseUrl + 'rebate-dates', key, values)
          .subscribe((result: any) => {
            this.promotionService.requestRefreshGrid();
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          await this.crudService.deleteData(this.baseUrl + 'rebate-dates', key)
          .subscribe((result: any) => {
            this.promotionService.requestRefreshGrid();
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      }
    })

    this.warehouses = {
      store: new CustomStore<any>({
        key:'id',
        load: async () => {
          const response = await this.crudService.getData(this.baseUrl + 'warehouses').toPromise();
          return response;
        },
        byKey: async (key) => {
          const response = await this.crudService.getData(`${this.baseUrl}warehouses/${key}`).toPromise();
          return response;
        }
      })
    }
  }
  formatDates(values: RebateValues): RebateValues
  {
    if(values.rebate_start)
      values.rebate_start = this.dateService.dateFormat(values.rebate_start);
    if(values.rebate_end)
      values.rebate_end = this.dateService.dateFormat(values.rebate_end);
    if(values.buying_start)
      values.buying_start = this.dateService.dateFormat(values.buying_start);
    if(values.buying_end)
      values.buying_end = this.dateService.dateFormat(values.buying_end);
    return values;
  }
onHiding(event:any){
  this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditRebate(value))
      .then((canHide:boolean) => {
        //if canHide checks if use can hide profile popup.
        if(canHide){
          this.horizontalMenuService.seteditRebate(false);
          console.log(this.horizontalMenuService.editRebate$);
        }
      });
    }
}
export class RebateValues
{

    rebate_start: string;
    rebate_end: string;
    buying_start: string;
    buying_end: string;
    warehouse_id: number;
    promotion_header_id: number;

}
