import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CanComponentDeactivate } from './can-deactivate.model';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard  {
  canDeactivate(component: CanComponentDeactivate): boolean | Observable<boolean> | Promise<boolean> {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
