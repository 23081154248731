<div class="nav_id"><p><u>promotions</u></p></div>

<div class="promoHeaderGrid">
  <dx-data-grid #promotionHeaders [ngClass]="{ 'hidden': promotionsLoading, 'visible': !promotionsLoading }"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [height]="'98%'"
    [className]="'promo-header'"
    [allowColumnResizing]="true"
    [columnResizingMode]="'widget'"
    [showColumnLines]="true"
    (onCellHoverChanged)="eventData($event)"
    (onContentReady)="contentReadyLoading($event)"
    (onEditingStart)="onEditingStart($event)"
  >
    <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    storageKey="promoHeaderState"
  ></dxo-state-storing>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-row
  [visible]="showFilterRow"
  [applyFilter]="currentFilter"
  ></dxo-filter-row>
  <dxo-toolbar>
    <dxi-item  location="after">
      <div *dxTemplate>
    <dx-button class="search-toggle"
    icon="search"
    [class.active]="showFilterRow"
    (onClick)="toggleFilterRow()">
    </dx-button>
    </div>
    </dxi-item>
      <dxi-item name="groupPanel"></dxi-item>
      <dxi-item name="addRowButton"></dxi-item>
      <dxi-item name="saveButton"></dxi-item>
      <dxi-item name="revertButton"  ></dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" hint="Refresh Grid" (onClick)="refreshDataGrid()">
          </dx-button>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxi-column dataField="promotion_period_id" caption="Name" dataType="integer" [setCellValue]="setDateValue" headerCellTemplate="nameTemplate">
      <dxo-lookup [dataSource]="promotionNames" valueExpr="id" displayExpr="promotion_name"></dxo-lookup>
    </dxi-column>
    <div *dxTemplate="let data of 'nameTemplate'">
      Name
      <dx-button *ngIf="permissions" class="edit_button name_button" icon="rename" hint="Edit Promotion Names" (onClick)="promotionNamePopupEvent($event)">
      </dx-button>
    </div>
    <dxi-column dataField="buying_group_id" caption="Buying Group" dataType="integer" headerCellTemplate="buyingGroupTemplate">
      <dxo-lookup
        [dataSource]="buyingGroups"
        valueExpr="id"
        displayExpr="buying_group_name"></dxo-lookup>
    </dxi-column>
    <div *dxTemplate="let data of 'buyingGroupTemplate'">
      Buying Group
      <dx-button *ngIf="permissions" class="edit_button buying_group_button" icon="rename" hint="Edit Buying Groups" (onClick)="promotionBuyingGroupPopupEvent($event)">
      </dx-button>
    </div>
    <dxi-column dataField="promotion_type" dataType="integer" headerCellTemplate="promotionTypeTemplate">
      <dxo-lookup
        [dataSource]="promotionTypes"
        valueExpr="id"
        displayExpr="name"></dxo-lookup>
    </dxi-column>
    <div *dxTemplate="let data of 'promotionTypeTemplate'">
      Type
      <dx-button  *ngIf="permissions" class="edit_button promotion_type_button" icon="rename" hint="Edit Promotion Type" (onClick)="promotionTypePopupEvent($event)">
      </dx-button>
    </div>
    <dxi-column dataField="description" dataType="string" ></dxi-column>
    <dxi-column dataField="promotion_start" dataType="date" format="dd-MM-yyyy"headerCellTemplate="promotionStartTemplate"></dxi-column>
    <div *dxTemplate="let data of 'promotionStartTemplate'">
      Start
      <dx-button *ngIf="permissions" class="edit_button promotion_start_button" icon="rename" hint="Edit Promotion Status" (onClick)="promotionStartPopupEvent($event)">
      </dx-button>
      </div>
    <dxi-column dataField="promotion_end" dataType="date" format="dd-MM-yyyy"></dxi-column>
    <dxi-column dataField="buying_start" dataType="date" format="dd-MM-yyyy"></dxi-column>
    <dxi-column dataField="buying_end" dataType="date" format="dd-MM-yyyy"></dxi-column>
    <dxi-column dataField="status" dataType="integer" headerCellTemplate="promotionStatusTemplate" [setCellValue]="valueChanged">
      <dxo-lookup
        [dataSource]="promotionStatuses"
        valueExpr="status"
        displayExpr="name"></dxo-lookup>
    </dxi-column>
    <div *dxTemplate="let data of 'promotionStatusTemplate'">
      Status
      <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Promotion Status" (onClick)="promotionStatusPopupEvent($event)"></dx-button>
    </div>
    <dxi-column dataField="edlp" dataType="boolean" caption="EDLP" width="60"></dxi-column>
    <dxi-column id="eventColumn" cellTemplate="cellTemplate" width="50"></dxi-column>
    <div *dxTemplate="let data of 'cellTemplate'">
      <div class="circle" [style.background-color]="'green'" [style.visibility]="hasEvent(data.data.promotion_start, data.data.promotion_end)">
        <p class="circle-inner">E</p>
      </div>
    </div>
    <dxi-column class="buttonColumn" type="buttons" cellTemplate="buttonCellTemplate"> </dxi-column>
    <div *dxTemplate="let data of 'buttonCellTemplate'" cssClass="iconButtons">
      <dx-button *ngIf="data.data.rebates?.length >= 1" name="rebate" hint="Edit Rebates" icon="money" (onClick)="rebatePopupEvent(data)"></dx-button>
      <dx-button *ngIf="!data.data.rebates || data.data.rebates.length === 0" name="rebate" hint="No Rebates" icon="money" cssClass="inactive" (onClick)="rebatePopupEvent(data)"></dx-button>

      <dx-button name="view" hint="View Details" icon="info" (onClick)="navigateDetails(data)"></dx-button>
      <dx-button name="viewMedia" hint="View Media Plan" icon="tags" (onClick)="navigateMediaPlan(data)"></dx-button>
      <dx-button name="clone" hint="Clone Details" icon="copy" (onClick)="openCloneSelection(data)"></dx-button>
    </div>
    <dxi-column name="delete" type="buttons" class="deleteColumn" width="50px">
      <dxi-button name="delete" hint="Delete Details"></dxi-button>
    </dxi-column>
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-editing
      mode="batch"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>
  </dx-data-grid>
</div>
<!-- rebate popup -->
<dx-popup *ngIf="(editRebate | async) === true"
  [(visible)]="rebatePopup.editRebate"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Rebate Details"
  height="auto"
  width="auto"
  minHeight="250px"
  [showCloseButton]="true"
  (onHiding)="rebatePopup.onHiding($event)"
  (onShowing)="rebatePopup.loadData()">
    <app-rebate-details [selectedPromoID]="selectedPromoID" #rebatePopup></app-rebate-details>

</dx-popup>
<!--Clone Promotion-->
<dx-popup
  [(visible)]="clonePromotionData"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Clone Promotion"
  height="auto"
  width="auto"
  minHeight="250px"
  [showCloseButton]="true">
    <app-clone-promotion (clonePromotionValues)="closeCloneSelection()"></app-clone-promotion>
</dx-popup>
<dx-tooltip
  [target]="tooltipTarget">
  <div *dxTemplate="let data of 'content'">
    <div class="tooltipContent" *ngFor="let item of tooltipData">
      <div><b>Date:</b> {{displayDate(item.date)}}</div>
      <div><b>Event:</b> {{item.event_name}}</div>
      <div><b>State:</b> {{item.region}}</div>
      <div><hr></div>
    </div>
  </div>
</dx-tooltip>

<!--Promotion Buying Groups Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="buyingGroup.editBuyingGroups"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Buying Groups"
  height="80%"
  width="800px"
  [showCloseButton]="true"
  (onHiding)="buyingGroup.onHiding($event)"
  (onShowing)="buyingGroup.loadData()">
    <app-buying-groups #buyingGroup></app-buying-groups>
</dx-popup>
<!--Promotion Names Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionNamePopup.editPromotionNames"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Names"
  height="95%"
  width="800px"
  [showCloseButton]="true"
  (onHiding)="promotionNamePopup.onHiding($event)"
  (onShowing)="promotionNamePopup.loadData()">
    <app-promotion-names #promotionNamePopup></app-promotion-names>
</dx-popup>
<!--Promotion Status Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionStatusPopup.editStatus"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Status"
  height="50%"
  width="800px"
  [showCloseButton]="true"
  (onHiding)="promotionStatusPopup.onHiding($event)"
  (onShowing)="promotionStatusPopup.loadData()">
    <app-promotion-status #promotionStatusPopup></app-promotion-status>
</dx-popup>
<!--Promotion Types Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionTypePopup.editPromotionTypes"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Types"
  height="50%"
  width="800px"
  [showCloseButton]="true"
  (onHiding)="promotionTypePopup.onHiding($event)"
  (onShowing)="promotionTypePopup.loadData()">
    <app-promotion-type #promotionTypePopup></app-promotion-type>
</dx-popup>
<!--Promotion Periods Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionPeriodPopup.editPromotionPeriods"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Periods"
  height="95%"
  width="800px"
  [showCloseButton]="true"
  (onHiding)="promotionPeriodPopup.onHiding($event)"
  (onShowing)="promotionPeriodPopup.initializeData()">
    <app-promotion-periods #promotionPeriodPopup></app-promotion-periods>
</dx-popup>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="promotionsLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>
