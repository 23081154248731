import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxTagBoxComponent, DxLookupComponent, DxValidationGroupModule, DxTagBoxModule, DxValidatorModule, DxSelectBoxModule, DxLookupModule, DxButtonModule } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { CrudService } from '../services/crud.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-clone-slotting-data',
    templateUrl: './clone-slotting-data.component.html',
    styleUrls: ['./clone-slotting-data.component.css'],
    imports: [ DxValidationGroupModule, DxTagBoxModule, DxValidatorModule, DxSelectBoxModule, DxLookupModule, DxButtonModule ],
    standalone: true
})
export class CloneSlottingDataComponent {
  @ViewChild('promotionsTagBox', { static: false }) promotionsTagBox: DxTagBoxComponent;
  @ViewChild('fromBuyingGroupLookup', { static: false }) fromBuyingGroupLookup: DxLookupComponent;
  @ViewChild('toBuyingGroupLookup', { static: false }) toBuyingGroupLookup: DxLookupComponent;
  @Output() clonePromotionsValues: EventEmitter<any> = new EventEmitter<any>();
  promotionPeriods: any[] = [];
  buyingGroups: any[] = [];
  calendarWeeks: any[] = [];
  apiUrl = environment.baseApiUrl;
  
    constructor(private crudService: CrudService) { 
      if(localStorage.getItem('promotion-periods') !== null) {
        this.promotionPeriods = JSON.parse(localStorage.getItem('promotion-periods') || '');
      }
      if(localStorage.getItem('buying-groups') !== null) {
        this.buyingGroups = JSON.parse(localStorage.getItem('buying-groups') || '');
      }
      if(localStorage.getItem('calender-weeks') !== null) {
        this.calendarWeeks = JSON.parse(localStorage.getItem('calender-weeks') || '');
      }
    }

    clonePromotions(e: any) {
      const result = e.validationGroup.validate();
      if(!result.isValid) {
        return;
      }
      const data: any = {};
      const selectedPromotions = this.promotionsTagBox.instance.option('value') || [];
      const allPeriodWeekIds: number[] = [];
      selectedPromotions.forEach((promoId: number) => {
        const periodWeekIds: number[] = this.getPeriodWeekIdsByPromoId(promoId);
        allPeriodWeekIds.push(...periodWeekIds);
      });
      const fromBuyingGroup = this.fromBuyingGroupLookup.instance.option('value');
      const toBuyingGroup = this.toBuyingGroupLookup.instance.option('value');
      const fromBuyingGroupName = this.buyingGroups.find(obj => obj.id == fromBuyingGroup).buying_group_name;
      const toBuyingGroupName = this.buyingGroups.find(obj => obj.id == toBuyingGroup).buying_group_name;
      let confirmResult = confirm(`<i>Are you sure?</i><p>This process will overwrite all data in <b>${toBuyingGroupName}</b><br>
        with data from <b>${fromBuyingGroupName}</b> for the selected promotions</p>`, "Clone Data");
        confirmResult.then((dialogResult) => {
            if(!dialogResult) {
              return;
            }
            console.log('clone planning data');
            data.from_buying_group = fromBuyingGroup;
            data.to_buying_group = toBuyingGroup;
            data.period_weeks_array = allPeriodWeekIds;
            this.crudService.updateDataWithoutId(`${this.apiUrl}clone-planning-data`, data).subscribe((response: any) => {
              console.log(response);
            });
            this.fromBuyingGroupLookup.instance.reset();
            this.toBuyingGroupLookup.instance.reset();
            this.promotionsTagBox.instance.reset();
            e.validationGroup.reset();
            this.clonePromotionsValues.emit({close: true});
        });
    }

    getPeriodWeekIdsByPromoId(targetPromoId: number) {
      const filteredObjects = this.calendarWeeks.filter(obj => obj.promo_id == targetPromoId);
      const periodWeekIds: number[] = filteredObjects.map(obj => obj.id);
      return periodWeekIds;
    }
}
