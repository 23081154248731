<div class="nav_id"><p><u>reports</u></p></div>
<br>
<div class="list-container">
    <dx-list #listComponent
      [dataSource]="dataSource"
      [grouped]="true"
      [collapsibleGroups]="true"
      [searchEnabled]="true"
      [searchEditorOptions]="{ placeholder: 'Find Reports'}"
      [scrollingEnabled]="true"
      [height]="'80vh'"
    >
      <div *dxTemplate="let item of 'category'">
        <div class="category-text">{{ item.category }}</div>
      </div>
      <div *dxTemplate="let data of 'item'" id="report_item">
        <div class="list-item" (click)="showReportFilter(data.name, data.component_name)">
          <div class="report_card">
            <div class="report_text">
              <div class="report_header_container">
             <span class="report_name"> {{data.name}} </span>
             <div class="report_icon_container">
              <i *ngIf="pdfReports.includes(data.name)" class="dx-icon-pdffile"></i>
              <i *ngIf="!excelReports.includes(data.name) || !pdfReports.includes(data.name)" class="dx-icon-pdffile icon-transparent"></i>
            <i *ngIf="excelReports.includes(data.name)" class="dx-icon-xlsxfile"></i>
          </div>
        </div>
            <span class="report_description">{{data.description}}</span>
          </div>
              
          </div> 
        </div> 
    </div>
    </dx-list>
</div>
<!-- ALM Advice -->
<dx-popup id="filter_popup"
  [(visible)]="showReportPopup"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  [(title)]="reportName"
  height="auto"
  width="35rem"
  [showCloseButton]="true"
  (onShown)="loadReport()"
  >
  <div *ngIf="promoDescription" class="report_description">{{ promoDescription }}</div>
  <div class="line"><div class="line_1"></div></div>
  <div class="report_container"#reportContainer></div>

</dx-popup>
