<dx-data-grid
  [dataSource]="data$ | async"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onRowUpdating)="updateRow($event)"
  (onRowInserting)="insertRow($event)"
>
<dxo-editing
    mode="row"
    [allowAdding]="true"
    [allowDeleting]="true"
    [allowUpdating]="true"
  >
  </dxo-editing>
  <dxi-column dataField="warehouse_id" dataType="number" caption="Warehouse">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup
      [dataSource]="warehouses$ | async"
      valueExpr="id"
      displayExpr="name"
    >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="account_code" dataType="string" caption="Buying Group">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup
      [dataSource]="buyingGroups$ | async"
      valueExpr="account_code"
      displayExpr="buying_group_name"
    >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="product_ordering_code" dataType="string" caption="Ordering Code"></dxi-column>
  <dxi-column dataField="liquorfile_number" dataType="string" caption="LF Number"></dxi-column>
  <dxi-column dataField="cost_ex_gst" dataType="string" caption="Cost"></dxi-column>
  <dxi-column dataField="cost_inc_wet_only" dataType="string" caption="Cost inc WET"></dxi-column>
  <dxi-column dataField="allowance_group" dataType="string" caption="Allowance Group"></dxi-column>
  <dxi-column dataField="allowance_promotion" dataType="string" caption="Allowance Promotion"></dxi-column>
  <dxi-column dataField="allowance_universal" dataType="string" caption="Allowance Universal"></dxi-column>
  <dxi-column dataField="allowance_total" dataType="string" caption="Allowance Total"></dxi-column>
  <dxi-column dataField="pick_fee" dataType="string" caption="Pick Fee"></dxi-column>
  <dxi-column dataField="rrp" dataType="string" caption="RRP"></dxi-column>
  <dxi-column dataField="barcode" dataType="string" caption="BarCode"></dxi-column>
</dx-data-grid>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="detailsLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>
