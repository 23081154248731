import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class CostServiceService {

  constructor() { }

  calculateGpAmount(row: Product): number
  {
    //API is calculating allowance for mix_match_items
    let allowance_total = 0;
    let multiBuyCount = 0;
    let gpAmount = 0;
    let sellPrice = row.sell_price || null;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    } else {
      allowance_total = (+row.allowance_total || 0);
    };
    if('multi_buy' in row && row.multi_buy !== null && row.multi_buy !== 'Mix n Match') {
      multiBuyCount = Number(row.multi_buy.substring(0, 1));
      if(row.sell_price !== null) {
        sellPrice = (row.sell_price / multiBuyCount);
      } else {
        sellPrice = row.sell_price;
      }
    }
    if(row.cost_ex_gst !== null && sellPrice !== null) {
      const gst = sellPrice / 11;
      let newCost = 0;
      if (row.has_wet) {
        newCost = ((row.cost_ex_gst - allowance_total) * 1.29);
        }
      else {
        newCost = (row.cost_ex_gst - allowance_total);
        };
      if('multi_buy' in row && row.multi_buy !== null && row.multi_buy !== 'Mix n Match') {
        gpAmount = (sellPrice - (newCost  / row.packs_per_carton) - gst) * multiBuyCount;
      } else {
        gpAmount = (sellPrice - (newCost  / row.packs_per_carton) - gst);
      }
      return gpAmount;
    }
    return 0;
  }

  calculateGpPercent(row: Product)
  {
    //API is calculating allowance for mix_match_items
    let allowance_total = 0;
    let multiBuyCount = 0;
    let sellPrice = row.sell_price || null;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    }else {
      allowance_total = (+row.allowance_total || 0);
    };
    if('multi_buy' in row && row.multi_buy != null && row.multi_buy !== 'Mix n Match') {
      multiBuyCount = Number(row.multi_buy.substring(0, 1));
      if(sellPrice !== null) {
        sellPrice = (sellPrice / multiBuyCount);
      }
    }
    if(sellPrice !== null && row.cost_ex_gst !== null) {
      const gst = sellPrice / 11;
      const ex_gst = sellPrice - gst;
      let newCost = 0;
      if (row.has_wet) {
        newCost = ((row.cost_ex_gst - allowance_total) * 1.29);
        }
      else {
        newCost = (row.cost_ex_gst - allowance_total);
        };
      newCost =  newCost / row.packs_per_carton;
      // if('multi_buy' in row && row.multi_buy !== null) {
      //   newCost = newCost * multiBuyCount;
      // }
      const percentage = ((ex_gst - newCost) / ex_gst) * 100;
      return percentage.toFixed(2) + '%';
    }
    return 0;
  }

  calculateNewCost(row: Product)
  {
    //API is calculating allowance for mix_match_items
    let allowance_total = 0;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    } else {
      allowance_total = (+row.allowance_total || 0);
    }

    if(row.allowance_total !== null && row.cost_ex_gst !== null) {
      if(row.has_wet === 1) {
        return (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))) + (0.29 * (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))));
      } else {
        return row.cost_ex_gst - (allowance_total + (+row.allowance_universal));
      }
    }
    return undefined;
  }

  calculateCostExGst(row: Product)
  {
    if(row.cost_inc_gst !== null && row.cost_inc_gst !== 0) {
      return (row.cost_inc_gst / row.packs_per_carton) / 1.1;
    }
    return undefined;
  }

  getRebateCost(row: Product)
  {
    //API is calculating allowance for mix_match_items
    let allowance_total = 0;
    let rebatedCost = 0;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    } else {
      allowance_total = (+row.allowance_total || 0);
    };
    if(row.has_wet === 1) {
      rebatedCost = (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))) + (0.29 * (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))));
    } else {
      rebatedCost = row.cost_ex_gst - (allowance_total + (+row.allowance_universal));
    }
    if('multi_buy' in row && row.multi_buy !== null && row.multi_buy !== 'Mix n Match') {
      rebatedCost = rebatedCost / row.packs_per_carton;
      rebatedCost = rebatedCost * Number(row.multi_buy.substring(0, 1));
    }
    return rebatedCost - row.rebate;
  }

  calculateRebateCost = (row: Product) =>
  {
    return this.getRebateCost(row);
  }

  sellPriceExGST(sell_price: number) {
    return sell_price / 1.1;
  }

  calculateRebateGpAmount = (row: Product) =>
  {
    let rebateCost = this.getRebateCost(row);
    if(row.rebate === null || row.rebate == 0 || row.multi_buy === null || row.multi_buy === 'Mix n Match') {
      rebateCost = rebateCost / row.packs_per_carton;
    }
    if(row.sell_price !== null) {
      let sellPrice = row.sell_price;
      const rebateGpAmount = this.sellPriceExGST(sellPrice) - (rebateCost / row.packs_per_carton);
      return (rebateGpAmount);
    }
    return undefined;
  }

  calculateRebateGpPercent = (row: Product) =>
  {
    if(row.sell_price !== null && row.sell_price !== 0) {
      const rebateGpAmount = this.calculateRebateGpAmount(row) || 0;
      const ex_gst = this.sellPriceExGST(row.sell_price);
      const percentage =  (rebateGpAmount / ex_gst) * 100;
      return percentage.toFixed(2) + '%';
    }
    return undefined;
  }
}
