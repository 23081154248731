<div><p class="nav_id">promotions > <u>promotion-details</u></p></div>

<div [ngClass]="{ 'hidden': detailsLoading, 'visible': !detailsLoading }" id="promotion-header"><dx-button id="back-button" icon="arrowleft" [width]="32" [height]="32"(click)="returnToPlanning($event)"></dx-button> <p><b>{{promoType}}</b></p></div>
<div class="promotionDetailsByGroupGrid">
    <dx-data-grid #promotionGroupGrid
      [dataSource]="data$ | async"
      [remoteOperations]="false"
      [allowColumnReordering]="true"
      [rowAlternationEnabled]="true"
      [focusedRowEnabled]="true"
      [height]="'95%'"
      [width]="'95%'"
      [allowColumnResizing]="true"
      [columnResizingMode]="'widget'"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onEditingStart)="onEditingStart($event)"
      (onCellHoverChanged)="groupTotalData($event)"
      (onRowUpdating)="rowUpdating($event)"
      (onRowRemoving)="rowRemoving($event)"
      keyExpr="id">

      <dxo-editing
          mode="batch"
          [allowAdding]="allowEditing"
          [allowUpdating]="allowEditing"
          [allowDeleting]="allowEditing">
      </dxo-editing>

      <dxo-toolbar>
        <dxi-item>
          <dx-button hint="Restore grid" icon="return" (onClick)="resetGridState()"></dx-button>
        </dxi-item>
        <dxi-item location="after">
          <div *dxTemplate>
            <dx-button icon="plus" hint="Add Product" [disabled]="disabled" (onClick)="openPromotionGroupSelection($event)">
            </dx-button>
          </div>
        </dxi-item>
        <dxi-item name="saveButton"></dxi-item>
        <dxi-item name="revertButton"></dxi-item>
        <dxi-item location="after">
          <div *dxTemplate>
            <dx-button icon="money" hint="Update Costs" [disabled]="disabled" (onClick)="updateCosts()">
            </dx-button>
          </div>
        </dxi-item>
        <dxi-item name="columnChooserButton"></dxi-item>
        <dxi-item name="searchPanel"></dxi-item>
      </dxo-toolbar>

      <dxo-column-chooser #columnChooser [enabled]="true" mode="select">
        <dxo-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button"></dxo-position>
      </dxo-column-chooser>

      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>

      <dxi-column id="groupTotalColumn" dataField="groupTotalColumn" [calculateCellValue]="compareGroupTotals" cellTemplate="cellTemplate" width="50" caption="" [allowFiltering]="false"></dxi-column>
    <div *dxTemplate="let data of 'cellTemplate'">
      <div *ngIf="data.value == 1">
        <i class="dx-icon-clearsquare" aria-hidden="true"></i>
      </div>
    </div>
      <dxi-column dataField="promotion_group_name" dataType="string" caption="Name" [allowEditing]="false" [fixed]="true"></dxi-column>
      <dxi-column dataField="supplier_name" dataType="string" caption="Supplier" [allowEditing]="false"></dxi-column>
      <!-- <dxi-column caption="Rebates" alignment="center" headerCellTemplate="rebateTemplate"> -->
        <ng-container *ngFor="let column of columns">
          <dxi-column
            [dataField]="column.dataField"
            [name]="column.dataField"
            [format]="currencyFormat"
            [dataType]="column.dataType"
            [visible]="column.visible"
            [setCellValue]="setRebateTotal"
            [allowEditing]="allowEditing">
        </dxi-column>
        </ng-container>
        <dxi-column dataField="rebate" dataType="number" [format]="currencyFormat" [allowEditing]="false" headerCellTemplate="rebateTemplate"></dxi-column>
        <dxi-column dataField="max_rebate_qty" dataType="number" caption="Max Rebate Qty"></dxi-column>
      <!-- </dxi-column>
      <dxi-column caption="Allowances" alignment="center" headerCellTemplate="allowanceTemplate"> -->
        <dxi-column dataField="allowance" [format]="currencyFormat" dataType="number" caption="Allowance" [setCellValue]="setAllowanceTotal" [allowEditing]="allowEditing"></dxi-column>
        <dxi-column dataField="allowance_group" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Allowance Group"></dxi-column>
        <dxi-column dataField="allowance_promotion" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Allowance Promotion"></dxi-column>
        <dxi-column dataField="allowance_total" [format]="currencyFormat" dataType="number" [setCellValue]="setAllowance" [calculateCellValue]="calculateAllowanceTotal" [allowEditing]="allowEditing"></dxi-column>
        <dxi-column dataField="allowance_universal" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Uni. Deal"></dxi-column>
      <!-- </dxi-column>
      <dxi-column caption="Product Costs & Pricing" alignment="center"> -->
        <dxi-column dataField="cost_ex_gst" [format]="currencyFormat" dataType="number" caption="Std Cost ex WET" [visible]="false" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="cost_inc_wet_only" [format]="currencyFormat" dataType="number" caption="Std Cost"  [allowEditing]="false"></dxi-column>
        <dxi-column dataField="new_cost" [format]="currencyFormat" caption="New Cost" [calculateCellValue]="calculateNewCost" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="sell_price" [format]="currencyFormat" dataType="number" caption="Promo Price" [allowEditing]="allowEditing"></dxi-column>
        <dxi-column dataField="gp_percent" [format]="percentageFormat" caption="GP %" [calculateCellValue]="calculateGpPercent" [allowEditing]="false" [customizeText]="customiseText"></dxi-column>
        <dxi-column dataField="gp_amount" [format]="currencyFormat" caption="GP $" [calculateCellValue]="calculateGpAmount" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="rrp" dataType="number" [format]="currencyFormat" caption="RRP" [allowEditing]="false" [visible]="false"></dxi-column>
      <!-- </dxi-column> -->
      <dxi-column dataField="comments" dataType="string" caption="Comments" [allowEditing]="allowEditing"></dxi-column>
      <dxi-column dataField="department_code" dataType="string" caption="Category Code" [allowEditing]="false" [visible]="false"></dxi-column>
      <dxi-column dataField="department_description" dataType="string" caption="Category Desc" [allowEditing]="false" [visible]="false"></dxi-column>
      <dxi-column dataField="planning_category_name" dataType="string" caption="Planning Category" [allowEditing]="false" [visible]="false"></dxi-column>
      <dxi-column dataField="store_position_name" dataType="string" caption="Store Position" [allowEditing]="false" [visible]="false"></dxi-column>
      <!-- <dxi-column caption="Rebate Inclusive" alignment="center" [visible]="false"> -->
        <dxi-column [format]="currencyFormat" caption="R-Cost" [calculateCellValue]="calculateRebateDisplayCost" [allowEditing]="false"></dxi-column>
        <dxi-column [format]="currencyFormat" caption="R-GP$" [calculateCellValue]="calculateRebateGpAmount" [allowEditing]="false"></dxi-column>
        <dxi-column [format]="percentageFormat" caption="R-GP%" [calculateCellValue]="calculateRebateGpPercent" [allowEditing]="false" [customizeText]="customiseText"></dxi-column>
        <dxi-column type="buttons" [width]="80" [allowEditing]="false" fixed="right">
          <dxi-button hint="Swap Promotion Group" icon="repeat" name="swapButton" [onClick]="openPromotionGroupSelection"></dxi-button>
          <dxi-button name="delete"></dxi-button>
        </dxi-column>
      <!-- </dxi-column> -->

      <!-- <div *dxTemplate="let data of 'allowanceTemplate'">
        Allowance Total
        <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Promotion Allowances" (onClick)="allowancePopupEvent($event)">
        </dx-button>
      </div> -->

      <div *dxTemplate="let data of 'rebateTemplate'">
        Rebate Total
        <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Promotion Rebates" (onClick)="rebatePopupEvent($event)"></dx-button>
      </div>

      <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
      <div *dxTemplate="let item of 'detail'">
          <app-by-group-details [key]="item.data.promotion_group_id" [item]="item" (updateGroupCount)="updateGroupCount($event)" (editMode)="detailEditMode($event)"></app-by-group-details>
      </div>
      <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadState" [customSave]="saveState"></dxo-state-storing>
    </dx-data-grid>
</div>

<dx-tooltip
  [target]="tooltipTarget">
  <div *dxTemplate="let data of 'content'">
    <div class="tooltipContent" *ngFor="let item of tooltipData">
      <div>{{item}}</div>
      <div><hr></div>
    </div>
  </div>
</dx-tooltip>

<!-- Promotion Group Selection-->
<dx-popup #promotionGroupSelection
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Products"
  height="75%"
  width="70%"
  [showCloseButton]="true"
  (onHiding)="promotionGroupSelectionPopup.onHiding($event)">
    <app-promotion-group-selection #promotionGroupSelectionPopup (loadParentData)="loadData()" (closeChild)="closePromotionGroupSelection($event)"></app-promotion-group-selection>
</dx-popup>

<!--Promotion Allowances Setup-->
<!-- <dx-popup *ngIf="permissions"
  [(visible)]="promotionAllowancesPopup.editPromotionAllowances"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Allowances"
  height="50%"
  width="400px"
  [showCloseButton]="true"
  (onHiding)="promotionAllowancesPopup.onHiding($event)">
    <app-promotion-allowances #promotionAllowancesPopup></app-promotion-allowances>
</dx-popup> -->

<!--Promotion Rebates Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionRebatesPopup.editPromotionRebates"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Rebates"
  height="50%"
  width="400px"
  [showCloseButton]="true"
  (onHiding)="promotionRebatesPopup.onHiding($event)">
    <app-promotion-rebates #promotionRebatesPopup (loadParentData)="loadData()"></app-promotion-rebates>
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="detailsLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
