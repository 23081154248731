<link href="https://fonts.googleapis.com/css?family=Raleway:100,600" rel="stylesheet" type="text/css">
<div class="tool-bar" id="tool-bar">
  <dx-toolbar id="toolbar">
    <dxi-item 
        widget="dxButton"
        [options]="buttonOptions"
        location="before">
    </dxi-item>
  </dx-toolbar>
  <a routerLink="/"><img src="../assets/OTD_Promoflo_Brandmark_Reversed.svg" height="90%" class="logo"></a>

  <div class="week-range-display" *ngIf="weekRange && endDate && displayWeekRange">
    Displaying promotions between: <b>{{ weekRange.startWeek }} </b>to <b>{{ endDate }} </b>
  </div>
  
  <div class="logged-in-logo">
    <img [src]="userImage()"/>
  </div>
  <div class="info-header" (click)="navigateToNotifications()">
    <div class="notification-parent" *ngIf="infoCardsCountWarnings">
      <div class="notification-div">
      <i class="notification dx-icon-bell"></i>
    <span class="notification-value">
      {{infoCardsCountWarnings + infoCardsCountErrors}}
    </span>
    <div>
      
    
    </div>
    </div>
    </div>
  </div>
  <div class="fields-container">
    <div class="dx-field">
      <div class="dx-field-value">
        <dx-drop-down-button
          class="profile-button"
          style="color: white !important;"
          [splitButton]="false"
          [useSelectMode]="false"
          [text]="userName"
          icon="user"
          [items]="profileSettings"
          displayExpr="name"
          keyExpr="id"
          stylingMode="text"
          [dropDownOptions]="{  
            width: 120  
        }"  
          (onItemClick)="itemClick($event)"
        >
      </dx-drop-down-button>
      </div>
    </div>
  </div>
  <dx-popup class="profilePopup" id="profilePopup" [wrapperAttr]="{ id: 'profilePopupId' ,class: 'profilePopup' }"
    [(visible)]="profileComponent.editProfile"
    [hideOnOutsideClick]="true"
    [showTitle]="true"
    title="Edit Profile"
    height="auto"
    width="auto"
    [showCloseButton]="true"
    (onHiding)="profileComponent.onHiding($event)" 
    (onShown)="profileComponent.shown($event)" 
    
    >
    <app-profile #profileComponent></app-profile>
  </dx-popup>
