import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { PromotionHeader } from '../models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { ProductGroup } from '../models/product-group.model';
import { DxDataGridComponent, DxDataGridModule, DxSwitchModule, DxButtonModule } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { CoreRangeDetail } from '../settings/core-range-details/core-range-details.component';
import { HorizontalMenuService } from '../services/horizontalmenu.service';
import { ConfirmationService } from '../services/confirmation.service';
import { environment } from 'src/environments/environment';
import { CostServiceService } from '../services/cost-service.service';

@Component({
    selector: 'app-product-selection',
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.css'],
    imports: [DxDataGridModule, DxSwitchModule, DxButtonModule],
    standalone: true
})
export class ProductSelectionComponent {
  @ViewChild('productSelectionGrid', {static: false}) dataGrid: DxDataGridComponent;
  @Output() loadParentData: EventEmitter<any> = new EventEmitter();
  @Output() closeChild: EventEmitter<any> = new EventEmitter();
  @Input() parent: string;
  @Input() promotionGroupId: number;
  @Input() coreRangeHeaderId: number;
  @Input() header_id: number;
  @Input() selection_level: number;
  productGroups: ProductGroup[];
  selectedRows: any = [];
  products: Product[] = [];
  dataSource: CustomStore;
  header: PromotionHeader;
  showSwitch: boolean = false;
  editProductSelection: boolean = false;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
    private horizontalMenuService: HorizontalMenuService,
    private confirmationService: ConfirmationService,
    private costService: CostServiceService) {
    if(localStorage.getItem('selectedPromo') !== null) {
      this.header = JSON.parse(localStorage.getItem('selectedPromo') || '{}');
    }
    if(localStorage.getItem('product-groups') !== null) {
      const productGroups: any = localStorage.getItem('product-groups');
      this.productGroups = JSON.parse(productGroups);
    } else {
      this.crudService.getData(`${this.apiUrl}product-groups`).subscribe((result: any) => {
        this.productGroups = result;
        localStorage.setItem('product-groups', JSON.stringify(result));
      });
    }
    this.horizontalMenuService.editProductSelection$.subscribe((value: boolean) => {
      this.editProductSelection = value;
    });
    this.loadProducts();
  };

  ngAfterContentChecked() {
    if(this.parent === 'promotion-details') {
      this.showSwitch = true;
    }
  }

  loadProducts() {
    console.log('Loading products');
    this.dataSource = new CustomStore<Product>({
      load: async (loadOptions) => {
        return await new Promise(async (resolve) => {
          this.crudService.getData(`${this.apiUrl}products`).subscribe((result: any) => {
            resolve(result);
          })
        });
      }
    });
  }

  productsByPromotionGroupId(e: any) {
    if(e.value) {
      this.dataSource = new CustomStore<Product>({
        load: async (loadOptions) => {
          return await new Promise(async (resolve) => {
            console.log(loadOptions);
            this.crudService.getData(`${this.apiUrl}by-group-id?promotion_period_id=${this.header.promotion_period_id}`, loadOptions).subscribe((result: any) => {
              resolve(result);
            })
          });
        }
      });
    } else {
      this.loadProducts();
    }
  }


  productGroupName(code: string): string {
    const productGroup = this.productGroups.find((item: ProductGroup) => item.product_group_code === code);
    return productGroup ? productGroup.product_group_name : 'UNKNOWN';
  }

  productGroupId(code: string = '0')
  {
    const productGroup = this.productGroups.find(productGroup => productGroup.product_group_code === code);
    return productGroup ? productGroup.id : 0;
  }

  selectProducts(e: any)
  {
    console.log(`Parent: ${this.parent}`);
    const products: Product[] = this.dataGrid.instance.getSelectedRowsData();
    console.log(this.dataGrid.instance.getSelectedRowsData());
    if(this.parent === 'promotion-details') {
      this.promotionDetails(products);
    }
    if(this.parent === 'promotion-groups') {
      this.promotionGroups(products);
    }
    if(this.parent === 'core-range-details') {
      this.coreRangeDetails(products);
    }
    if(this.parent === 'mix-match') {
      this.mixMatchItems(products);
    }

    this.dataGrid.instance.clearSelection();
    this.closeChild.emit();
  }

  selectPlanningProducts(e: any)
    {
      //
    }

    async promotionDetails(products: Product[]) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
      const locationId = userDetails.data.location_id;
      const promoHeaderId = localStorage.getItem('promo-header-id');

      const promises = products.map(async (product) => {
        const values: any = {
          location_id: locationId,
          promotion_header_id: promoHeaderId,
          product_id: product.id,
          product_group_id: this.productGroupId(product.product_group_code),
          product_name: product.product_name,
          cost_ex_gst: product.cost_ex_gst,
          sell_price: product.sell_price,
          allowance_group: product.allowance_group,
          allowance_promotion: product.allowance_promotion,
          allowance_universal: product.allowance_universal,
          allowance_total: product.allowance_total,
          allowance: product.allowance,
        };

        return this.crudService.insertData(`${this.apiUrl}promotion-details`, values).toPromise();
      });

      await Promise.all(promises);

      this.loadParentData.emit();
    }


  async promotionGroups(products: Product[])
  {
    const inserts: Promise<any>[] = [];
    products.forEach(async (product) => {
      const values: any = {};
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');
      values.location_id = parsedDetails.data.location_id;
      values.product_id = product.id;
      values.promotion_group_id = this.promotionGroupId;
      const updatePromise =  new Promise(async (resolve) => {
        const result: any = await this.crudService.insertData(`${this.apiUrl}grouped-products`, values)
        .subscribe((result: any) => {
          resolve(result);
        });
      });
      inserts.push(updatePromise);
    });
    await Promise.all(inserts);
    this.loadParentData.emit();
  }

  async mixMatchItems(products: Product[])
  {
    const inserts: Promise<any>[] = [];
    products.forEach(async (product) => {
      const values: any = {};
      values.product_id = product.id;
      values.header_id = this.header_id;
      values.selection_level = this.selection_level;
      const updatePromise =  new Promise(async (resolve) => {
        const result: any = await this.crudService.insertData(`${this.apiUrl}mix-match-items`, values)
          .subscribe((result: any) => {
            resolve(result);
        });
      });
    inserts.push(updatePromise);
    });
    await Promise.all(inserts);
    this.loadParentData.emit();
  }

  async coreRangeDetails(products: Product[]) {
    const insertPromises: Promise<any>[] = [];
    products.forEach((product) => {
      const values: CoreRangeDetail = {
        core_range_header_id: this.coreRangeHeaderId,
        product_id: product.id,
        product_name: product.product_name,
        cost_inc_gst: product.cost_inc_gst || 0,
        sell_price: product.sell_price || 0,
        standard_price: product.sell_price || 0,
      };
      insertPromises.push(this.crudService.insertData(`${this.apiUrl}core-range-details`, values).toPromise());
    });
    await Promise.all(insertPromises);
    this.loadParentData.emit();
  }


  calculateGpAmount(row: Product)
  {
    this.costService.calculateGpAmount(row);
  }

  calculateGpPercent(row: Product)
  {
    this.costService.calculateGpPercent(row);
  }

  onEditorPreparing(e: any) {
    if (e.parentType === "searchPanel") {
      e.editorOptions.onValueChanged = undefined;
      e.editorOptions.onEnterKey = function(arg: any) {
              e.component.searchByText(arg.component.option("value"));
      }
    }
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditProductSelection(value))
    this.horizontalMenuService.seteditProductSelection(false);
    this.dataGrid.instance.clearSelection();
    this.dataGrid.instance.clearFilter();
  }

}
