import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HorizontalMenuService } from '../../services/horizontalmenu.service';
import { ConfirmationService } from '../../services/confirmation.service';
import CustomStore from 'devextreme/data/custom_store';
import { CrudService } from 'src/app/services/crud.service';
import { MixMatchHeader } from 'src/app/models/mix-match-header.model';
import { DxDataGridComponent, DxDataGridModule, DxButtonModule } from 'devextreme-angular';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mix-match-selection',
    templateUrl: './mix-match-selection.component.html',
    styleUrls: ['./mix-match-selection.component.css'],
    imports: [ DxDataGridModule, DxButtonModule ],
    standalone: true
})
export class MixMatchSelectionComponent {
  @Input() parent: string;
  @Input() promotionGroupId: number;
  @Output() loadParentData: EventEmitter<any> = new EventEmitter();
  @Output() closeChild: EventEmitter<any> = new EventEmitter();
  @ViewChild('mixMatchSelectionGrid', {static: false}) dataGrid: DxDataGridComponent;
  seteditMixMatch: boolean = false;
  dataSource: CustomStore;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private horizontalMenuService: HorizontalMenuService,
    private confirmationService: ConfirmationService,
    private crudService: CrudService) {
    this.horizontalMenuService.editMixMatch$.subscribe((value: boolean) => {
      this.seteditMixMatch = value;
    });
    this.loadProducts();
  }

  loadProducts() {
    this.dataSource = new CustomStore<MixMatchHeader>({
      load: async (loadOptions) => {
        return await new Promise(async (resolve) => {
          this.crudService.getData(`${this.apiUrl}mix-match-headers`, loadOptions).subscribe((result: any) => {
            resolve(result);
          })
        });
      }
    });
  }

  async addItems(e: any)
  {
    const items: MixMatchHeader[] = this.dataGrid.instance.getSelectedRowsData();
    const inserts: Promise<any>[] = [];
    items.forEach(async (item) => {
      const values: any = {};
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');
      values.location_id = parsedDetails.data.location_id;
      values.product_id = item.id;
      values.promotion_group_id = this.promotionGroupId;
      values.product_type = 2;
      const updatePromise = new Promise(async (resolve) => {
        const result: any = await this.crudService.insertData(`${this.apiUrl}grouped-products`, values)
        .subscribe((result: any) => {
          resolve(result);
        });
      });
      inserts.push(updatePromise);
    });
    await Promise.all(inserts);
    this.dataGrid.instance.clearSelection();
    this.closeChild.emit();
    this.loadParentData.emit();
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditMixMatch(value))
    this.horizontalMenuService.seteditMixMatch(false);
  }

}
