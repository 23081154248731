<div class="grid">
<dx-data-grid
  #promotionPeriodsGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
  [loadPanel]="{ enabled: false }"
  (onEditCanceling)="onEditCanceling()"
  (onInitialized)="loadData()"
  >
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-editing
    mode="batch"
    [allowUpdating]="true"
  >
  </dxo-editing>
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-select-box
          id="daySelect"
          label="Week starts on:"
          width="225"
          [items]="daysOfWeek"
          displayExpr="name"
          valueExpr="number"
          [value]="selectedDayIndex"
          [inputAttr]="{ 'aria-label': 'Value' }"
          (onValueChanged)="setWeekStartConfig($event)"
        >
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-select-box
          id="startDateSelect"
          label="FY Start Date:"
          width="225"
          [acceptCustomValue]="true"
          [items]="calendarWeeks"
          displayExpr="start_date"
          [value]="startDate"
          [inputAttr]="{ 'aria-label': 'Value' }"
          (onValueChanged)="setDataStartDateConfig($event)"
        >
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item name="saveButton"></dxi-item>
    <dxi-item name="revertButton"></dxi-item>
    <dxi-item location="after">
      <dxo-search-panel [visible]="true"></dxo-search-panel>
    </dxi-item>
  </dxo-toolbar>
  <dxi-column dataField="week_number" dataType="integer" [allowEditing]="false" width="100"></dxi-column>
  <dxi-column dataField="start_date" dataType="date" format="dd-MM-yyyy" caption="Start Date" [allowEditing]="false" width="140" [calculateDisplayValue]="getFormattedStartDate"></dxi-column>
  <dxi-column dataField="end_date" dataType="date" format="dd-MM-yyyy" caption="End Date" [allowEditing]="false" width="140" [calculateDisplayValue]="getFormattedEndDate"></dxi-column>
  <dxi-column dataField="promo_id" dataType="string" caption="Promotion Name">
    <dxo-lookup
      [dataSource]="promoData"
      valueExpr="id"
      displayExpr="promotion_name"
      loadMode="raw">
    </dxo-lookup>
    <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="handleSequenceValidation"
          message="The sequence is not continuous.">
      </dxi-validation-rule>
  </dxi-column>
</dx-data-grid>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="isLoadingData"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>

</div>
