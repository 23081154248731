
<div><p class="nav_id">promotions > <u>media-planning</u></p></div>
<div [ngClass]="{ 'hidden': detailsLoading, 'visible': !detailsLoading }" id="promotion-header"><dx-button id="back-button" icon="arrowleft" [width]="32" [height]="32"(click)="returnToPlanning($event)"></dx-button> <p><b>{{promoInfo}}</b></p></div>
<div class="mediaPlanningGrid">
  <dx-data-grid #mediaPlanningGrid
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [rowAlternationEnabled]="true"
    [allowColumnReordering]="true"
    [showBorders]="true"
    [loadPanel]="{ enabled: false }"
    [height]="'95%'"
    [columnChooser]="{ enabled: true, mode: 'select' }"
    [customizeColumns]="customiseColumns"
    [summary]="{recalculateWhileEditing: true, skipEmptyValues: true, totalItems}"
    (onContentReady)="onContentReady($event)"
    [allowColumnResizing]="true"
    [columnResizingMode]="'widget'"
    [showColumnLines]="true"
    [wordWrapEnabled]="true"
    (onExporting)="onExporting($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
  >

  
      <dxo-editing
          mode="batch"
          refreshMode="full"
          [allowUpdating]="true"
          [allowDeleting]="false"
          [allowAdding]="false"
          >
      </dxo-editing>
      <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="mediaPlanningState"
    ></dxo-state-storing>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-row
    [visible]="showFilterRow"
    [applyFilter]="currentFilter"
    ></dxo-filter-row>
    <dxo-toolbar>
      <dxi-item  location="after">
        <div *dxTemplate>
      <dx-button class="search-toggle"
      icon="search"
      [class.active]="showFilterRow"
      (onClick)="toggleFilterRow()">
      </dx-button>
      </div>
    </dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="saveButton"></dxi-item>
      <dxi-item name="revertButton"  ></dxi-item>
    </dxo-toolbar>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
  </dx-data-grid>

  <dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="detailsLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>

</div>
