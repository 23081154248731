import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { AllowanceTypesService, AllowanceType } from 'src/app/services/allowance-types.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-promotion-allowances',
    templateUrl: './promotion-allowances.component.html',
    styleUrls: ['./promotion-allowances.component.css'],
    imports: [DxDataGridModule],
    standalone: true
})
export class PromotionAllowancesComponent {
  public dataSource: any;
  private apiUrl: string = environment.baseApiUrl;
  allowanceTypes: AllowanceType[] = [];
  editPromotionAllowances: boolean;
  gridHeight:any = '40vh';
  @ViewChild("allowanceGrid", { static: false }) public dataGrid: DxDataGridComponent;
  constructor(private crudService: CrudService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private allowanceTypesService: AllowanceTypesService, private cdr: ChangeDetectorRef, private route:ActivatedRoute) {
      this.allowanceTypes = this.allowanceTypesService.getAllowanceTypes();
      this.dataSource = new CustomStore<any>({
        key: 'id',
        load: async () => {
          return await new Promise((resolve) => {
            this.crudService.getData(`${this.apiUrl}allowances`).subscribe((result: any) => {
              const userDetails = localStorage.getItem('userDetails');
              const parsedDetails = JSON.parse(userDetails || '{}');
              localStorage.setItem('allowances', JSON.stringify(result));
              resolve({data: result, totalCount: result.length});
            })
          })
        },
        insert: async (values) => {
          const userDetails = localStorage.getItem('userDetails');
          const parsedDetails = JSON.parse(userDetails || '{}');
          values.group_id = parsedDetails.data.group_id;
          values.location_id = parsedDetails.data.location_id;
          console.log(values);
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.insertData(`${this.apiUrl}allowances`, values)
            .subscribe((result: any) => {
              resolve(result);
            });
          });
        },
        update: async (key, values) => {
          console.log(key, values);
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.updateData(`${this.apiUrl}allowances`, key, values)
            .subscribe((result: any) => {
              resolve(result);
            });
          });
        },
        remove: async (key) => {
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.deleteData(`${this.apiUrl}allowances`, key)
            .subscribe((result: any) => {
              resolve(result);
            });
          });
        }
      })
      this.horizontalMenuService.editAllowances$.subscribe((value: boolean) => {
        this.editPromotionAllowances = value;
      });  

      this.route.url.subscribe((event) => {
        if(event[0].path == 'promotion-allowances'){
          this.gridHeight = '80vh'
        }
        console.log(event[0].path)});
    
    }
    onHiding(event: any) {
      console.log("DxDataGridComponent reference:", this.dataGrid);
      this.confirmationService.checkUnsaved(event, this.dataGrid, (value: boolean) => this.horizontalMenuService.seteditAllowances(value))
        .then((canHide: boolean) => {
          if(canHide) {
            this.horizontalMenuService.seteditAllowances(false);
          }
        }); 
    }
}
