import { Component,ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { PromotionGroup } from 'src/app/models/promotion-group.model';
import { firstValueFrom, Observable, map } from 'rxjs';
import { LocalDataService } from 'src/app/services/local-data.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { RebateNames } from 'src/app/models/rebate-names.model';
@Component({
  selector: 'app-promotion-groups',
  templateUrl: './promotion-groups.component.html',
  styleUrls: ['./promotion-groups.component.css']
})
export class PromotionGroupsComponent {
  dataSource: any;
  promotionCategories: any;
  suppliers$: Observable<Supplier[]>;
  rebateNames$: Observable<RebateNames[]>;
  editPromotionGroups:boolean;
  currencyFormat = { type: 'currency', precision: 2 };
  private apiUrl: string = environment.baseApiUrl;
  @ViewChild("promotionGroupGrid", { static: false }) public dataGrid: DxDataGridComponent;
  
  constructor(private crudService: CrudService,
    private localData: LocalDataService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService) {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.newGetData(`${this.apiUrl}promotion-groups?get_all=1`).subscribe((result: any) => {
            this.suppliers$ = this.crudService.newGetData(`${this.apiUrl}suppliers`);
            this.rebateNames$ = this.crudService.newGetData(`${this.apiUrl}rebates?type=0`).pipe(map((data: any) => data.data));
            resolve(result);
          });
        });
    },
      insert: async (values: any) => {
        console.log(values);
        const rebateNames: RebateNames[] = await firstValueFrom(this.rebateNames$);
        const rebateData: any[] = [];
        const rebateNameskeys = Object.keys(values);
        rebateNameskeys.forEach((rebateNameskey: any) => {
          const rebateDetails = rebateNames.find((rebate) => rebate.rebate_name === rebateNameskey);
          if(rebateDetails) {
            rebateData.push({
              rebate_id: rebateDetails.id,
              rebate_amount: values[rebateNameskey]
            });
          }
        });
        values.rebates = rebateData;
        return await new Promise(async (resolve) => {
          this.crudService.insertData(`${this.apiUrl}promotion-groups`, values).subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        console.log(values);
        const rebateNames: RebateNames[] = await firstValueFrom(this.rebateNames$);
        const rebateData: any[] = [];
        const rebateNameskeys = Object.keys(values);
        rebateNameskeys.forEach((rebateNameskey: any) => {
          const rebateDetails = rebateNames.find((rebate) => rebate.rebate_name === rebateNameskey);
          if(rebateDetails) {
            rebateData.push({
              rebate_id: rebateDetails.id,
              rebate_amount: values[rebateNameskey],
              promotion_group_id: key
            });
          }
        });
        values.rebates = rebateData;
        console.log(values);
        return await new Promise(async (resolve) => {
          this.crudService.updateData(`${this.apiUrl}promotion-groups`, key, values).subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}promotion-groups`, key)
          .subscribe((result: any) => {
            this.localData.removeData('promotion-groups', key);
            resolve(result);
          });
        });
      }
    })

    this.horizontalMenuService.editPromotionGroups$.subscribe(async (value: boolean) => {
      this.promotionCategories =  await this.crudService.getData(`${this.apiUrl}planning-categories`).toPromise();
      this.editPromotionGroups = value;
    });
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditPromotionGroups(value))
  }   
}

export class Supplier
{
  supplier_code: string;
  supplier_name: string;
}
