import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { DateFormatService } from 'src/app/services/date-format.service';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { LoadingService } from '../report-loading.service';
import notify from 'devextreme/ui/notify';
import { DateService } from 'src/app/services/date.service';
import { FilterComponent } from '../filter/filter.component';
import { DatePipe } from '@angular/common';

const pdf = pdfMake;
pdf.vfs = pdfFonts.vfs;
@Component({
    selector: 'app-alm-advice',
    templateUrl: './alm-advice.component.html',
    styleUrls: ['./alm-advice.component.css'],
    providers: [ DateService, DatePipe ],
    imports: [ FilterComponent ],
    standalone: true
})
export class AlmAdviceComponent {
  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  disclaimer:string;
  constructor(private crudService: CrudService,
    private dateService: DateService,
              private groupProductService: GroupProductService,
              private base64Image: Base64Images,
              private dateFormatService: DateFormatService,
              private reportStylesService: ReportStylesService,
              private loadingService: LoadingService)
  {

    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');

  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }

    //
  }
pagePromotion: string = '';
promotionIndex: number = 0;
  transformDataForPdfMake(promotions: any[]): any[] {
    return promotions.flatMap(promotion => {  // Use flatMap to flatten the resulting array
      if(promotion.promotionName  == "Unknown"){
        return
      }  
      const headers = [
            'Order Code', 'Product Description', 'Pack Size', 'Normal Cost','Buying Dates', 'Pick Fee', 'Group Allow', 'Extra PA', 'Universal Deals', 'Total Deal', 'Location'
        ];
        const styledHeaders = headers.map(header => ({ text: header, style: 'tableHeader' }));

        const allRows = promotion.suppliers.flatMap((supplier: any) => {
          // Create a row for the supplier name
     
          const supplierRow = [
            { colSpan: 11, text: supplier.supplier, style: 'supplierStyle' },
            {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
        ];


          // Map over the products of the supplier
          const productRows = supplier.products.map((product: any) => {
            const allowance_group_filtered = Number(product.allowance_group).toFixed(2)
            const pick_fee_filtered = Number(product.pick_fee).toFixed(2)
            const universal_allowance_filtered = Number(product.allowance_universal).toFixed(2)
            const extra_pa = Number(product.allowance).toFixed(2)
            const total_allow = Number(product.allowance_total).toFixed(2)
            const sell_price_filtered = Number(product.sell_price).toFixed(2)
            const normal_cost = Number(product.cost_ex_gst).toFixed(2)
            return [
                { text: product.product_code, style: 'bodyStyle' },
                { text: product.product_name, style: 'bodyStyle' },
                { text: product.packs_per_carton, style: 'bodyStyle' },
                { text: `$${normal_cost}`, style: 'bodyStyle' },
                { text: `${this.dateFormatService.changeDateFormat(product.buying_start)} - ${this.dateFormatService.changeDateFormat(product.buying_end)}`, style: 'bodyStyle' },
                { text: `$${pick_fee_filtered}`, style: 'bodyStyle' },
                { text: `$${allowance_group_filtered}`, style: 'bodyStyle' },
                { text: `$${extra_pa}`, style: 'bodyStyle' },
                { text: `$${universal_allowance_filtered}`, style: 'bodyStyle' },
                { text: `$${total_allow}`, style: 'bodyStyle' },
                { text: product.store_position_name, style: 'bodyStyle' },
            ];
        });


          // Return the supplier row followed by its product rows
          return [supplierRow, ...productRows];
      });
        const styledRows = allRows.map((row:any) => this.styleRow(row, 'bodyStyle'));


       // Subheader for the promotionId
       const subheader = {
        stack: [
          {
            columns: [
              {
                text: `${this.name}`,
                style: 'header',
                alignment: 'left',
              },
              {
                  image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
                  width: 150,
                  alignment: 'right',
                  margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
              }
            ]
          }
          ]
        };
 const secondHeader = {
  stack: [
    {
      columns: [
        {
          text: `${this.headers[this.promotionIndex].promotion_name}`,

          style: 'subheader',
          alignment: 'left',
          margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
        },
        {
          text: `${promotion.promotionName}`,

          style: 'subheader',
          alignment: 'center',
          margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
        },
        {
          text: `${this.buyingGroupName}`,

          style: 'subheader',
          alignment: 'center',
          margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
        },
        {
          text: `Promotion Dates: ${this.dateFormatService.changeDateFormat(promotion.promotion_start)} - ${this.dateFormatService.changeDateFormat(promotion.promotion_end)}`,
          style: 'subheader',
          alignment: 'right',
          margin: [0, 0, 35, 0]  // Adjust margin as needed for the text
        }
      ]
    }
  ]
 }
 if(this.headers.length <= 2 && this.promotionIndex < (this.headers.length - 1)){
this.promotionIndex++;

        return [
            subheader,
            secondHeader,
            {
                table: {
                    headerRows: 1,
                    widths: [ 'auto', '*', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [styledHeaders, ...allRows],
                    
              
                    
                },

                pageBreak: 'after',
                layout: {
                  hLineWidth: () => 0,
                  vLineWidth: () => 0,
                  hLineColor: () => 'white',
                  vLineColor: () => 'white',
                  fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                    return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                  },

            }
          }

        ];
      }
      else if(this.promotionIndex < (this.headers.length - 2)){ 
        this.promotionIndex++;
        return [
          subheader,
          secondHeader,
          {
              table: {
                  headerRows: 1,
                  widths: [ 'auto', '*', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: [styledHeaders, ...allRows],
                  
            
                  
              },

              pageBreak: 'after',
              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                },

          }
        }

      ];
      }
      else{
        return [
          subheader,
          secondHeader,
          {
              table: {
                  headerRows: 1,
                  widths: [ 'auto', '*', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: [styledHeaders, ...allRows],
                  
            
                  
              },

            
              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                },

          }
        }

      ];
      }
    });

}

styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}



pdfCreator(promotions: any[]) {
  const transformedDataArray = this.transformDataForPdfMake(promotions);
  const styles: any = this.reportStylesService.getStyles();

  const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `ALM Advice ${this.promotionName}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },
               
                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };
  this.promotionIndex = 0;
  const filename = `ALM_Advice_${this.buyingGroupName}.pdf`;
  pdfMake.createPdf(documentDefinition).download(filename);
  this.loadingService.toggleLoading(false);
}

buyingGroupName:string = '';
async getPromoData(e: any) {
if(e.groupSelected == null) {
return;
}
try{
  await this.crudService.getData(`${this.apiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).subscribe((result: any) => {
  this.disclaimer = e.disclaimer;
  this.promotionName = result[0].promotion_name;
  this.buyingGroupName = e.groupSelectedName;
  const sortedHeaders = result.sort((a:any, b:any) => {
    const descriptionA = a.description || '';
    const descriptionB = b.description || '';
    return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
  });

  this.headers = sortedHeaders;
  let promoDataEDLPChecked:any;
  
  if (e.export == true) {
    this.crudService.getExportData(
      `${this.apiUrl}export/alm-advice?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`,
      {},
      'blob'
    ).subscribe(
      (response: Blob) => {
            // Create a Blob from the response
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a download link
            const downloadLink = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = 'ALM_Advice_' + this.promotionName + '_' + this.buyingGroupName + '.xlsx';

            // Append the link, trigger the download, then remove the link
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
        },
        (error:any) => {
            console.error('Error:', error);
        }
    );
  }
  if(e.groupSelected != null) {

    this.crudService.getData(`${this.promoDetailsUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&edlp`).subscribe((res: any) => {
        // default grouping, for example:

     
        const firstHeaderId = res.data[0].promotion_header_id;
        promoDataEDLPChecked = res.data.map((promoProduct: any) => {
            return {
              ...promoProduct,
              promotion_header_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
            };
          });
          try{
        let data = this.groupProductService.groupByProductValue(promoDataEDLPChecked, (product: any) => product.supplier_name, sortedHeaders);
        this.pdfCreator(data);
          }catch(e:any){
            notify("No data found with this configuration.", "error", 3000);
            this.loadingService.toggleLoading(false);
          }
    });
    }
  
});
}catch(e:any){
  notify("No data found with this configuration.", "error", 3000);
  this.loadingService.toggleLoading(false);
}
  



}
@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }

}
