import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/config.service';
import config from 'devextreme/core/config';

import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
config({licenseKey: environment.devextremeLicenceKey});
bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
