import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LoadOptions } from 'devextreme/data';
import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private token = this.cookieService.get('otd-token');
  private headers: HttpHeaders = new HttpHeaders({
    'Authorization': 'Bearer ' + this.token,
    'Accept': 'application/json'
  });
  

  constructor(private http: HttpClient,
    private cookieService: CookieService) {}

    newGetData(url: string): Observable<any> {
        return this.http.get(url, { headers: this.headers });
    }

    getData(url: string, params?: any): any {
      if(params) {
        params = this.getParams(params);
      }
      return this.http.get(url, { headers: this.headers, params: params});
    }
    getExportData(url: string, params?: any, responseType: 'json' | 'blob' = 'json'): Observable<any> {
      let httpOptions = {
        headers: this.headers,
        params: params ? this.getParams(params) : {}, // Changed from null to {}
        responseType: responseType as 'json' // Cast to 'json' for type compatibility
      };    
      return this.http.get(url, httpOptions as any); // Cast the entire httpOptions as any
    }

    updateData(url: string, id: number|string, data: any) {
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.put(url + '/' + id, data, {headers: this.headers});
    }

    updateDataWithoutId(url: string, data: any) {
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.put(url, data, {headers: this.headers});
    }

    insertData(url: string, data: any) {
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.post(url, data, {headers: this.headers});
    }

    deleteData(url: string, id: number) {
      return this.http.delete(url + '/' + id, {headers: this.headers});
    }

    deleteDataWithBody(url: string, data: any) {
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.post(url, data, {headers: this.headers});
    }

    getParams(loadOptions: LoadOptions)
    {
      const isNotEmpty = (value: unknown) => value !== undefined && value !== null && value !== '';
      let params: HttpParams = new HttpParams();
 
      [
          'filter',
          'group',
          'groupSummary',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',
          'take',
          'totalSummary',
          'userData',
      ].forEach(function (i) {
          const optionValue = loadOptions[i as keyof LoadOptions];
          if (i in loadOptions && isNotEmpty(optionValue)) {
              params = params.set(i, JSON.stringify(optionValue));
          }
      });
      return params;
    }

}
