import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { StyleDictionary } from 'pdfmake/interfaces';
import { GroupProductService } from '../../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { CostServiceService } from 'src/app/services/cost-service.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { LoadingService } from '../../report-loading.service';
import { DateService } from 'src/app/services/date.service';
import notify from 'devextreme/ui/notify';
import { FilterComponent } from '../../filter/filter.component';
import { DatePipe } from '@angular/common';

const pdf = pdfMake;
pdf.vfs = pdfFonts.vfs;

@Component({
    selector: 'app-member-advice',
    templateUrl: './new-member-advice.component.html',
    styleUrls: ['./new-member-advice.component.css'],
    providers: [ DateService, DatePipe ],
    imports: [ FilterComponent ],
    standalone: true
})
export class NewMemberAdviceComponent {

  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  disclaimer: string;
  localLiquorFilePath:string = 'assets/localliquor.png';
  constructor(private crudService: CrudService,
    private dateService:DateService,
              private groupProductService: GroupProductService,
              private base64Image: Base64Images,
              private costService: CostServiceService, private dateFormatService: DateFormatService,private loadingService: LoadingService)
  {

    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');

  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }

    //
  }
  @Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;

  }
pagePromotion: string = '';
promotionIndex: number = 0;
filtered_by: string = '';
hasRowNote:Boolean;
  transformDataForPdfMake(promotions: any[]): any[] {
   let promoRowHeaderIndex: number = 0;
    return promotions.flatMap(promotion => {  // Use flatMap to flatten the resulting array
      if(promotion.promotionName  == "Unknown"){
        return
      }


      const headers = [
            'Order Code', 'Product Description', 'Carton Qty', 'Case Price ex GST','Ongoing Discount',' Promo Discount','Discount Per Ctn', 'Total ex GST','Total inc GST', 'Single Sell', 'Single Sell GP%', 'Promo GP$', 'Multi Buy Price', 'Multi GP%','MOQ', 'LLX','Notes', 'Supplier'
        ];
        const styledHeaders = headers.map(header => ({ text: header, style: 'tableHeader' }));
        const promoRow = [
          { colSpan: 18, text: promotion.promotionName + '  ' + `(Buying Period: ${this.dateFormatService.changeDateFormat(this.headers[this.promotionIndex].buying_start)} - ${this.dateFormatService.changeDateFormat(this.headers[this.promotionIndex].buying_end)})`, style: 'promoRowStyle' },
          {}, {}
      , {}, {}, {}, {}, {}, {}, {},{}, {}, {}, {}, {}, {}, {}, {}
      ];
      const allRows = promotion.suppliers.flatMap((supplier: any) => {
        if(supplier.groupComment === "No comment"){
          this.hasRowNote = false;
        }
        else{
          this.hasRowNote = true;
        }
        const supplierRow = [
          { text: `${this.hasRowNote ? supplier.groupComment: ''}`, colSpan: 18, style: `${this.hasRowNote ? 'hasRowNoteStyle': 'supplierStyle'}`},
          {}, {}
      , {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
      ];
      let previousIsDataRow
          // Map over the products of the supplier
          const productRows = supplier.products.flatMap((product: any) => {

            let ong_discount = ""
            const ten_percent = 1.1;
            const cost_inc_gst:any = Number(product.cost_ex_gst * ten_percent).toFixed(2)
            const value_gp = this.costService.calculateGpAmount(product)
            const percentage_difference = this.costService.calculateGpPercent(product)


            const costExGst = parseFloat(product.cost_ex_gst);
            const allowanceTotal = parseFloat(product.allowance_total);
            const allowanceUniversal = parseFloat(product.allowance_universal);
            const allow_promo = parseFloat(product.allowance_promotion);
            if (isNaN(costExGst))
            {
              ong_discount = "";
            }
            else{
              ong_discount = Number(allowanceTotal + allowanceUniversal).toFixed(2);
            }
            if(product.isCoreRange){

            }
            previousIsDataRow = false;
            const productRow = [
                { text: product.product_code, style: product.isCoreRange? 'bodyStyleMemberAdviceFocus':'bodyStyleMemberAdvice',isDataRow: true },
                { text: product.product_name, style: product.isCoreRange? 'bodyStyleMemberAdviceFocus':'bodyStyleMemberAdvice',isDataRow: true },
                { text: product.carton_quantity, style: 'bodyStyleMemberAdvice',isDataRow: true }, //carton qty
                { text: `$${(Number(product.cost_ex_gst) - Number(product.pick_fee)).toFixed(2)}`, style: 'bodyStyleMemberAdvice', isDataRow: true }, // pack cost

                { text: `$${(Number(product.allowance_universal) + Number(product.allowance_group)).toFixed(2)}`, style: 'bodyStyleMemberAdvice', isDataRow: true }, // discount per carton
                { text: `$${(Number(product.allowance)).toFixed(2)}`, style: 'bodyStyleMemberAdvice', isDataRow: true }, // discount per carton
                { text: `$${(Number(product.allowance_total)).toFixed(2)}`, style: 'bodyStyleMemberAdvice', isDataRow: true }, // discount per carton



                { text: `$${Number(product.luc_ex_gst).toFixed(2)}`, style: 'bodyStyleMemberAdvice', alignment: 'center' }, //LUC
                { text: `$${Number(cost_inc_gst).toFixed(2)}`, style: 'bodyStyleMemberAdvice',isDataRow: true }, // total inc gst
                { text: `$${Number(product.sell_price).toFixed(2) }`, style: 'bodyStyleMemberAdvice', alignment: 'center' },// single sell
                { text: this.costService.calculateGpPercent(product), style: 'bodyStyleMemberAdvice',isDataRow: true },
                { text: `$${this.costService.calculateGpAmount(product).toFixed(2)}`, style: 'bodyStyleMemberAdvice',isDataRow: true },
                { text: `$${Number(product.mix_match_price).toFixed(2)}`, style: 'bodyStyleMemberAdvice',isDataRow: true },
                { text: product.multi_buy ? `${this.costService.calculateRebateGpPercent(product)}` : 0, style: 'bodyStyleMemberAdvice',isDataRow: true },
                { text: product.moq ? product.moq : 0, style: 'bodyStyleMemberAdvice',isDataRow: true },
                { text: product.llx ? product.llx : '', style: 'bodyStyleMemberAdvice',isDataRow: true },



                //{ text: product.multi_buy ? `${product.multi_buy.substring(0, 1)}`: 0, style: 'bodyStyleMemberAdvice',isDataRow: true },

                { text: product.comment, style: 'bodyStyleMemberAdvice',isDataRow: true },
                { text: product.supplier_name, style: 'bodyStyleMemberAdvice',isDataRow: true },


            ];


            return [productRow]; // This will effectively add an empty row after each product row
        });

       if(promoRowHeaderIndex == 0){
        promoRowHeaderIndex++;
        return [promoRow, supplierRow, ...productRows];
       }
       else{
       // Return the supplier row followed by its product rows
          return [supplierRow, ...productRows];
       }
      });
        const styledRows = allRows.map((row:any) => this.styleRow(row, 'bodyStyleMemberAdvice'));


        const subheader = {
          stack: [
            {
              columns: [

                {
                  layout: {
                    hLineWidth: function(i:any, node:any) {
                      return 0;
                    },
                    vLineWidth: function(i:any, node:any) {
                      return 0;
                    },
                    paddingLeft: function(i:any, node:any) { return 4; },
                    paddingRight: function(i:any, node:any) { return 4; },
                    paddingTop: function(i:any, node:any) { return 2; },
                    paddingBottom: function(i:any, node:any) { return 2; }
                  },
                  alignment: 'left',
                  table: {
                    widths: ['*','auto',10,'auto', 'auto'],
                    body: [

                      [ {style:'header', text:`${this.name}`, colSpan: 3}, {},{text:''}, {image: this.base64Image.getBase64Image('LL'), fit: [100, 100]}, {fit: [100, 100], image: this.base64Image.getBase64Image('LLE')}]


                    ]
                  },
                },

                {
                  layout: {
                    hLineWidth: function(i:any, node:any) {
                      return 0;
                    },
                    vLineWidth: function(i:any, node:any) {
                      return 0;
                    },
                    paddingLeft: function(i:any, node:any) { return 4; },
                    paddingRight: function(i:any, node:any) { return 4; },
                    paddingTop: function(i:any, node:any) { return 2; },
                    paddingBottom: function(i:any, node:any) { return 2; }
                  },
                  alignment: 'left',
                  table: {
                    widths: ['*','auto', 'auto','auto','auto'],
                    body: [

                      [{}, {text:`${promotion.suppliers[0].products[0].account_code}`, style:'memberAdviceHeader'},{ style:'memberAdviceHeader', text:`${this.filtered_by}`},{style:'memberAdviceHeader', text:`${this.headers[this.promotionIndex].promotion_name}`}, {style:'memberAdviceHeader', text:`${promotion.promotionName}`}],
                      [{},{},{colSpan:3, style:'memberAdviceHeaderPromotionDate', text:`Promotion Period:   ${this.dateFormatService.changeDateFormat(promotion.promotion_start)} - ${this.dateFormatService.changeDateFormat(promotion.promotion_end)}`}, {}, {}],

                    ]
                  },
                }


                  ]
            }
              ]
            }





 if(this.headers.length <= 2 && this.promotionIndex < (this.headers.length - 1)){
this.promotionIndex++;
promoRowHeaderIndex = 0;
        return [
            subheader,

            {
                table: {
                    headerRows: 1,
                    widths: [ 25, '*', 22,22,24,24,23,23, 23, 23, 25, 23, 22, 22,22,22, 60, 'auto'],
                    body: [styledHeaders, ...allRows]
                },

                layout: {
                  hLineWidth: (i:any, node:any) => {
                    if (i == 0 || i == node.table.body.length) {
                        return Number(1); // Thicker top and bottom borders for the entire table
                    }
                    // Check if the current row or the previous row is a supplier header to draw a horizontal line
                    if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle' ||  node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle') {
                        return Number(1); // Draw line below and above supplier headers
                    }
                    if (node.table.body[i][0].style === 'hasRowNoteStyle') {
                      return Number(1);
                  }
                    return Number(0); // No line between product rows
                },
                vLineWidth: function (i:any, node:any) {
                  return (i === 0 || i === node.table.widths.length) ? Number(1) : Number(0); // Thicker left and right borders
              },
              paddingTop: (i:any, node:any) =>{

                    // Check if the current row or the previous row is a supplier header to draw a horizontal line
                    if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle'||
                      (node.table.body[i] && node.table.body[i][0].style === 'hasRowNoteStyle')) {
                        return Number(3);
                      }
                    if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle'||
                      (node.table.body[i] && node.table.body[i][0].style === 'supplierStyle')) {

                          return Number(0); // Draw line below and above supplier headers
                        }

                    return Number(3)
              },
              paddingBottom: (i:any, node:any) =>{
                // Check if the current row or the previous row is a supplier header to draw a horizontal line
                if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle'||
                  (node.table.body[i] && node.table.body[i][0].style === 'hasRowNoteStyle')) {
                    return Number(3);
                  }
                if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle'||
                  (node.table.body[i] && node.table.body[i][0].style === 'supplierStyle')) {

                      return Number(0); // Draw line below and above supplier headers
                    }

                return Number(3)
          },
                  hLineColor: () => 'black',
                  vLineColor: () => 'black',
                  fillColor: function (rowIndex:any, node:any, columnIndex:any) {
                    // Check if the current row is a supplier header row
                    if (node.table.body[rowIndex][0].style === 'supplierStyle') {
                        return 'black'; // Fill color black for supplier headers
                    }
                    if(node.table.body[rowIndex][0].style === 'bodyStyleMemberAdviceFocus'){
                      return '#d3a9e8'
                    }
                    else{
                    return (rowIndex % 2 === 0) ? '#d9f0f1' : null; // Alternate coloring for other rows
                    }
                },

                }

          }

        ];
      }
      else if(this.promotionIndex < (this.headers.length - 2)){
        this.promotionIndex++;
        promoRowHeaderIndex = 0;
        return [
            subheader,

            {
                table: {
                    headerRows: 1,
                    widths: [ 25, '*', 22,22,24,24,23,23, 23, 23, 25, 23, 22, 22,22,22, 60, 'auto'],
                    body: [styledHeaders, ...allRows]
                },
                pageBreak: 'after',
                layout: {
                  hLineWidth: (i:any, node:any) => {
                    if (i == 0 || i == node.table.body.length) {
                        return Number(1); // Thicker top and bottom borders for the entire table
                    }
                    // Check if the current row or the previous row is a supplier header to draw a horizontal line
                    if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle' ||  node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle') {
                        return Number(1); // Draw line below and above supplier headers
                    }
                    if (node.table.body[i][0].style === 'hasRowNoteStyle') {
                      return Number(1);
                  }
                    return Number(0); // No line between product rows
                },
                vLineWidth: function (i:any, node:any) {
                  return (i === 0 || i === node.table.widths.length) ? Number(1) : Number(0); // Thicker left and right borders
              },
              paddingTop: (i:any, node:any) =>{
                // Check if the current row or the previous row is a supplier header to draw a horizontal line
                if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle'||
                  (node.table.body[i] && node.table.body[i][0].style === 'hasRowNoteStyle')) {
                    return Number(3);
                  }
                if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle'||
                  (node.table.body[i] && node.table.body[i][0].style === 'supplierStyle')) {

                      return Number(0); // Draw line below and above supplier headers
                    }

                return Number(3)
          },
          paddingBottom: (i:any, node:any) =>{
            // Check if the current row or the previous row is a supplier header to draw a horizontal line
            if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle'||
              (node.table.body[i] && node.table.body[i][0].style === 'hasRowNoteStyle')) {
                return Number(3);
              }
            if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle'||
              (node.table.body[i] && node.table.body[i][0].style === 'supplierStyle')) {

                  return Number(0); // Draw line below and above supplier headers
                }

            return Number(3)
      },
                  hLineColor: () => 'black',
                  vLineColor: () => 'black',
                  fillColor: function (rowIndex:any, node:any, columnIndex:any) {
                    // Check if the current row is a supplier header row
                    if (node.table.body[rowIndex][0].style === 'supplierStyle') {
                        return 'black'; // Fill color black for supplier headers
                    }
                    else{
                    return (rowIndex % 2 === 0) ? '#d9f0f1' : null; // Alternate coloring for other rows
                    }
                },
              }

          }

        ];
      }
      else{

        promoRowHeaderIndex = 0;
        return [
            subheader,

            { // widths: [ 10,10,30, '*', 30, 30, 30, 30, 30, 30, 30, 90, 'auto', 90 ],
                table: {
                    headerRows: 1,
                    widths: [ 25, '*', 22,22,24,24,23,23, 23, 23, 25, 23, 22, 22,22,22, 60, 'auto'],
                    body: [styledHeaders, ...allRows]
                },

                layout: {
                  hLineWidth: (i:any, node:any) => {
                    if (i === 0 || i === node.table.body.length) {
                        return Number(0); // Thicker top and bottom borders for the entire table
                    }
                    // Check if the current row or the previous row is a supplier header to draw a horizontal line
                    if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle' ||  node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle') {
                        return Number(1); // Draw line below and above supplier headers
                    }
                    if (node.table.body[i][0].style === 'hasRowNoteStyle') {
                      return Number(1);
                  }
                    return Number(0); // No line between product rows
                },
                vLineWidth: function (i:any, node:any) {
                  return (i === 0 || i === node.table.widths.length) ? Number(1) : Number(0); // Thicker left and right borders
              },
              paddingTop: (i:any, node:any) =>{
                // Check if the current row or the previous row is a supplier header to draw a horizontal line

                if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle'||
                  (node.table.body[i] && node.table.body[i][0].style === 'hasRowNoteStyle')) {
                    return Number(3);
                  }
                if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle'||
                  (node.table.body[i] && node.table.body[i][0].style === 'supplierStyle')) {

                      return Number(0); // Draw line below and above supplier headers
                    }

                return Number(3)
          },
          paddingBottom: (i:any, node:any) =>{
            // Check if the current row or the previous row is a supplier header to draw a horizontal line
            if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'hasRowNoteStyle'||
              (node.table.body[i] && node.table.body[i][0].style === 'hasRowNoteStyle')) {
                return Number(3);
              }
            if (node.table.body[i - 1] && node.table.body[i - 1][0].style === 'supplierStyle'||
              (node.table.body[i] && node.table.body[i][0].style === 'supplierStyle')) {

                  return Number(0); // Draw line below and above supplier headers
                }

            return Number(3)
      },
                  hLineColor: () => 'black',
                  vLineColor: () => 'black',
                  fillColor: function (rowIndex:any, node:any, columnIndex:any) {
                    // Check if the current row is a supplier header row
                    if (node.table.body[rowIndex][0].style === 'supplierStyle') {
                        return 'black'; // Fill color black for supplier headers
                    }
                    else{
                    return (rowIndex % 2 === 0) ? '#d9f0f1' : null; // Alternate coloring for other rows
                    }
                },
              }
          }

        ];
      }

    });

}

styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}



pdfCreator(promotions: any[]) {
  const transformedDataArray = this.transformDataForPdfMake(promotions);

  const styles: StyleDictionary = {
    header: {
      fontSize: 16,
      bold: true,
      margin: [0, 10, -10, 0],
      alignment: 'center'
    },
    supplierStyle: {
      fontSize: 6,
      bold: true,
      fillColor: 'white', // Some light background color
    },
    hasRowNoteStyle: {
      fontSize: 6,
      bold: true,
      fillColor: 'yellow', // Some light background color
    },
    promoRowStyle:{
      fontSize: 10,
      bold: true,
      fillColor: '#bfe6f9', // Some light background color
    },
      footer: {
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'center'
      },
      bodyStyleMemberAdvice: {
        fontSize: 6
      },
      bodyStyleMemberAdviceFocus: {
        fontSize: 6,
        fillColor: '#d3a9e8'
      },
      coreStyle: {
        color: '#ff0000',
        fontSize:8
      },
      tableHeader: {
        bold: true,
        fontSize: 6,
        color: 'white',
        fillColor: '#182351',
      },
      memberAdviceHeaderPromotionDate:{
        fontSize: 10
      },
      memberAdviceHeader:{
        bold: true,
      },
        coreRange: {
          bold: true,
          color: 'red',
          fontSize: 8
      },
    emptybodyStyleMemberAdvice: {
      margin: [0,3,0,3],
      fontSize: 8,
    },

    copyrightFooter:{
      fontSize: 8,
      alignment: 'right',
      marginRight: 40

    }

    // ... other styles
  };

  const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `Member Advice ${this.promotionName}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },

                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }

  };
  this.promotionIndex = 0;
// Dynamically generate a filename based on specific data
const filename = `Member_Advice_${this.promotionName}_${new Date().toDateString()}.pdf`;
pdfMake.createPdf(documentDefinition).download(filename);
this.loadingService.toggleLoading(false);

}

async getPromoData(e: any) {
  if (!e.groupSelected) {
    return;
  }
  console.log(e)
  this.filtered_by = e.groupSelectedName;
  let sortedHeaders:any
  this.disclaimer = e.disclaimer;

  await this.crudService.getData(`${this.apiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).subscribe((result: any) => {
    this.promotionName = result[0].promotion_name;

     sortedHeaders = result.sort((a:any, b:any) => {
      const descriptionA = a.description || '';
      const descriptionB = b.description || '';
      return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
    });

    this.headers = sortedHeaders;
  });
console.log("e", e)
let promotion_period: any = [];
if(localStorage.getItem('promotion-names') !== null) {
  promotion_period = localStorage.getItem('promotion-names');
  promotion_period = JSON.parse(promotion_period);
}
let selectedPromotion = promotion_period.find((promoPeriod:any) => promoPeriod.promotion_name === e.promotionNameSelected);
let promotion_period_end_date;
let promotion_period_start_date;

if (selectedPromotion) {
    console.log("Found promotion:", selectedPromotion);
    promotion_period_start_date = selectedPromotion.promotion_start;
    promotion_period_end_date = selectedPromotion.promotion_end
} else {
    console.log("Promotion not found");
}
  console.log(promotion_period_start_date)
  console.log(promotion_period_end_date)
  console.log("promoperiod", promotion_period)
  this.crudService.getData(`${this.apiUrl}core-range-details?start_date=${promotion_period_start_date}&end_date=${promotion_period_end_date}&buying_group_id=${e.groupSelected.value}`).subscribe((coreRangeResult: any) => {
    const coreRangeFiltered = coreRangeResult.data.filter((item: any) => item.audit === 1);
    const potentialRebatable = coreRangeResult.data.filter((item: any) => item.rebate_total);
    console.log("corerange?",coreRangeResult)
    console.log("rebated?",potentialRebatable)
    const coreRangeIds = new Set<any>(coreRangeFiltered.map((item: any) => item.product_id));
    const rebatableIds = new Set<any>(potentialRebatable.map((item: any) => item.product_id));
    const coreRange = new Map<number, number>(coreRangeResult.data.map((item: any) => [item.product_id, item.rebate_total]));
    // Fetch the promotion data
    this.crudService.getData(`${this.promoDetailsUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&members_advice`).subscribe((promoResult: any) => {
        const firstHeaderId = promoResult.data[0].promotion_header_id;
        try {
            let promoDataWithCoreRange = promoResult.data;
            console.log("promoDataWithCoreRange", promoDataWithCoreRange)

            // Check if the data is an array and the first element has expected properties
            if (!Array.isArray(promoDataWithCoreRange) || promoDataWithCoreRange.length === 0 || !promoDataWithCoreRange[0].product_id) {
                console.error('Unexpected data format or no data:', promoDataWithCoreRange);
                throw new Error('Invalid or no data returned from the server');
            }

            // If the first item doesn't have the expected structure, handle as an error or empty data
            if (typeof promoDataWithCoreRange[0] === 'object' && Object.keys(promoDataWithCoreRange[0]).some(key => isNaN(Number(key)))) {
                const errorMessage = Object.values(promoDataWithCoreRange[0]).join('');
                if (errorMessage.toLowerCase().includes("no records returned")) {
                    console.error('No records returned:', errorMessage);
                    throw new Error('No records returned from the server');
                }
            }

            // If the data structure is as expected, map over it to attach additional properties
      promoDataWithCoreRange = promoResult.data.map((promoProduct: any) => {
        let hasRebate = 0;
        return {
          ...promoProduct,
          isCoreRange: coreRangeIds.has(promoProduct.product_id),
          hasRebate: rebatableIds.has(promoProduct.product_id),
          promotion_header_size_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
        };
      });

            if (promoDataWithCoreRange.length > 0) {
                if (this.selectedFilter) {
                    this.data = this.groupProductService.groupByProductValue(promoDataWithCoreRange, (product: any) => product[this.selectedFilter], sortedHeaders);
                } else {
                    // Default grouping
                    this.data = this.groupProductService.groupByProductValue(promoDataWithCoreRange, (product: any) => product.promotion_group_name, sortedHeaders);
                }
            }

            console.log("data sent:", this.data);
            this.pdfCreator(this.data);
            this.data = [];
            promoDataWithCoreRange = [];
        } catch (e: any) {
            console.error('Error processing promo data:', e);
            notify("No data found in this configuration.", 'error', 3000);
            this.loadingService.toggleLoading(false);
        }
    });
}, (error:any) => {
    console.error('Error fetching core range data:', error);
    notify("Error fetching core range data.", 'error', 3000);
    this.loadingService.toggleLoading(false);
});
}


}
