import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import { MixMatchLevel } from 'src/app/models/mix-match-level.model';
import { NotifyService } from 'src/app/services/notify.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { MixMatchItemComponent } from '../mix-match-item/mix-match-item.component';
@Component({
    selector: 'app-mix-match-level',
    templateUrl: './mix-match-level.component.html',
    styleUrls: ['./mix-match-level.component.css'],
    imports: [ DxDataGridModule, MixMatchItemComponent ],
    standalone: true
})
export class MixMatchLevelComponent implements AfterViewInit{
  @Input() key: number;
  @ViewChild('mixMatchLevelGrid', {static: false}) mixMatchLevelGrid: DxDataGridComponent;
  private apiUrl : string = environment.baseApiUrl;
  levels: MixMatchLevel[] = [];
  mixMatchDataSource: DataSource;
  parent: string = 'mix-match-header'

  constructor(private crudService: CrudService,
    private notifyService: NotifyService ) {}

  async ngAfterViewInit() {
    await this.loadData();
  }

  async loadData()
  {
    console.log('Key: ', this.key);
    const mixMatchLevels = await this.crudService.getData(`${this.apiUrl}mix-match-levels?header_id=${this.key}`).toPromise();
    this.levels = mixMatchLevels;
    console.log('Levels: ', this.levels);
    this.mixMatchDataSource = await new DataSource({
      store: new ArrayStore({
        data: this.levels
      })
    });
  }

  onRowUpdating = (e: any) => {
      this.crudService.updateData(`${this.apiUrl}mix-match-levels`, e.key.id, e.newData).pipe(
        catchError((err) => {
          this.loadData();
          this.notifyService.tellUser(err);
          return throwError(err);
        })
      )
      .subscribe((result: any) => {
        this.notifyService.tellUser(result);
      });
  }

  onRowInserting = (e: any) => {
    e.data.header_id = this.key;
    this.crudService.insertData(`${this.apiUrl}mix-match-levels`, e.data).pipe(
      catchError((err) => {
        //this.loadData();
        this.notifyService.tellUser(err);
        return throwError(err);
      })
    )
    .subscribe((result: any) => {
      this.notifyService.tellUser(result);
    });
  }

  onRowRemoving = (e: any) => {
    this.crudService.deleteData(`${this.apiUrl}mix-match-levels`, e.data.id).pipe(
      catchError((err) => {
        this.loadData();
        this.notifyService.tellUser(err);
        return throwError(err);
      })
    ).subscribe((result: any) => {
        this.notifyService.tellUser(result);
      });
  }
}
