<div><p class="nav_id">promotions > <u>promotion-details</u></p></div>

<div [ngClass]="{ 'hidden': detailsLoading, 'visible': !detailsLoading }" id="promotion-header"><dx-button id="back-button" icon="arrowleft" [width]="32" [height]="32"(click)="returnToPlanning($event)"></dx-button> <p><b>{{promoType}}</b></p></div>
<div id="tooltip" class="tooltip">Core Range Product</div>

<div class="promoDetailsGrid" >
<dx-data-grid #promoDetailsGrid [ngClass]="{ 'hidden': detailsLoading, 'visible': !detailsLoading }"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [rowAlternationEnabled]="true"
  [focusedRowEnabled]="true"
  [wordWrapEnabled]="true"
  [height]="'90%'"
  [width]="'100%'"
  [columnAutoWidth]="true"
  (onRowPrepared)="onRowPrepared($event)"
  (onFocusedRowChanging)="autoSave($event)"
  [showBorders]="true"
  (onContentReady)="contentReadyLoading($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onEditingStart)="onEditingStart($event)"
  (onContextMenuPreparing)="onContextMenuPreparing($event)"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  >
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-column-chooser
    #columnChooser
    [enabled]="true"
    mode="select"
  >
    <dxo-position
    my="right top"
    at="right bottom"
    of=".dx-datagrid-column-chooser-button"
  ></dxo-position>
  </dxo-column-chooser>
  <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    storageKey="promotionDetailsState"
  ></dxo-state-storing>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-editing
    mode="batch"
    refreshMode="full"
    [allowAdding]="allowEditing"
    [allowUpdating]="allowEditing"
    [allowDeleting]="allowEditing"
  ></dxo-editing>
  <dxo-toolbar>
    <dxi-item name="saveButton"></dxi-item>
    <dxi-item  location="after">
      <div *dxTemplate>
    <dx-button class="search-toggle"
    icon="search"
    [class.active]="showFilterRow"
    (onClick)="toggleFilterRow()">
    </dx-button>
    </div>
    </dxi-item>
    <dxi-item name="groupPanel"></dxi-item>



    <!-- <dxi-item name="revertButton"></dxi-item> -->
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="plus" hint="Add Product" [disabled]="disabled" (onClick)="openPromotionGroupSelection($event)">
        </dx-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="money" hint="Update Costs" [disabled]="disabled" (onClick)="updateCosts()">
        </dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Refresh" (onClick)="refreshGrid()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="product_code" dataType="string" caption="Product Code" [allowEditing]="false" [fixed]="true"></dxi-column>
  <dxi-column dataField="product_name" dataType="string"  caption="Product Name" [allowEditing]="false" [fixed]="true"></dxi-column>
  <dxi-column dataField="planning_category_name" dataType="string" caption="Category"  [allowEditing]="false"></dxi-column>
  <dxi-column dataField="promotion_group_id" dataType="number" [visible]="false"></dxi-column>
  <dxi-column dataField="promotion_group_name" dataType="string" caption="Promotion Group" [allowEditing]="false">
    <dxo-lookup [dataSource]="promotionGroups" displayExpr="promotion_group_name"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="supplier_name" dataType="string" caption="Supplier" [allowEditing]="false"></dxi-column>
  <div *dxTemplate="let data of 'mixMatchTemplate'">
    Mix n Match
    <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Mix n Match" (onClick)="mixMatchPopupEvent($event)"></dx-button>
  </div>
  <dxi-column dataField="mix_match_header_name" dataType="string" caption="Mix n Match" [allowEditing]="false" headerCellTemplate="mixMatchTemplate"></dxi-column>
  <dxi-column dataField="carton_quantity" dataType="number" caption="Carton" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="packs_per_carton" dataType="number" caption="Packs" [allowEditing]="false"></dxi-column>
  <div *dxTemplate="let data of 'rebateTemplate'">
    Rebates
    <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Promotion Rebates" (onClick)="rebatePopupEvent($event)"></dx-button>
  </div>
  <dxi-column caption="Rebates" alignment="center" headerCellTemplate="rebateTemplate">
    <ng-container *ngFor="let column of columns">
      <dxi-column
        [dataField]="column.dataField"
        [name]="column.dataField"
        [format]="currencyFormat"
        [dataType]="column.dataType"
        [allowEditing]="true"
        [setCellValue]="setRebateTotal">
    </dxi-column>
    </ng-container>
    <dxi-column dataField="rebate" dataType="number" [format]="currencyFormat" [allowEditing]="false" caption="Rebate Total"></dxi-column>
    <dxi-column dataField="max_rebate_qty" dataType="number" caption="Max Rebate Qty"></dxi-column>
  </dxi-column>
  <dxi-column dataField="store_position_name" dataType="string" caption="Store Position">
    <dxo-lookup [dataSource]="storePositions" valueExpr="position_name" displayExpr="position_name"></dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let data of 'allowanceTemplate'">
    Allowances
    <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Promotion Allowances" (onClick)="allowancePopupEvent($event)">
    </dx-button>
    </div>
  <dxi-column caption="Allowances" alignment="center" headerCellTemplate="allowanceTemplate">
    <dxi-column dataField="allowance" [format]="currencyFormat" dataType="number" caption="Allowance" [setCellValue]="setAllowanceTotal"></dxi-column>
    <dxi-column dataField="allowance_group" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Allowance Group"></dxi-column>
    <dxi-column dataField="allowance_promotion" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Allowance Promotion"></dxi-column>
    <dxi-column dataField="allowance_total" [format]="currencyFormat" dataType="number" caption="Allowance Total" [setCellValue]="setAllowance" [calculateCellValue]="calculateAllowanceTotal"></dxi-column>
  </dxi-column>
  <dxi-column dataField="allowance_universal" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Uni. Deal"></dxi-column>
  <dxi-column caption="Product Costs" alignment="center">
    <dxi-column dataField="cost_ex_gst" [format]="currencyFormat" dataType="number" caption="Std Cost ex WET" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="cost_inc_wet_only" [format]="currencyFormat" dataType="number" caption="Std Cost"  [allowEditing]="false"></dxi-column>
    <!-- <dxi-column [format]="{type: 'currency', precision: 2}" [width]="'70'"minWidth="70" caption="Std Cost ex.GST" [allowEditing]="false" [calculateCellValue]="calculateCostExGst"></dxi-column> -->
    <!-- <ng-container *ngFor="let column of columns">
      <dxi-column
        [caption]="column.caption"
        [dataField]="column.dataField"
        [cssClass]="column.cssClass"
        [calculateCellValue]="calculateAllowance"
        [visible]="column.visible"
        [format]="column.format"
        [allowEditing]="true"
        minWidth="70">
    </dxi-column>
    </ng-container> -->
    <dxi-column dataField="new_cost" [format]="currencyFormat" caption="New Cost" [calculateCellValue]="calculateNewCost" [allowEditing]="false"></dxi-column>
    <!-- <dxi-column dataField="luc_ex_gst" [format]="currencyFormat" dataType="number" [width]="'60'"minWidth="70" caption="LUC ex.GST" [allowEditing]="false"></dxi-column> -->
  </dxi-column>
  <dxi-column caption="Product Sell Price" alignment="center">
    <!-- <dxi-column dataField="list_price" [format]="{type: 'currency', precision: 2}" [width]="'70'"minWidth="80" caption="Std Price" [allowEditing]="false"></dxi-column> -->
    <dxi-column dataField="sell_price" [format]="currencyFormat" dataType="number" caption="Promo Price"></dxi-column>
    <dxi-column dataField="gp_percent" [format]="{type: 'fixedPoint', precision: 2}" caption="GP %" [calculateCellValue]="calculateGpPercent" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="gp_amount" [format]="currencyFormat" caption="GP $" [calculateCellValue]="calculateGpAmount" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="rrp" [format]="currencyFormat" caption="RRP" [allowEditing]="false" [visible]="false"></dxi-column>
  </dxi-column>
  <dxi-column dataField="price_override" dataType="boolean" caption="Price Override" [visible]="false"></dxi-column>
  <dxi-column dataField="comment" dataType="string" caption="Comment" [visible]="false"></dxi-column>
  <dxi-column caption="Rebate Inclusive" alignment="center" [visible]="false">
    <dxi-column [format]="currencyFormat" caption="Cost" [calculateCellValue]="calculateRebateCost" [allowEditing]="false"></dxi-column>
    <dxi-column [format]="currencyFormat" caption="GP$" [calculateCellValue]="calculateRebateGpAmount" [allowEditing]="false"></dxi-column>
    <dxi-column [format]="currencyFormat" caption="GP%" [calculateCellValue]="calculateRebateGpPercent" [allowEditing]="false"></dxi-column>
  </dxi-column>

  <dxo-summary>
    <dxi-group-item column="product_group_name" summaryType="count"> </dxi-group-item>
  </dxo-summary>
</dx-data-grid>
<dx-tooltip position="right">
  <div class='tooltipContent'>
    <div>Mix n Match Item</div>
  </div>
</dx-tooltip>
</div>
<!--Product Selection-->
<!-- <dx-popup #productSelection
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Products"
  height="75%"
  width="70%"
  [showCloseButton]="true">
    <app-product-selection (loadParentData)="loadData()" (closeChild)="closeProductSelection()" [parent]="parent"></app-product-selection>
</dx-popup> -->
<!-- Promotion Group Selection-->
<dx-popup #promotionGroupSelection
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Products"
  height="75%"
  width="70%"
  [showCloseButton]="true"
  (onHiding)="promotionGroupSelectionPopup.onHiding($event)">
    <app-promotion-group-selection #promotionGroupSelectionPopup (loadParentData)="loadData()" (closeChild)="closePromotionGroupSelection()"></app-promotion-group-selection>
</dx-popup>
<!--Promotion Allowances Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionAllowancesPopup.editPromotionAllowances"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Allowances"
  height="50%"
  width="400px"
  [showCloseButton]="true"
  (onHiding)="promotionAllowancesPopup.onHiding($event)">
    <app-promotion-allowances #promotionAllowancesPopup></app-promotion-allowances>
</dx-popup>

<!--Promotion Rebates Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionRebatesPopup.editPromotionRebates"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Rebates"
  height="50%"
  width="400px"
  [showCloseButton]="true"
  (onHiding)="promotionRebatesPopup.onHiding($event)">
    <app-promotion-rebates #promotionRebatesPopup (loadParentData)="loadData()"></app-promotion-rebates>
</dx-popup>

<!-- Mix n Match -->
<dx-popup
  [(visible)]="mixMatch.editMixMatch"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Mix n Match"
  height="95%"
  width="60%"
  [showCloseButton]="true"
  (onHiding)="mixMatch.onHiding($event)">
    <app-mix-match #mixMatch></app-mix-match>
</dx-popup>

<!-- Mix n Match Qty -->
<dx-popup *ngIf="permissions"
  #mixMatchQty
  [(visible)]="mixMatchQtyPopup.editMixMatchQty"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Add Mix n Match"
  height="400px"
  width="500px"
  [showCloseButton]="true"
  (onShowing)="mixMatchQtyPopup.onShowing($event)"
  (onHiding)="mixMatchQtyPopup.onHiding($event)">
    <app-mix-match-qty #mixMatchQtyPopup
      (qty)="getQty($event)"
      (importAllCheckbox)="getimportAllCheckbox($event)"
      (price)="getPrice($event)"
      (close)="createMixnMatch()"
      (quickAdd)="quickAddMixMatch($event)"
      (mixMatchHeaderId)="getMixMatchHeaderId($event)"
      (nameFieldEmitter)="getMixnMatchName($event)"
      (mixMatchHeaderName)="getMixMatchHeaderName($event)"
      [productId]="productId">
    </app-mix-match-qty>

    <dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="detailsLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>
