import { Component, Output, EventEmitter } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PromotionHeader } from '../models/promotion-header.model';
import { PromotionType } from '../models/promotion-type.model';
import { CrudService } from '../services/crud.service';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../services/notify.service';
import { DxBoxModule, DxButtonModule, DxDateBoxModule, DxLoadPanelModule, DxSelectBoxModule, DxSwitchModule, DxTextBoxModule } from 'devextreme-angular';

@Component({
    selector: 'app-clone-promotion',
    templateUrl: './clone-promotion.component.html',
    styleUrls: ['./clone-promotion.component.css'],
    imports: [ CommonModule, DatePipe, DxBoxModule, DxSelectBoxModule, DxTextBoxModule, DxDateBoxModule, DxSwitchModule, DxButtonModule, DxLoadPanelModule ],
    standalone: true
})
export class ClonePromotionComponent {
  @Output() clonePromotionValues: EventEmitter<any> = new EventEmitter<any>();
  currentPromotion: PromotionHeader = new PromotionHeader();
  promotionTypes: PromotionType[] = [];
  promotionType: number;
  buyingGroups: any[] = [];
  buyingGroupId: number;
  buyingStart: string;
  buyingEnd: string;
  description: string;
  rebate_clone: boolean = false;
  loadingVisible: boolean = false;
  apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
    private notifyService: NotifyService) {
      this.crudService.getData(`${this.apiUrl}buying-groups`).subscribe((data: any) => {
        this.buyingGroups = data
      });

      this.crudService.getData(`${this.apiUrl}promotion-types`).subscribe((data: any) => {
        this.promotionTypes = data
      });
  }

  ngAfterContentChecked() {
    if(localStorage.getItem('selected-promotion') !== null) {
      this.currentPromotion = JSON.parse(localStorage.getItem('selected-promotion') || '');
      this.currentPromotion.description = this.currentPromotion.description + ' (COPY)';
    }
  }

  setPromotionType(e: any)
  {
    this.promotionType = e.value;
  }

  setBuyingGroup(e: any)
  {
    this.buyingGroupId = e.value;
  }

  setBuyingStart(e: any)
  {
    this.buyingStart = e.value;
  }

  setBuyingEnd(e: any)
  {
    this.buyingEnd = e.value;
  }

  setDescription(e: any)
  {
    this.description = e.value;
  }

  setRebateClone(e: any)
  {
    this.rebate_clone = e.value;
  }

  cancel() {
    this.clonePromotionValues.emit(false);
  }

  clonePromotion()
  {
    this.loadingVisible = true;
    let path: string = `clone-promotion`;
    if(this.promotionType !== undefined)
      this.currentPromotion.promotion_type = this.promotionType;
    if(this.buyingGroupId !== undefined)
      this.currentPromotion.buying_group_id = this.buyingGroupId;
    if(this.buyingStart !== undefined)
      this.currentPromotion.buying_start = this.buyingStart;
    if(this.buyingEnd !== undefined)
      this.currentPromotion.buying_end = this.buyingEnd;
    if(this.description !== undefined)
      this.currentPromotion.description = this.description;
    if(this.rebate_clone !== undefined && this.rebate_clone === true)
      path = `clone-promotion?clone_rebate=1`

    delete this.currentPromotion.status;

    try {
      this.crudService.insertData(`${this.apiUrl}${path}`, this.currentPromotion).subscribe((data: any) => {
        console.log(data);
        this.loadingVisible = true;
        if(data.success) {
          this.notifyService.tellUser(data);
          this.loadingVisible = false;
          this.clonePromotionValues.emit(true);
        } else {
          this.notifyService.tellUser(data);
          this.loadingVisible = false;
          this.clonePromotionValues.emit(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}
