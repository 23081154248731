<dx-box direction="row" width="100%" height="75">
  <dxi-item [ratio]="0" baseSize="150">
    <div class="dx-fieldset">
      <div class="dx-fieldset-label">Host File Date</div>
      <dx-lookup
      [items]="fileDates"
      (onValueChanged)="setDate($event)"></dx-lookup>
    </div>
    <dxi-validation-rule type="required" message="Buying group code is required"></dxi-validation-rule>
  </dxi-item>
  <dxi-item [ratio]="0" baseSize="150">
    <div class="dx-fieldset">
      <div class="dx-fieldset-label">Account Code</div>
      <dx-lookup
      [items]="accountCodes"
      (onValueChanged)="setAccountCode($event)"></dx-lookup>
    </div>
  </dxi-item>
  <dxi-item [ratio]="0" baseSize="150">
    <div class="dx-fieldset">
      <div class="button-spacer"></div>
        <dx-button
          stylingMode="contained"
          text="Load"
          type="default"
          [width]="120"
          (onClick)="loadData()"
        ></dx-button>
      </div>
  </dxi-item>
  <dxi-item [ratio]="0" baseSize="150">
    <div class="dx-fieldset">
      <div class="button-spacer"></div>
        <dx-button
          stylingMode="contained"
          text="Rollback"
          type="default"
          [width]="120"
          [disabled]="rollBackDisabled"
          (onClick)="rollBackData()"
        ></dx-button>
      </div>
  </dxi-item>
</dx-box>
<div class="grid">
<dx-data-grid #rawHostData [ngClass]="{ 'hidden': dataLoading, 'visible': !dataLoading }"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  [showColumnLines]="true"
  [wordWrapEnabled]="true"
  [columnAutoWidth]="true"
  [loadPanel]="{ enabled: false }">

  <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    storageKey="rawHostDataState"
  ></dxo-state-storing>

  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-column-chooser
    #columnChooser
    [enabled]="true"
    mode="select"
  ></dxo-column-chooser>

  <dxo-search-panel [visible]="true"></dxo-search-panel>

</dx-data-grid>
<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="dataLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>
</div>
