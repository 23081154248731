import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config.model';
import { BuyingGroups } from '../models/buying-groups.model';
import { ConfigService } from './config.service';
import { Observable, of, tap, take } from 'rxjs';
import { UserDetails } from '../models/user-details';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import themes from 'devextreme/ui/themes';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  private apiUrl = environment.baseApiUrl;
  private authApiUrl = environment.authApiUrl;
  private userDetails: UserDetails;
  constructor(private crudService: CrudService,
              private configService: ConfigService,
              private cookieService: CookieService,
              private http: HttpClient) {

               }

  async getHostFileData() {
    this.getUserDetails().subscribe(data => {
      this.userDetails = JSON.parse(JSON.stringify(data));
      document.body.style.backgroundColor = environment.bgColours[data.data.theme];
      localStorage.setItem('theme', data.data.theme === 0 ? 'Light' : 'Dark');
      localStorage.setItem('switchValue', data.data.theme === 0 ? 'false' : 'true');
    });
    this.crudService.getData(`${this.apiUrl}config`).subscribe(async (data: any) => {
      console.log('Config Data: ', data);
      sessionStorage.setItem('ConfigData', JSON.stringify(data));
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');
      const dataArray = Object.values(data);
      let lastUpdate = '';
      const foundConfig: Config = dataArray.find((config: any) => config.config_key === 'LastHostFileUpdate') as Config;
      if (foundConfig) {
        lastUpdate = foundConfig.config_string;
      } else {
        lastUpdate = '2023-09-11';
      }
      if(lastUpdate < new Date().toISOString().split('T')[0]) {
        const response: BuyingGroups[] = await this.crudService.getData(`${this.apiUrl}buying-groups`).toPromise();
        const data = {
          main_supplier_token: parsedDetails.data.location_token || '',
          date_added: lastUpdate
        }
        response.forEach(BuyingGroup => {
          this.crudService.updateData(`${this.apiUrl}host-data`, BuyingGroup.account_code, data).subscribe((response: any) => {
            console.log('products pricing: ', response);
          });
        });
        this.crudService.getData(`${this.apiUrl}host-data?date_added=${lastUpdate}`).subscribe((hostData: any) => {
          console.log('Host Data: ', hostData);
          if(hostData && hostData.success) {
            const configData = {
              group_id: parsedDetails.data.group_id,
              location_id: parsedDetails.data.location_id,
              config_key: 'LastHostFileUpdate',
              config_string: new Date().toISOString().split('T')[0]
            };
            this.crudService.updateData(`${this.apiUrl}config`, 1, configData).subscribe();
          }
        }, (error: any) => {
          console.log('Error fetching host data: ', error);
        });
      }
    });
  }

  getUserDetails(): Observable<UserDetails> {
      let tokenDetails: any = {};
      if(this.cookieService.get('otd-user')) {
        tokenDetails = JSON.parse(this.cookieService.get('otd-user'));
      } else if(sessionStorage.getItem('user')) {
        const userDetailsString = sessionStorage.getItem('user') || '';
        const userDetails = JSON.parse(userDetailsString);
        tokenDetails.email = userDetails.email;
        tokenDetails.token = sessionStorage.getItem('token') || '';
      } else {
        console.error('User details cookie is missing or empty');
        return of({ data: {} } as UserDetails);
      }
      console.log('User details:', tokenDetails);
      if (!tokenDetails) {
        // Handle the case where the cookie is not set or is empty
        console.error('User details cookie is missing or empty');
        return of({ data: {} } as UserDetails); // Return an empty UserDetails object or handle as needed
      }
      const url = `${this.authApiUrl}user-details?email=${tokenDetails.email}`;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + tokenDetails.token
      });
      return this.http.get<UserDetails>(url, { headers, withCredentials: true }).pipe(
        tap(data => {
        themes.current(environment.themes[data.data.theme]);
        document.body.style.backgroundColor = environment.bgColours[data.data.theme];
        localStorage.setItem('userDetails', JSON.stringify(data));
        }),
        take(1)
      );
    }

  // async getConfigData() {
  //   this.configService.getAllConfig().then((data: Config[]) => {
  //     console.log('Config Data: ', data);
  //     sessionStorage.setItem('ConfigData', JSON.stringify(data));
  //   });
  // }

}
