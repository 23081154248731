import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { LoadingService } from '../../report-loading.service';
import { DateService } from 'src/app/services/date.service';
const pdf = pdfMake;
pdf.vfs = pdfFonts.pdfMake.vfs;


interface PromotionProduct {
  product_id: number;
  product_name: string;
  buying: Record<string, string>;  // Assuming 'buying' is an object with dates as keys and string values
  promo: Record<string, string>;   // Same as above for 'promo'
  rebate: Record<string, string>;  // And for 'rebate'
}

interface GroupedPromotions {
  [category: string]: PromotionProduct[];
}

@Component({
  selector: 'app-buy-in-the-green',
  templateUrl: './buy-in-the-green.component.html',
  styleUrls: ['./buy-in-the-green.component.css']
})


export class BuyInTheGreenComponent {

  @Input() name: string;
  @Input() reportComponent: string;
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  //promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  startdate:any;
  buying_group:any;
  disclaimer: string;
  constructor(private crudService: CrudService, private dateService:DateService, private base64Image: Base64Images, private reportStylesService:ReportStylesService,
    private loadingService: LoadingService,
    private groupedProductService: GroupProductService
  )
  {

    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');

  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }

    //
  }

  transformDataForPdfMake(groupedPromotions: any, promotionNamesMap: Record<string, string>): any[] {
    let bodyContent: any[] = [];
    let uniqueDates: any[] = [];
    // Prepare header based on first set of promotions (assumes same dates for all)
  const firstPromotions: any = Object.values(groupedPromotions)[0];
  if (firstPromotions && firstPromotions.length > 0) {
    console.log(firstPromotions);
    uniqueDates = Array.from(new Set(firstPromotions.flatMap((product: PromotionProduct) =>
      [...Object.keys(product.buying ?? {}), ...Object.keys(product.promo ?? {})]
    ))).sort(); // Sorting dates if needed

    bodyContent.push([
      { text: 'Product Code', style:  {bold: true,
        fontSize: 10,
        color: 'white',
        fillColor: '#182351',
        alignment: 'center'} },
      { text: 'Product Description', style: {bold: true,
        fontSize: 10,
        color: 'white',
        fillColor: '#182351',
        alignment: 'center'} },
      ...uniqueDates.map((date: any) => {
    const day = new Date(date).getDate();
    const monthAbbr = new Date(date).toLocaleString('en-us', { month: 'short' }).toUpperCase();
    //const promotionPeriodName = firstPromotions.find((p: any) => p.promo[date]).promotion_period_name || '';
    const promotionPeriodName = promotionNamesMap[date] || '';
    return [
      { text: `${day}`, style: 'dayHeader' },
      { text: `${monthAbbr}`, style: 'monthHeader' },
      { text: `${promotionPeriodName}`, style: 'promoHeader' }
    ];
      })
    ]);
  }

    // Append all categories and products to the same table
    Object.entries(groupedPromotions).sort((a, b) => a[0].localeCompare(b[0])).forEach(([category, promotions]: any) => {
      // Category row within the table
      if(category == 'null'){
        category = 'Not Associated with a Category'
      }
      bodyContent.push([{
        border: [true, true, true, true], borderColor: ['white', 'white', 'white', 'white'],
        text: category,
        style: 'categoryHeader',
        colSpan: 2 + uniqueDates.length,
        alignment: 'left',
      }, ...Array(2 + uniqueDates.length - 1).fill('')]);
  
      promotions.sort((a: any, b: any) => a.product_name.localeCompare(b.product_name));
    
      // Construct rows for each product
      promotions.forEach((product: any) => {
        
        const dataCells = uniqueDates.map((date: any) => {
          const buyingValue = product.buying?.[date] || '0';
          const promoValue = product.promo?.[date] || '0';
          const rebateValue = product.rebate?.[date] || '0';

          let cellText = '';
          let cellStyle = 'baseStyle';

          if (rebateValue !== '0') {
            cellText = 'HB';
            cellStyle = 'rebateStyle';
          } else {
            if (promoValue !== '0') {
              cellText = 'P';
              cellStyle = 'buyingreenPCellStyle';
            }
            if (buyingValue !== '0') {
              cellStyle = 'promoStyle';
            }
            else {
              cellStyle = 'buyinGreenEmptyCell';
            }
          }

          return { border: [true, true, true, true], borderColor: ['white', 'white', 'white', 'white'],text: cellText, style: cellStyle };
        });

        bodyContent.push([
          { text: product.product_id.toString(), style: 'bodyStyle' },
          { text: product.product_name, style: 'bodyStyle' },
          ...dataCells
        ]);
      });
    });
    const subheader = {
      stack: [
        {
          columns: [
            {
              text: `${this.name}`,
              style: 'header',
              alignment: 'left',
            },
            {
              image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
              width: 150,
              alignment: 'right',
              margin: [0, 0, 0, 0]
            }
          ]
        }
      ]
    };

    const secondHeader = {
      stack: [
        {
          columns: [
            {
              text: `All Suppliers`,
              alignment: 'left'
            },
            {
              text: `${this.buying_group}`,
              alignment: 'center'
            },
            {
              text: `From: ${this.startdate}`,
              style: 'subheader',
              alignment: 'right',
              margin: [0, 0, 35, 0]
            }
          ]
        }
      ]
    };
    // Define the whole content as a single table section
    return [
      subheader,
      secondHeader,{

      table: {
        headerRows: 1,
        widths: ['auto', 'auto', ...Array(uniqueDates.length).fill('*')],
        body: bodyContent
      },
      layout: {
        defaultBorder: false,
        hLineWidth: () => 0.1,
        vLineWidth: () => 0.1,

        fillColor: (rowIndex: number) => {
          if(rowIndex === 0){
            return '#182351'
          }
          if(rowIndex % 2 === 0 && rowIndex !== 0) {
            return '#d9f0f1'
          }
          else{ return null }
        }
      }
    }];
  }


  pdfCreator(promotions: any, promotionNamesMap: Record<string, string>) {  // Now requires two parameters
    const transformedDataArray = this.transformDataForPdfMake(promotions, promotionNamesMap);  // Pass both parameters
    const styles: any = this.reportStylesService.getStyles();

    const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'portrait' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `Buy In The Green ${this.startdate}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },
               
                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
    };

    const filename = `BuyInGreen_${new Date().toDateString()}.pdf`;
    pdfMake.createPdf(documentDefinition).download(filename);
    this.loadingService.toggleLoading(false);
    this.sortedUniqueDates = [];
  }


sortedUniqueDates:any = [];
getPromoData(e: any): void {
  this.buying_group = e.buyingGroupFiltered.buying_group_name;
  const buyingGroupId = e.buyingGroupFiltered.id;
  this.startdate = e.buyInGreenDateSelected;
  const weeks = e.weeksCount;
  this.disclaimer = e.disclaimer;
 // Promotion Periods API URL
 const promoPeriodsUrl = `${this.apiUrl}promotion-periods?start_date=${e.buyInGreenDateSelected}&number_of_weeks=${weeks}`;

 // Fetch both datasets concurrently
 Promise.all([
  this.crudService.getData(promoPeriodsUrl).toPromise(),
  this.crudService.getData(`${this.apiUrl}buy-in-green?buying_group_id=${buyingGroupId}&start_date=${e.buyInGreenDateSelected}&weeks=${weeks}`).toPromise()
]).then(([promoPeriodsData, buyingGreenData]) => {
  const promoNamesMap = this.createPromoNamesMap(promoPeriodsData);
  const groupedData = this.groupDataByCategory(buyingGreenData, promoNamesMap);


  this.pdfCreator(groupedData, promoNamesMap);  // Now passing promoNamesMap here as well
}).catch((error: Error) => {
  console.error('Error fetching data:', error);
});
}

createPromoNamesMap(promoPeriodsData: any[]): Record<string, string> {
  let map: Record<string, string> = {};
  promoPeriodsData.forEach(promo => {
    let startDate = new Date(promo.start_date);
    let endDate = new Date(promo.end_date);
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      let formattedDate = date.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
      map[formattedDate] = promo.promotion_name;
    }
  });
  return map;
}



groupDataByCategory(buyingGreenData: any[], promoNamesMap: Record<string, string>): any {
  return buyingGreenData.reduce((acc, obj) => {
    const key = obj.planning_category_name;
    if (!acc[key]) {
      acc[key] = [];
    }
    // Include promotion name from the mapped data
    const promoName = promoNamesMap[obj.start_date];
    obj.promotion_name = 'test'//promoName;
    acc[key].push(obj);
    return acc;
  }, {});
}
@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }

}

