import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LoadOptions } from 'devextreme/data';
import { Observable, retry, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private token = this.cookieService.get('otd-token-temp') ? this.cookieService.get('otd-token-temp') : sessionStorage.getItem('token');
  private headers: HttpHeaders = new HttpHeaders({
    'Authorization': 'Bearer ' + this.token,
    'Accept': 'application/json'
  });
  

  constructor(private http: HttpClient,
    private cookieService: CookieService) {}

    checkTokenLength() {
      if(this.token !== null && this.token.length > 100) {
        this.token = this.cookieService.get('otd-token-temp') ? this.cookieService.get('otd-token-temp') : sessionStorage.getItem('token');
        this.headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.token,
          'Accept': 'application/json'
        });
      } else {
        this.token = this.cookieService.get('otd-token-temp') ? this.cookieService.get('otd-token-temp') : sessionStorage.getItem('token');
        this.headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.token,
          'Accept': 'application/json'
        });
      }
    }

    newGetData(url: string): Observable<any> {
      this.checkTokenLength();
      return this.http.get(url, { headers: this.headers }).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }

    getData(url: string, params?: any): any {
      this.checkTokenLength();
      if(params) {
        params = this.getParams(params);
      }
      return this.http.get(url, { headers: this.headers, params: params}).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }
    getExportData(url: string, params?: any, responseType: 'json' | 'blob' = 'json'): Observable<any> {
      this.checkTokenLength();
      let httpOptions = {
        headers: this.headers,
        params: params ? this.getParams(params) : {}, // Changed from null to {}
        responseType: responseType as 'json' // Cast to 'json' for type compatibility
      };    
      return this.http.get(url, httpOptions as any).pipe(
        retry(3),
        catchError(this.handleError)
      ); // Cast the entire httpOptions as any
    }

    updateData(url: string, id: number|string, data: any) {
      this.checkTokenLength();
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.put(url + '/' + id, data, {headers: this.headers}).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }

    updateDataWithoutId(url: string, data: any) {
      this.checkTokenLength();
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.put(url, data, {headers: this.headers}).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }

    insertData(url: string, data: any) {
      this.checkTokenLength();
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.post(url, data, {headers: this.headers}).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }

    deleteData(url: string, id: number) {
      this.checkTokenLength();
      return this.http.delete(url + '/' + id, {headers: this.headers}).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }

    deleteDataWithBody(url: string, data: any) {
      this.checkTokenLength();
      const httpParams = new HttpParams({ fromObject: data });
      return this.http.post(url, data, {headers: this.headers}).pipe(
        retry(3),
        catchError(this.handleError)
      );
    }

    getParams(loadOptions: LoadOptions)
    {
      const isNotEmpty = (value: unknown) => value !== undefined && value !== null && value !== '';
      let params: HttpParams = new HttpParams();
 
      [
          'filter',
          'group',
          'groupSummary',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',
          'take',
          'totalSummary',
          'userData',
      ].forEach(function (i) {
          const optionValue = loadOptions[i as keyof LoadOptions];
          if (i in loadOptions && isNotEmpty(optionValue)) {
              params = params.set(i, JSON.stringify(optionValue));
          }
      });
      return params;
    }

    private handleError(error: any) {
      console.error('An error occurred:', error);
      return throwError(error);
    }

}
