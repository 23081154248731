import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class CostServiceService {

  constructor() { }

  calculateGpAmount(row: Product): number
  {
    if(row.cost_ex_gst !== null && row.sell_price !== null) {
      let newCost = this.calculateNewUnitCost(row) || 0;
      return this.sellPriceExGST(row.sell_price) - newCost;
    }
    return 0;
  }

  calculateGpPercent(row: Product): number
  {
    if(row.sell_price !== null && row.cost_ex_gst !== null) {
      const ex_gst = this.sellPriceExGST(row.sell_price);
      const gpAmount = this.calculateGpAmount(row);
      return (gpAmount / ex_gst) * 100;
    }
    return 0;
  }

  calculateNewCost(row: Product): number
  {
    let allowance_total = this.calculateAllowanceTotal(row);
    if(row.cost_ex_gst !== null) {
      if(row.has_wet === 1) {
        return (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))) + (0.29 * (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))));
      } else {
        return row.cost_ex_gst - (allowance_total + (+row.allowance_universal));
      }
    }
    return 0;
  }

  calculateNewUnitCost(row: Product): number
  {
    let newCost = this.calculateNewCost(row);
    if(newCost !== 0) {
      return (newCost / row.packs_per_carton) * row.purchase_quantity;
    }
    return 0;
  }

  calculateSellPriceGSTAmount(row: Product): number
  {
    if(row.sell_price !== null) {
      return row.sell_price / 11;
    }
    return 0;
  }

  calculateCostExGst(row: Product)
  {
    if(row.cost_inc_gst !== null && row.cost_inc_gst !== 0) {
      return (row.cost_inc_gst / row.packs_per_carton) / 1.1;
    }
    return undefined;
  }

  getRebateCost(row: Product)
  {
    let rebatedCost = this.calculateNewUnitCost(row);
    let rebateAmountPerPack = this.calculateRebateAmountPerPack(row);
    return rebatedCost - rebateAmountPerPack;
  }

  calculateAllowanceTotal(row: Product)
  {
    let allowance_total = 0;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    } else {
      allowance_total = (+row.allowance_total || 0);
    }
    return allowance_total;
  }

  calculateRebateCost = (row: Product) =>
  {
    return this.getRebateCost(row);
  }

  sellPriceExGST(sell_price: number) {
    return sell_price / 1.1;
  }

  calculateRebateAmountPerPack(row: Product): number
  {
    if(row.rebate !== null && row.rebate !== 0 && row.packs_per_carton !== null && row.packs_per_carton !== 0) {
      return row.rebate / row.packs_per_carton;
    }
    return 0;
  }

  calculateRebateDisplayCost(row: Product): number
  {
    let newCost = this.calculateNewCost(row);
    if(row.rebate !== null && row.rebate !== 0) {
      return newCost - row.rebate;
    }
    return newCost;
  }

  calculateRebateGpAmount = (row: Product): number =>
  {
    let rebateCost = this.getRebateCost(row);
    if(row.sell_price !== null) {
      let sellPrice = row.sell_price;
      return this.sellPriceExGST(sellPrice) - rebateCost;
    }
    return 0;
  }

  calculateRebateGpPercent = (row: Product): number =>
  {
    if(row.sell_price !== null && row.sell_price !== 0) {
      const rebateGpAmount = this.calculateRebateGpAmount(row) || 0;
      const ex_gst = this.sellPriceExGST(row.sell_price);
      return (rebateGpAmount / ex_gst) * 100;
    }
    return 0;
  }
}
