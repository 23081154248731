import { Component,ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { CrudService } from 'src/app/services/crud.service';
import { Event } from 'src/app/services/events.service';
import { RegionsService, Region } from 'src/app/services/regions.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { LocalDataService } from 'src/app/services/local-data.service';
import { DxDataGridComponent, DxDataGridModule, DxTagBoxModule } from 'devextreme-angular';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.css'],
    providers: [DatePipe],
    imports: [ DxDataGridModule, DxTagBoxModule ],
    standalone: true
})
export class EventsComponent {
  dataSource: any;
  regions: Region[] = [];
  editEvents:boolean;
  private apiUrl: string = environment.baseApiUrl + 'events';
  @ViewChild("eventsGrid", { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private crudService: CrudService,
              private regionService: RegionsService,
              private localData: LocalDataService,
              private confirmationService:ConfirmationService,
              private horizontalMenuService:HorizontalMenuService) {
    this.dataSource = new CustomStore<any>({
      key: ['date', 'region', 'event_name'],
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(this.apiUrl).subscribe((result: Event[]) => {
            result = this.formatData(result);
            localStorage.setItem('events', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
          })
        })
    },
    insert: async (values: EventValues) => {
      return new Promise<void>(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.date = this.dateToInteger(values.date);
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;

        // Create an array to hold promises
        const promises = values.region.map(async (region: string) => {
          const clonedValues = { ...values, region }; // Clone values with updated region
          await this.localData.addData('events', clonedValues);
          await this.insertRecord(clonedValues);
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        resolve(); // Resolve the main promise after all iterations are done
      });
    },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        values.date = values.date === undefined ? key.date : values.date;
        values.event_name = values.event_name === undefined ? key.event_name : values.event_name;
        values.region = values.region === undefined ? key.region : values.region;
        values.date = this.dateToInteger(values.date);
        key.date = this.dateToInteger(key.date);
        values.keyData = key;
        console.log(`Key: ${JSON.stringify(key)} Values: ${JSON.stringify(values)}`);
          const result: any = await this.crudService.updateData(this.apiUrl, 1, values)
          .subscribe((result: any) => {
            this.localData.updateData('events', key, values);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        console.log(key);
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        key.group_id = parsedDetails.data.group_id;
        key.location_id = parsedDetails.data.location_id;
        key.date = key.date.replace(/-/g, '');
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteDataWithBody(`${this.apiUrl}-delete`, key)
          .subscribe((result: any) => {
            this.localData.removeData('events', key);
            resolve(result);
          });
        });
      }
    });

    this.regions = this.regionService.getRegions();

    this.horizontalMenuService.editEvents$.subscribe((value: boolean) => {
      this.editEvents = value;
    });
  }

  async insertRecord(values: EventValues) {
    return await new Promise(async (resolve) => {
      await this.crudService.insertData(this.apiUrl, values)
      .subscribe((result: any) => {
        resolve(result);
      });
    });
  }

  formatData(data: Event[]): Event[]
  {
    return data.map((event) => {
      const dateString = event.date.toString();
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      const day = dateString.slice(6, 8);
      const formattedDate = `${year}-${month}-${day}`;
      return {...event, date: formattedDate};
    })

  }

  setStyle(e: any)
  {
    if(e.rowType == "data" && e.row.data.editable === 0) {
      e.cellElement.className += " editable";
    }
  }

  isEditable(e: any): boolean
  {
    return e.row.data.editable === 1;
  }

  calculateFilterExpression(filterValue: string, selectedFilterOperation: any, target: string) {
    console.log(`filter: ${filterValue}`);
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [(this as any).dataField, 'contains', filterValue];
    }
    return function (data: any) {
      return (data.region || []).indexOf(filterValue) !== -1;
    };
  }

  getWeekNumber(newData: any, value: any, currentRowData: any) {
    const dP: DatePipe = new DatePipe('en-AU');
    const formattedDate = dP.transform(value, 'w');
    newData.week_number = formattedDate;
    newData.date = value;
  }

  dateToInteger(date: string | number): number
  {
    const dP: DatePipe = new DatePipe('en-AU');
    return Number(dP.transform(date, 'yyyyMMdd'));
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditEvents(value))
  }
}


export class EventValues
{
    date: number | string;
    event_name: string;
    group_id: number;
    location_id: number;
    region: any;
    week_number: string;
}
