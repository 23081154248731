import { Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { Warehouse } from 'src/app/models/warehouse.model';
import { PromotionType } from 'src/app/models/promotion-type.model';

@Component({
    selector: 'app-promotion-buying-dates',
    templateUrl: './promotion-buying-dates.component.html',
    styleUrls: ['./promotion-buying-dates.component.css'],
    imports: [ DxDataGridModule ],
    standalone: true
})
export class PromotionBuyingDatesComponent {

  private apiUrl: string = environment.baseApiUrl;
  dataSource: any;
  warehouses: Warehouse[] = [];
  promotionTypes: PromotionType[] = [];
  editPromoBuyingDates: boolean = false;
  options: any = { items: ["after", "before"] };

  @ViewChild("promotionBuyingDatesGrid", { static: false }) public dataGrid: DxDataGridComponent;
  constructor(private crudService: CrudService,
    private horizontalMenuService: HorizontalMenuService,
    private confirmationService:ConfirmationService) 
  {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}promotion-buying-dates`).subscribe((result: any) => {
            if(result) {
              this.crudService.getData(`${this.apiUrl}warehouses`).subscribe((result: any) => {
                if(result) {
                  this.warehouses = result;  
                }
                resolve([]);
              });
              this.crudService.getData(`${this.apiUrl}promotion-types`).subscribe((result: any) => {
                if(result) {
                  this.promotionTypes = result;  
                }
                resolve([]);
              });
              resolve(result);
            }
            resolve([]);
          })
        })
    },
      insert: async (values) => {
        return await new Promise((resolve) => {
          this.crudService.insertData(`${this.apiUrl}promotion-buying-dates`, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise((resolve) => {
          this.crudService.updateData(`${this.apiUrl}promotion-buying-dates`, key, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise((resolve) => {
          this.crudService.deleteData(`${this.apiUrl}promotion-buying-dates`, key)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      }
    });
    this.horizontalMenuService.editPromoBuyingDates$.subscribe((value: boolean) => {
      this.editPromoBuyingDates = value;
    });
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditPromoBuyingDates(value));
  }

}
