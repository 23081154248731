<div class="nav_id"><p><u>core-range</u></p></div>
<div class="grid">
<dx-data-grid #coreRangeHeaders [ngClass]="{ 'hidden': coreRangeLoading, 'visible': !coreRangeLoading }"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
  (onContentReady)="contentReadyLoading($event)"
>
<dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-editing
        mode="batch"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
    >
    </dxo-editing>
    <dxo-scrolling mode="virtual" showScrollbar="onHover"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxi-column dataField="buying_group_id" dataType="number" caption="Default Buying Group">
        <dxo-lookup
            [dataSource]="buyingGroups"
            valueExpr="id"
            displayExpr="buying_group_name">
        </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="range_name" dataType="string" caption="Range Name"></dxi-column>
    <dxi-column dataField="start_date" dataType="date" format="dd-MM-yyyy" caption="Start Date"></dxi-column>
    <dxi-column dataField="end_date" dataType="date" format="dd-MM-yyyy" caption="End Date"></dxi-column>
    <dxi-column dataField="status" dataType="integer">
        <dxo-lookup
            [dataSource]="status"
            valueExpr="status"
            displayExpr="name">
        </dxo-lookup>
    </dxi-column>
    <dxi-column type="buttons">
        <dxi-button name="edit" hint="Edit Details"></dxi-button>
        <dxi-button name="delete" hint="Delete Details"></dxi-button>
        <dxi-button name="view" hint="View Details" icon="info" [onClick]="navigateDetails"></dxi-button>
        <dxi-button name="clone" hint="Clone Details" icon="copy" [onClick]="openCloneSelection"></dxi-button>
      </dxi-column>
</dx-data-grid>
</div>

<!--Clone Core Range-->
<dx-popup
  [(visible)]="cloneCoreRangeData"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Clone Range"
  height="auto"
  width="auto"
  minHeight="250px"
  [showCloseButton]="true">
    <app-clone-core-range (cloneCoreRangeValues)="closeCloneSelection()"></app-clone-core-range>
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="coreRangeLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
