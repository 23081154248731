import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgClass, AsyncPipe, CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CrudService } from '../services/crud.service';
import { PromotionProduct } from '../services/promotion-products.service';
import { DxPopupComponent, DxTooltipComponent, DxDataGridModule, DxButtonModule, 
  DxPopupModule, DxTooltipModule, DxTextAreaModule, DxLoadPanelModule } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';
import { Product } from '../models/product.model';
import { PromotionGroup } from '../models/promotion-group.model';
import { RebateNames } from '../models/rebate-names.model';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, lastValueFrom } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { Allowance } from '../models/allowance.model';
import { HorizontalMenuService } from '../services/horizontalmenu.service';
import { on } from "devextreme/events";
import { MixMatchHeader } from '../models/mix-match-header.model';
import { MixMatchLevel } from '../models/mix-match-level.model';
import { MixMatchItem } from '../models/mix-match-item.model';
import { StorePositions } from '../models/store-positions.model';
import { NotifyService } from '../services/notify.service';
import { CostServiceService } from '../services/cost-service.service';
import { DxoColumnChooserComponent } from 'devextreme-angular/ui/nested';
import { Warehouse } from '../models/warehouse.model';
import { MixMatchComponent } from '../settings/mix-match/mix-match.component';
import { MixMatchQtyComponent } from '../settings/mix-match-qty/mix-match-qty.component';

@Component({
    selector: 'app-by-group-details',
    templateUrl: './by-group-details.component.html',
    styleUrls: ['./by-group-details.component.css'],
    imports: [ CommonModule, NgClass, AsyncPipe, DxDataGridModule, DxButtonModule, DxPopupModule, 
      DxTooltipModule, DxTextAreaModule, DxLoadPanelModule, MixMatchComponent, MixMatchQtyComponent],
    standalone: true
})
export class ByGroupDetailsComponent {
  @Input() header: any;
  @Input() fileName: string;
  @Input() key: string;
  @Input() item: any;
  @Output() updateGroupCount = new EventEmitter();
  @Output() editMode = new EventEmitter();
  @ViewChild('productSelection', {static: false}) productSelection: DxPopupComponent;
  @ViewChild('promotionGroupSelection', {static: false}) promotionGroupSelection: DxPopupComponent;
  @ViewChild('mixMatchQty', {static: false}) mixMatchQty: DxPopupComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @ViewChild('columnChooser', { static: false }) columnChooser: DxoColumnChooserComponent;
  @ViewChild('byGroupDetailsGrid', { static: false }) public dataGrid: DxDataGridComponent;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private promoCoreRangeUrl: string = environment.baseApiUrl + 'core-range-details';
  private baseApiUrl: string = environment.baseApiUrl;
  permissions: boolean = true; //Change this logic for when there is permissions on user details for admin users etc.
  dataSource: CustomStore;
  data$: Observable<string[]>;
  warehouses$: Observable<Warehouse[]>;
  promotionGroups$: Observable<PromotionGroup[]>;
  promotionProducts: any;
  storePositions: StorePositions[];
  parent: string = 'promotion-details'
  theme: string;
  promoStartDate:Date;
  promoEndDate:Date;
  detailsLoading: boolean = true;
  allowances: Allowance[] = [];
  rebateNames: RebateNames[] = [];
  selectedRebateColumn: string = '';
  columns: any[] = [];
  promotionDetails: any[] = [];
  productId: number = 0;
  currencyFormat = { type: 'currency', precision: 2 };
  percentageFormat = { type: 'fixedPoint', precision: 2 };
  qty: number = 2;
  nameField: string = '';
  importAllCheckbox: boolean = false;
  MixnMatchProduct: MixnMatchProduct = new MixnMatchProduct();
  price: number = 0;
  mixMitchHeaderId: number = 0;
  mixMatchHeaderName: string = '';
  rowData: PromotionProduct = new PromotionProduct();
  rowKey: number;
  showFilterRow: boolean | null | undefined = false;
  allowEditing: boolean = true;
  disabled: boolean = false;
  storageKey: string = 'byGroupDetailsState';
  isBuilt: boolean = false;
  commentPopupVisible: boolean = false;
  commentValue: string = '';
  rowIndex: number = 0;


  @ViewChild('gridContainer', { static: false }) gridContainer: DxDataGridComponent;

  constructor(
        private crudService: CrudService,
        private horizontalMenuService: HorizontalMenuService,
        private notifyService: NotifyService,
        private costService: CostServiceService,
  ) {
    this.promotionGroups$ = this.crudService.newGetData(`${this.baseApiUrl}promotion-groups`);

    this.warehouses$ = this.crudService.getData(`${this.baseApiUrl}warehouses`);
      
    this.crudService.getData(`${this.baseApiUrl}store-positions`).subscribe((result: any) => {
      this.storePositions = result.data;
    });

    //this.allowances = this.crudService.getData(this.baseApiUrl + 'allowances').toPromise();

    if(localStorage.getItem('selectedPromo') !== null) {
      this.header = JSON.parse(localStorage.getItem('selectedPromo') || '{}');
      this.fetchCoreProducts();
      if(this.header.status_type === 'closed') {
        this.allowEditing = false;
        this.disabled = true;
      }
    }
  }

  ngOnInit(): void {
    this.loadData();
    this.addColumn();
  }

  loadState = () =>
  {
    setTimeout(() => {
      console.log('loading state');
      if (localStorage.getItem(this.storageKey) !== null) {
        const state = JSON.parse(localStorage.getItem(this.storageKey) || '');
        this.dataGrid.instance.state(state);
        return state;
      }
    }, 300);
  }

  saveState = (state: any) => {
    if(this.isBuilt) {
      setTimeout(() => {
        console.log('SaveState');
        const state = this.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
      }, 300);
    }
  }

  onEditingStart(e: any) {
    this.editMode.emit(true);
  }

  onSavedOrCanceled(e: any) {
    this.editMode.emit(false);
  }

// Function to toggle the filter row visibility
toggleFilterRow(): void {
  this.showFilterRow = !this.showFilterRow;
}
  // contentReadyLoading(e:any){
  //   this.detailsLoading = false; // End loading
  // }

  onEditorPreparing(e: any) {
    if(e.parentType === "dataRow")
     e.editorOptions.onFocusIn = function (args: any) {
       args.element.querySelector("input.dx-texteditor-input").select();
     };
  }

  openEditor(value: string, data: any) {
    console.log('value', value);
    console.log('data', data);
    this.commentValue = value;
    this.commentPopupVisible = !this.commentPopupVisible;
  }

  saveComment() {
    console.log('saveComment', this.commentValue);
    this.dataGrid.instance.cellValue(this.rowIndex, 'comment', this.commentValue);
    this.dataGrid.instance.saveEditData();
    this.commentPopupVisible = !this.commentPopupVisible;
  }
  loadData() {
    this.detailsLoading = true;
    this.data$ = this.crudService.newGetData(`${this.promoDetailsUrl}?header_id=${this.header.id}&account_code=${this.header.account_code}&promotion_group_id=${this.key}&sp`)
      .pipe(map((result: any) => {
        if(result.hasOwnProperty('success')) {
          throw new Error(result.message);
        }
        this.promotionProducts = result.data;
        this.detailsLoading = false;
        return result;
      }),
      catchError((error: any) => {
        this.notifyService.tellUser(error);
        this.detailsLoading = false;
        throw new Error(error);
      }));
  }

  rowUpdating(e: any) {
    Object.assign(e.oldData, e.newData);
    if(this.storePositions.length > 0) {
      const position = this.storePositions.find((position: StorePositions) => position.position_name === e.oldData.store_position_name);
      e.oldData.store_position_id = position?.id || null;
      e.oldData.store_position_name = position?.position_name || null;
    }
    const rebateData: any[] = [];
    const keys = Object.keys(e.oldData);
    keys.forEach((key: any) => {
      const rebateDetails = this.rebateNames.find((rebate) => rebate.rebate_name === key);
      if(rebateDetails) {
        rebateData.push({
          rebate_id: rebateDetails.id,
          rebate_amount: e.oldData[key],
          promotion_detail_id: e.oldData.id
        });
      }
    });
    this.rowKey = e.key;
    this.rowData = e.oldData;
    this.rowData.rebates = rebateData;
    this.rowData.by_line = 1;
    lastValueFrom(
      this.crudService.updateData(`${this.promoDetailsUrl}`, e.key, this.rowData).pipe(
        tap((result: any) => {
          this.notifyService.tellUser(result);
        })
      )
    );
  }

  async rowRemoving(e: any) {
    await lastValueFrom(this.crudService.deleteData(`${this.promoDetailsUrl}`, e.key).pipe(
      tap((result: any) => {
        this.notifyService.tellUser(result);
      })
    ));
  }

  async fetchCoreProducts(){
    return await lastValueFrom(this.crudService.getData(`${this.promoCoreRangeUrl}?start_date=${this.header.promotion_start}&end_date=${this.header.promotion_end}&buying_group_id=${this.header.buying_group_id}`))
        .then((coreRange:any) => {
            localStorage.setItem('coreRangeProducts', JSON.stringify(coreRange.data));
        });
  }

  async fetchEdlpProducts(){
    return await lastValueFrom(this.crudService.newGetData(`${this.baseApiUrl}edlp-for-promotion?promotion_header_id=${this.header.id}&buying_group_id=${this.header.buying_group_id}&promotion_group_id=${this.key}`))
        .then((edlpProducts:any) => {
            localStorage.setItem('edlpProducts', JSON.stringify(edlpProducts.data));
        });
  }

  // refreshGrid() {
  //   this.columns = [];
  //   const dataGridDataSource = this.dataGrid.instance.getDataSource();
  //   dataGridDataSource.reload();
  //   this.dataGrid.instance.refresh();
  //   this.cdr.detectChanges();
  // }

  setValues(values: PromotionProduct): PromotionProduct
  {
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');
      values.group_id = parsedDetails.data.group_id;
      values.location_id = parsedDetails.data.location_id;
      values.promotion_header_id = this.header.id;
      if('store_position_name' in values) {
        const position = this.storePositions.find((position) => position.position_name === values.store_position_name);
        values.store_position_id = position?.id || null;
        values.store_position_name = position?.position_name || null;
      }
      if('max_rebate_qty' in values) {
        values.max_rebate_qty = values.max_rebate_qty || null;
      }
      return values;
  }

  addColumn()
  {
    const rebateNames$ = this.crudService.newGetData(`${this.baseApiUrl}rebates?type=0`).pipe(map((rebateNames: any) => { 
      return rebateNames.data.map((rebate: any) => {
        return rebate;
      });
    }));
    this.columns = [];
    rebateNames$.subscribe((rebate: any) => {
      this.rebateNames = rebate;
      rebate.forEach((rebate: any) => {
        this.columns.push({
          dataField: rebate.rebate_name,
          dataType: 'number',
          visable: true,
          format: {
            type: 'currency',
            precision: 2
          }
        });
      });
      this.dataGrid.instance.state(this.loadState());
      this.isBuilt = true;
    });
  }

    onContextMenuPreparing(e: any) {
      if (e.target === 'content' && e.row.data.product_type === 1 && this.allowEditing === true) {
        if (!e.items) e.items = [];
        e.items.push({
          text: 'Create Mix n Match',
          onItemClick: () => this.showMixMatchPopup(e.row.data)
        });
      }
    }

    showMixMatchPopup(rowData: PromotionProduct) {
      this.rowData = rowData;
      this.productId = rowData.product_id;
      this.mixMatchQty.instance.show();
    }

    getQty(qty: number) {
      this.qty = qty;
      console.log('Qty ',this.qty);
    }
    getimportAllCheckbox(value:boolean){
      this.importAllCheckbox = value;
      console.log("checkbox selected: ", this.importAllCheckbox)
    }
    getPrice(price: number) {
      this.price = price;
      console.log('Price ',price);
    }

    getMixMatchHeaderId(mixMatchHeaderId: number) {
      this.mixMitchHeaderId = mixMatchHeaderId;
      console.log('MixMatchHeaderId ',this.mixMitchHeaderId);
    }

    getMixMatchHeaderName(mixMatchHeaderName: string) {
      this.mixMatchHeaderName = mixMatchHeaderName;
      console.log('MixMatchHeaderName ',this.mixMatchHeaderName);
    }
    getMixnMatchName(e:any){
      this.nameField = e;
    }


    getAllPromotionDetails(): Observable<PromotionProduct[]> {
      return this.crudService.getData(`${this.promoDetailsUrl}?header_id=${this.header.id}&account_code=${this.header.account_code}&sp`).pipe(
        tap(data => console.log("API Response:", data)), // Log raw data
        map((response: any) => {
          if (response) {
            return response;
          } else {
            console.error("Invalid or no data:", response);
            return []; // Return an empty array to prevent errors
          }
        })
      );
    }
    mixNMatchItemMatch:PromotionProduct | undefined;
    fetchAllItemsAndProcess(compareId: any): Promise<void> {
      return new Promise((resolve, reject) => {
        this.getAllPromotionDetails().subscribe({
          next: (items) => {
            let found = false;
            if (items) {
              items.forEach(item => {
                if (compareId.product_id == item.product_id) {
                  console.log("Found item: ", item);
                  this.mixNMatchItemMatch = item;
                  found = true;
                }

              });
              if (!found) {
                console.log("No matching items found.");
                this.mixNMatchItemMatch = undefined;
              }
            } else {
              console.log("No items to process.");
              this.mixNMatchItemMatch = undefined;
            }
            resolve();  // Resolve the promise when processing is done
          },
          error: (error) => {
            console.error('Failed to fetch items', error);
            reject(error);  // Reject the promise if there's an error
          }
        });
      });
    }


    createMixnMatch = async () => {
      console.log('Row Data: ', this.rowData);
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');

      const mixMatchHeader:any = new MixMatchHeader();
      mixMatchHeader.group_id = parsedDetails.data.group_id;
      mixMatchHeader.location_id = parsedDetails.data.location_id;
      mixMatchHeader.total_price = this.price;
      if(this.nameField == '' || this.nameField == undefined){
        if (this.importAllCheckbox) {
        mixMatchHeader.name = `${this.qty} x ${this.rowData.promotion_group_name}`;
        }
        else{
          mixMatchHeader.name = `${this.qty} x ${this.rowData.product_name}`;
        }
    }else{
      mixMatchHeader.name = `${this.qty} x ${this.nameField}`;
    }
      try {
          const result:any = await lastValueFrom(this.crudService.insertData(`${this.baseApiUrl}mix-match-headers`, mixMatchHeader));
          console.log(result, "result:")
          if (result.success) {
              const mixMatchLevel = new MixMatchLevel();
              mixMatchLevel.header_id = result.data.id;
              mixMatchLevel.selection_level = 1;
              mixMatchLevel.purchase_quantity = this.qty;
              mixMatchLevel.description = `${this.qty} for ${this.price}`;

              const levelsResult:any = await lastValueFrom(this.crudService.insertData(`${this.baseApiUrl}mix-match-levels`, mixMatchLevel));
              if (levelsResult.success) {
                  const mixMatchItem = new MixMatchItem();
                  mixMatchItem.header_id = result.data.id;
                  mixMatchItem.product_id = this.rowData.product_id;
                  mixMatchItem.selection_level = 1;

                  const itemsResult:any = await lastValueFrom(this.crudService.insertData(`${this.baseApiUrl}mix-match-items`, mixMatchItem));
                  if (itemsResult.success) {
                      if (this.importAllCheckbox) {
                          const promotionGroupProducts:any = await lastValueFrom(this.crudService.getData(`${this.baseApiUrl}promotion-groups-products?promotion_group_id=${this.rowData.promotion_group_id}`));
                          console.log(this.rowData.promotion_group_id);
                          console.log(promotionGroupProducts)
                          for (const product of promotionGroupProducts.data) {
                              const extraMixMatchItem = new MixMatchItem();
                              extraMixMatchItem.header_id = result.data.id;
                              extraMixMatchItem.product_id = product.product_id;
                              extraMixMatchItem.selection_level = 1;
                              await lastValueFrom(this.crudService.insertData(`${this.baseApiUrl}mix-match-items`, extraMixMatchItem));


                              await this.fetchAllItemsAndProcess(product)
                                // Code to execute after fetchAllItemsAndProcess completes

                              if(this.mixNMatchItemMatch != undefined){

                              this.MixnMatchProduct.promotion_header_id = this.mixNMatchItemMatch.promotion_header_id;

                              this.MixnMatchProduct.product_id = this.mixNMatchItemMatch.product_id;

                              this.MixnMatchProduct.product_group_id = this.mixNMatchItemMatch.product_group_id;
                              this.MixnMatchProduct.product_name = this.mixNMatchItemMatch.product_name;
                              this.MixnMatchProduct.sell_price = this.price;
                              this.MixnMatchProduct.allowance = this.mixNMatchItemMatch.allowance;
                              this.MixnMatchProduct.rebate = this.mixNMatchItemMatch.rebate;
                              this.MixnMatchProduct.promotion_group_name = this.mixNMatchItemMatch.promotion_group_name;
                              this.MixnMatchProduct.price_override = this.mixNMatchItemMatch.price_override;
                              this.MixnMatchProduct.comment = this.mixNMatchItemMatch.comment;
                              this.MixnMatchProduct.promotion_group_id = product.promo_group_id;
                              this.MixnMatchProduct.cost_ex_gst = this.mixNMatchItemMatch.cost_ex_gst;
                              this.MixnMatchProduct.cost_inc_wet_only = this.mixNMatchItemMatch.cost_inc_wet_only;
                              this.MixnMatchProduct.allowance_group = this.mixNMatchItemMatch.allowance_group;
                              this.MixnMatchProduct.allowance_promotion = this.mixNMatchItemMatch.allowance_promotion;
                              this.MixnMatchProduct.allowance_universal = this.mixNMatchItemMatch.allowance_universal;
                              this.MixnMatchProduct.allowance_total = this.mixNMatchItemMatch.allowance_total;
                              this.MixnMatchProduct.rebate = this.mixNMatchItemMatch.rebate;
                              this.MixnMatchProduct.max_rebate_qty = this.mixNMatchItemMatch.max_rebate_qty;
                              this.MixnMatchProduct.mix_match_header_id = result.data.id;
                              this.MixnMatchProduct.mix_match_header_name = mixMatchHeader.name;
                              this.MixnMatchProduct.store_position_id = this.mixNMatchItemMatch.store_position_id;
                              this.MixnMatchProduct.store_position_name = this.mixNMatchItemMatch.store_position_name;
                              this.MixnMatchProduct.product_type = 2;
                              this.MixnMatchProduct.slot_type = this.mixNMatchItemMatch.slot_type;
                              this.MixnMatchProduct.packs_per_carton = this.mixNMatchItemMatch.packs_per_carton;
                              this.MixnMatchProduct.purchase_quantity = this.qty;

                              this.rowData.product_type = 2;
                              this.rowData.mix_match_header_id = result.data.id;
                              this.rowData.mix_match_header_name = mixMatchHeader.name;
                              this.rowData.sell_price = this.price;


                              const detailsResult:any = await lastValueFrom(this.crudService.insertData(this.promoDetailsUrl, this.MixnMatchProduct));



                        if (detailsResult.success) {
                          this.mixMatchQty.instance.hide();
                          this.notifyService.tellUser(result); // Consider changing to tellUser(detailsResult) to reflect the successful details operation
                          this.rowData.id = detailsResult.data.id;
                          this.dataGrid.instance.refresh();
                      }
                              }
                              else{
                                this.MixnMatchProduct.promotion_header_id = this.rowData.promotion_header_id;
                                this.MixnMatchProduct.product_id = product.product_id;
                                this.MixnMatchProduct.product_group_id = this.rowData.product_group_id;
                                this.MixnMatchProduct.product_name = product.product_name;

                                this.MixnMatchProduct.sell_price = this.price;
                                this.MixnMatchProduct.allowance = this.rowData.allowance;
                                this.MixnMatchProduct.rebate = this.rowData.rebate;
                                this.MixnMatchProduct.promotion_group_name = this.rowData.promotion_group_name;
                                this.MixnMatchProduct.price_override = this.rowData.price_override;
                                this.MixnMatchProduct.comment = this.rowData.comment;
                                this.MixnMatchProduct.promotion_group_id = this.rowData.promotion_group_id;
                                this.MixnMatchProduct.cost_ex_gst = this.rowData.cost_ex_gst;

                                this.MixnMatchProduct.cost_inc_wet_only = this.rowData.cost_inc_wet_only;
                                this.MixnMatchProduct.allowance_group = this.rowData.allowance_group;
                                this.MixnMatchProduct.allowance_promotion = this.rowData.allowance_promotion;
                                this.MixnMatchProduct.allowance_universal = this.rowData.allowance_universal;
                                this.MixnMatchProduct.allowance_total = this.rowData.allowance_total;
                                this.MixnMatchProduct.rebate = this.rowData.rebate;
                                this.MixnMatchProduct.max_rebate_qty = this.rowData.max_rebate_qty;
                                this.MixnMatchProduct.mix_match_header_id = result.data.id;
                                this.MixnMatchProduct.mix_match_header_name = mixMatchHeader.name;
                                this.MixnMatchProduct.store_position_id = this.rowData.store_position_id;
                                this.MixnMatchProduct.store_position_name = this.rowData.store_position_name;
                                this.MixnMatchProduct.product_type = 2;
                                this.MixnMatchProduct.slot_type = this.rowData.slot_type;
                                this.MixnMatchProduct.packs_per_carton = this.rowData.packs_per_carton;
                                this.MixnMatchProduct.purchase_quantity = this.qty;

                                const detailsResult:any = lastValueFrom(this.crudService.insertData(this.promoDetailsUrl, this.MixnMatchProduct));



                              if (detailsResult.success) {
                                this.mixMatchQty.instance.hide();
                                this.notifyService.tellUser(result); // Consider changing to tellUser(detailsResult) to reflect the successful details operation
                                this.rowData.id = detailsResult.data.id;
                                this.dataGrid.instance.refresh();
                            }
                              }



                          }
                      }
                      else{
                        this.MixnMatchProduct.promotion_header_id = this.rowData.promotion_header_id;
                        this.MixnMatchProduct.product_id = this.rowData.product_id;
                        this.MixnMatchProduct.product_group_id = this.rowData.product_group_id;
                        this.MixnMatchProduct.product_name = this.rowData.product_name;

                        this.MixnMatchProduct.sell_price = this.price;
                        this.MixnMatchProduct.allowance = this.rowData.allowance;
                        this.MixnMatchProduct.rebate = this.rowData.rebate;
                        this.MixnMatchProduct.promotion_group_name = this.rowData.promotion_group_name;
                        this.MixnMatchProduct.price_override = this.rowData.price_override;
                        this.MixnMatchProduct.comment = this.rowData.comment;
                        this.MixnMatchProduct.promotion_group_id = this.rowData.promotion_group_id;
                        this.MixnMatchProduct.cost_ex_gst = this.rowData.cost_ex_gst;

                        this.MixnMatchProduct.cost_inc_wet_only = this.rowData.cost_inc_wet_only;
                        this.MixnMatchProduct.allowance_group = this.rowData.allowance_group;
                        this.MixnMatchProduct.allowance_promotion = this.rowData.allowance_promotion;
                        this.MixnMatchProduct.allowance_universal = this.rowData.allowance_universal;
                        this.MixnMatchProduct.allowance_total = this.rowData.allowance_total;
                        this.MixnMatchProduct.rebate = this.rowData.rebate;
                        this.MixnMatchProduct.max_rebate_qty = this.rowData.max_rebate_qty;
                        this.MixnMatchProduct.mix_match_header_id = result.data.id;
                        this.MixnMatchProduct.mix_match_header_name = mixMatchHeader.name;
                        this.MixnMatchProduct.store_position_id = this.rowData.store_position_id;
                        this.MixnMatchProduct.store_position_name = this.rowData.store_position_name;
                        this.MixnMatchProduct.product_type = 2;
                        this.MixnMatchProduct.slot_type = this.rowData.slot_type;
                        this.MixnMatchProduct.packs_per_carton = this.rowData.packs_per_carton;
                        this.MixnMatchProduct.purchase_quantity = this.qty;

                        this.rowData.product_type = 2;
                        this.rowData.mix_match_header_id = result.data.id;
                        this.rowData.mix_match_header_name = mixMatchHeader.name;
                        this.rowData.sell_price = this.price;

                        const detailsResult:any = await lastValueFrom(this.crudService.insertData(this.promoDetailsUrl, this.MixnMatchProduct));
                        if (detailsResult.success) {
                          this.mixMatchQty.instance.hide();
                          this.notifyService.tellUser(result); // Consider changing to tellUser(detailsResult) to reflect the successful details operation
                          this.rowData.id = detailsResult.data.id;
                          this.dataGrid.instance.refresh();
                      }
                      }
                  }
              }
          }
      } catch (error) {
          console.error("Error during mix and match creation:", error);
          this.notifyService.tellUser("An error occurred while creating mix and match.");
      }
  }

    quickAddMixMatch = async (e: any) => {
      this.rowData.product_type = 2;
      this.rowData.mix_match_header_id = this.mixMitchHeaderId;
      this.rowData.mix_match_header_name = this.mixMatchHeaderName;
      this.rowData.sell_price = this.price;
      const detailsResult: any = await lastValueFrom(this.crudService.insertData(this.promoDetailsUrl, this.rowData));
      if(detailsResult.success) {
        this.mixMatchQty.instance.hide();
        this.notifyService.tellUser(detailsResult);
        this.rowData.id = detailsResult.data.id;
        this.dataGrid.instance.refresh();
      }
    }

    mixMatchPopupEvent(e: any){
      const nativeEvent = e.event || e;
      if (nativeEvent.stopPropagation) {
        nativeEvent.stopPropagation();
      }
      this.horizontalMenuService.seteditMixMatch(true);
    }

  openProductSelection = (e: any) => {
    this.productSelection.instance.show();
  }

  openPromotionGroupSelection = (e: any) => {
    this.promotionGroupSelection.instance.show();
  }

  closeProductSelection = () => {
    this.productSelection.instance.hide();
  }

  closePromotionGroupSelection = () => {
    this.promotionGroupSelection.instance.hide();
  }

  tooltipTimeout: any;  // Declare a class property to hold the timeout ID

  showTooltip(event: MouseEvent): void {
    const tooltip = document.getElementById('tooltip');
    if (tooltip) {
      // Clear any existing timeout to reset the timer
      clearTimeout(this.tooltipTimeout);

      // Set the tooltip's position and display it
      tooltip.style.position = 'fixed';
      tooltip.style.left = `${event.clientX + 15}px`;
      tooltip.style.top = `${event.clientY + 10}px`;
      tooltip.style.display = 'none';

      // Set a new timer to hide the tooltip after 3 seconds
      this.tooltipTimeout = setTimeout(() => {
        if (tooltip) {
          tooltip.style.display = 'block';
        }
      }, 1000);
    }
  }

  hideTooltip(): void {
    const tooltip = document.getElementById('tooltip');
    if (tooltip) {
      tooltip.style.display = 'none';
    }

    // Clear any existing timeout
    clearTimeout(this.tooltipTimeout);
  }

  onRowPrepared(e: any): void {
    const coreRangeProducts = localStorage.getItem('coreRangeProducts');
    const edlpProducts = localStorage.getItem('edlpProducts');
    if (coreRangeProducts !== null) {
      const promotionProducts = JSON.parse(coreRangeProducts);

      if (e.data) {
        const foundProduct = promotionProducts.find((product: any) => {
          return product.product_id === e.data.product_id;
        });

        if (foundProduct) {
          e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
          e.rowElement.style.cssText = 'background-color: rgba(102, 178, 178, .2)';
          e.rowElement.classList.add('core-range-row');

          e.rowElement.addEventListener('mousemove', (event: MouseEvent) => this.showTooltip(event));
          e.rowElement.addEventListener('mouseleave', () => this.hideTooltip());

        }

      }
    }
    if (edlpProducts !== null) {
      const edlpPromotionProducts = JSON.parse(edlpProducts);

      if (e.data) {
        const matchedProduct = edlpPromotionProducts.find((product: any) => {
          return product.product_id === e.data.product_id;
        });

        if (matchedProduct) {
          e.data.edlpPrice = matchedProduct.sell_price;
        }
      }
    }
  }

  onCellPrepared(e: any) {
    if(e.rowType === 'data') {
      if(e.data.product_type == 2 && e.column.dataField == 'product_code') {
        e.cellElement.style.cssText = "background-color:rgb(58, 27, 58); font-weight: bold; border-bottom:unset; color:white;";
        on(e.cellElement, "mouseover", (arg: any) => {
          this.tooltip.instance.show(arg.target);
      });

      on(e.cellElement, "mouseout", (arg: any) => {
        this.tooltip.instance.hide();
      });
      }
    }
    if(e.column.command === 'edit' && e.rowType === 'header') {
      e.cellElement.innerHTML = "<a class='dx-link dx-icon-refresh' id='refresh-link'></a><a class='dx-link dx-icon-columnchooser' id='columnchooser-link'></a>";

    // Add event listener to the link
    const addLink = e.cellElement.querySelector('#columnchooser-link');
      if (addLink) {
        addLink.addEventListener('click', (event: MouseEvent) => {
          this.onAddLinkClick(event);
        });
      }

      const refreshLink = e.cellElement.querySelector('#refresh-link');
      if (refreshLink) {
        refreshLink.addEventListener('click', (event: MouseEvent) => {
          this.onRefreshLinkClick(event);
        });
      }

    }
  }

  onRefreshLinkClick(event: MouseEvent) {
    confirm('Are you sure you want to refresh this promotion group?', 'Refresh Promotion Group Items').then( async (result) => {
      if(result) {
        const data = {
          promotion_header_id: this.header.id,
          promotion_group_id: this.key
        };
        console.log('Item: ', this.item);
        await lastValueFrom(
          this.crudService.updateDataWithoutId(`${this.baseApiUrl}refresh-promotion-group-items`, data).pipe(
            tap((result: any) => {
              this.notifyService.tellUser(result);
              this.loadData();
              this.updateGroupCount.next(this.item);
            })
          )
        );
      }
    });
  }

  onAddLinkClick(event: MouseEvent) {
    event.preventDefault();
    this.dataGrid.instance.option('columnChooser', { enabled: true, mode: 'select'});
    this.dataGrid.instance.showColumnChooser();
  }

  onColumnChooserHidden() {
    console.log('Column chooser hidden');
    this.dataGrid.instance.option('columnChooser', { enabled: false }); // Disable the column chooser
  }

  calculateAllowanceTotal(row: any) {
    const allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance || 0);
    return allowance_total;
  }

  setAllowanceTotal(newData: any, value: any, currentRowData: any): void
  {
    const total = (+currentRowData.allowance_group || 0) + (+currentRowData.allowance_promotion || 0) + (+value || 0);
    newData.allowance = value;
    newData.allowance_total = total;
  }

  setAllowance(newData: any, value: any, currentRowData: any): void
  {
    const amount = (+value || 0) - ((+currentRowData.allowance_group || 0) + (+currentRowData.allowance_promotion || 0));
    newData.allowance_total = value;
    newData.allowance = amount;
  }

  onFocusedCellChanged(e: any) {
    this.rebateNames.forEach((rebate: RebateNames) => {
      if(e.prevColumnIndex !== -1 && e.columns[e.prevColumnIndex].dataField === rebate.rebate_name) {
        localStorage.setItem('selectedRebateColumn', rebate.rebate_name);
      }
    });
  }

  setRebateTotal = (newData: any, value: any, currentRowData: any) =>{
    {
      const rebateColumn = localStorage.getItem('selectedRebateColumn') || '';
      newData[rebateColumn] = value;
      let total: number = 0;
      this.rebateNames.forEach((rebate: RebateNames) => {
          total += (+currentRowData[rebate.rebate_name] || 0);
        });
      newData.rebate = total + value - (+currentRowData[rebateColumn] || 0);
    }
  }

  calculateGpAmount = (row: Product) =>
  {
    return this.costService.calculateGpAmount(row);
  }

  calculateGpPercent = (row: Product) =>
  {
    return this.costService.calculateGpPercent(row);
  }

  calculateNewCost = (row: Product) =>
  {
    return this.costService.calculateNewCost(row);
  }

  calculateCostExGst = (row: Product) =>
  {
    return this.costService.calculateCostExGst(row);
  }

  getRebateCost = (row: Product) =>
  {
    return this.costService.getRebateCost(row);
  }

  calculateRebateCost = (row: Product) =>
  {
    return this.costService.calculateRebateDisplayCost(row);
  }

  sellPriceExGST = (sell_price: number) =>
   {
    return this.costService.sellPriceExGST(sell_price);
  }

  calculateRebateGpAmount = (row: Product) =>
  {
    return this.costService.calculateRebateGpAmount(row);
  }

  calculateRebateGpPercent = (row: Product) =>
  {
    return this.costService.calculateRebateGpPercent(row);
  }

  customiseText = (cellInfo: any) =>
  {
    return cellInfo.valueText + '%';
  }

}
export class MixnMatchProduct{
  promotion_header_id: number | null;;
  product_id: number | null;;
  product_group_id: number | null;;
  product_name: string | null;;
  cost_inc_gst: number | null;;
  sell_price: number | null;;
  allowance: number | null;;
  rebate: number | null;;
  promotion_group_name: string | null;;
  price_override: number | null;;
  comment: string | null;;

  promotion_group_id:number | null;
  cost_ex_gst:number  | null;
  allowance_group:number  | null;
  allowance_promotion:number  | null;
  allowance_universal:number  | null;
  allowance_total:number  | null;

  max_rebate_qty:number  | null;
  mix_match_header_id:number  | null;
  mix_match_header_name:string  | null;
  store_position_id:number  | null;
  store_position_name:string  | null;
  slot_type:number  | null;
  product_type: number | null;
  cost_inc_wet_only: number | null;
  packs_per_carton: number | null;
  purchase_quantity: number | null;
}
