import { Component, OnInit } from '@angular/core';
import { InfoCardsService, InfoCard } from '../services/info-card.service'; // Ensure correct paths are used
import { environment } from 'src/environments/environment';
import { CustomCardComponent } from '../custom-card/custom-card.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    imports: [ CommonModule, CustomCardComponent ],
    standalone: true
})
export class DashboardComponent implements OnInit {
  version: string = environment.version;
  infoCards: InfoCard[] = []; // Local array to hold info cards data
  constructor(private infoCardsService: InfoCardsService) { } // Keep InfoCardsService private

  ngOnInit(): void {
    this.fetchInfoCards();
    
  }

  private fetchInfoCards(): void {
    // Subscribe to the infoCards observable from the service
    this.infoCardsService.infoCards.subscribe(cards => {
      this.infoCards = cards;
    });
    // Trigger fetching of info cards
    this.infoCardsService.fetchInfoCards();
  }
}
