<div class="grid">
  <dx-data-grid #warehouseGrid
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [allowColumnReordering]="false"
    [rowAlternationEnabled]="true"
    [height]="'90%'"
    [loadPanel]="{ enabled: false }"
  >
    <dxo-editing
      mode="batch"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>
    <dxi-column dataField="code" dataType="string" caption="Code" width="15%">
    </dxi-column>
    <dxi-column dataField="name" dataType="string" caption="Name">
      <dxi-validation-rule type="required" message="slotting-type name is required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="address" dataType="string" caption="Address"></dxi-column>
    <dxi-column dataField="city" dataType="string" caption="City"></dxi-column>
    <dxi-column dataField="state" dataType="string" caption="State"></dxi-column>
    <dxi-column dataField="postcode" dataType="string" caption="Postcode"></dxi-column>
    <dxi-column dataField="default" dataType="boolean" caption="Default"></dxi-column>
    <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
  </dx-data-grid>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
