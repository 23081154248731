import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { LoadingService } from '../../report-loading.service';
import { DateService } from 'src/app/services/date.service';
import { FilterComponent } from '../../filter/filter.component';
import { DatePipe } from '@angular/common';

const pdf = pdfMake;
pdf.vfs = pdfFonts.vfs;

@Component({
    selector: 'app-promotion-summary-thirsty',
    templateUrl: './promotion-summary-thirsty.component.html',
    styleUrls: ['./promotion-summary-thirsty.component.css'],
    providers: [ DateService, DatePipe ],
    imports: [ FilterComponent ],
    standalone: true
})
export class PromotionSummaryThirsty {
  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private mediaPlanUrl: string = environment.baseApiUrl + 'media-planning';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  disclaimer: string;
  
  constructor(private crudService: CrudService, 
              private dateService: DateService,
              private groupProductService: GroupProductService,
              private dateFormatService:DateFormatService,
              private base64Image: Base64Images,
              private reportStylesService: ReportStylesService,
              private loadingService: LoadingService)
  {
    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');
  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }
  }
pagePromotion: string = '';
promotionIndex: number = 0;
 transformDataForPdfMake(promotions:any) {
  if (!Array.isArray(promotions)) {
    console.error('Expected an array for promotions, received:', promotions);
    return [];
  }

  let maxRowsPerColumn = 32;
  let output = [];
  let allProducts:any = [];
  let lastPromotionName = '';
  let lastProductSlotType = '';
  const tableBody= [
    [
      { text: 'Product Name', style: 'tableHeader' },
  
      { text: 'Price', style: 'tableHeader' },
      { text: 'Product Name', style: 'tableHeader' },
    
      { text: 'Price', style: 'tableHeader' }
    ]
  ];
 
  // Collect all products into a single array
  promotions.forEach(promotion => {
    if (!promotion || typeof promotion !== 'object' || !promotion.products) {
      console.error('Invalid or missing products for:', promotion);
      return; // Skip this iteration due to invalid data
    }
    console.log("td:",promotion)
    Object.values(promotion.products).forEach((category:any) => {
      category.forEach((product:any) => {
        if (lastPromotionName !== promotion.promotionName) {
          // Insert a "header product" to act as a promotion name row
          allProducts.push({
            isHeader: true,
            promotionName: promotion.promotionName,
            promotionStart: promotion.promotion_start,
            promotionEnd: promotion.promotion_end
          });
          lastPromotionName = promotion.promotionName;
        }
 
        if (lastProductSlotType !== product.slot_type && lastProductSlotType == "LA") {
          // Insert a "header product" to act as a promotion name row
         
          allProducts.push({
            isLA: true,
            promotionName: promotion.promotionName,
            promotionStart: promotion.promotion_start,
            promotionEnd: promotion.promotion_end
          });
        }
        lastProductSlotType = product.slot_type;
        // Add actual product
        allProducts.push({
          ...product,
          promotionName: promotion.promotionName,
          promotionStart: promotion.promotion_start,
          promotionEnd: promotion.promotion_end
        });
      });
    });
  });
  // Process the collected products
  while (allProducts.length > 0) {
    const productsForPage = allProducts.splice(0, maxRowsPerColumn * 2);
    //const tableBody = [];
    
    for (let i = 0; i < maxRowsPerColumn; i++) {
      const leftProduct = productsForPage[i];
      const rightIndex = i + maxRowsPerColumn;
      const rightProduct = rightIndex < productsForPage.length ? productsForPage[rightIndex] : undefined;
      
      if (!leftProduct && rightProduct === undefined) {
        break; // Stop adding rows if there are no more products to display
      }
      const row = [
        {
          text: leftProduct ? (leftProduct.isHeader ? `${leftProduct.promotionName} (${this.dateFormatService.changeDateFormat(leftProduct.promotionStart)} - ${this.dateFormatService.changeDateFormat(leftProduct.promotionEnd)})` : (leftProduct.isLA ? '' : leftProduct.promotion_group_name)) : '',
          style: leftProduct ? (leftProduct.isHeader ? 'promotionSummaryPromotionInTable' : (leftProduct.isLA ? 'separatorStyle':'bodyStyle')) : 'bodyStyle'
        },
        { 
          text: leftProduct && !leftProduct.isHeader &&!leftProduct.isLA ? `${this.formatPriceWithDollar(this.convertPriceToNumber(leftProduct.price))}` : '',
          style: leftProduct ? (leftProduct.isHeader ? 'promotionSummaryPromotionInTable' : (leftProduct.isLA ? 'separatorStyle':'bodyStyle')) : 'bodyStyle',
          alignment: 'center' 
        },
        {
          text: rightProduct ? (rightProduct.isHeader ? `${rightProduct.promotionName} (${this.dateFormatService.changeDateFormat(rightProduct.promotionStart)} - ${this.dateFormatService.changeDateFormat(rightProduct.promotionEnd)})` : (rightProduct.isLA ? '_' : rightProduct.promotion_group_name)) : '',
          style: rightProduct ? (rightProduct.isHeader ? 'promotionSummaryPromotionInTable' : (rightProduct.isLA ? 'separatorStyle':'bodyStyle')) : 'bodyStyle'
        },
        {
          text: rightProduct && !rightProduct.isHeader &&!rightProduct.isLA ? `${this.formatPriceWithDollar(this.convertPriceToNumber(rightProduct.price))}` : '',
          style: rightProduct ? (rightProduct.isHeader ? 'promotionSummaryPromotionInTable' : (rightProduct.isLA ? 'separatorStyle':'bodyStyle')) : 'bodyStyle',
          alignment: 'center'
        }
      ];

      tableBody.push(row);

    }
    maxRowsPerColumn = 37;
    console.log('Headers Length: ',this.headers.length - 1);
    console.log(this.headers);
    console.log('Promotion Index: ',this.promotionIndex);
    if(this.promotionIndex < (this.headers.length - 1) || this.headers.length == 1) {
      if(this.headers.length > 1)
        this.promotionIndex++;

        const subheader = {
          stack: [{
              columns: [
                {
                  text: `${this.name}`,
                  style: 'header',
                  alignment: 'left',
                },
                {
                  image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
                  width: 150,
                  alignment: 'right',
                  margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
                }
              ]
            }]
        };
        const secondHeader = {
          stack: [{
            columns: [
              {
                text: `${this.headers[this.promotionIndex].promotion_name}`,
                style: 'subheader',
                alignment: 'left',
                width: 'auto',
                margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
              },
              {
                text: `${this.selectedFilter}`,
                style: 'subheader',
                alignment: 'right',
                width: 'auto',
                margin: [160, 0, 10, 0]  // Adjust margin as needed for the text
              }
            ]
          }]
        };

        const tableDefinition = {
          table: {
            headerRows: 1,
            widths: [ '*',50,'*',50
            ],
            body: tableBody
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: (i:any) => (i === 2 ? 1 : 0),
            hLineColor: () => 'white',
            vLineColor: () => 'white',
            fillColor: (rowIndex:any) => (rowIndex % 2 === 0 ? '#d9f0f1' : null),
          },
        };
        if (this.promotionIndex === 1 || this.headers.length == 1) {
          output.push(subheader, secondHeader);
          output.push(tableDefinition);
        }
        if (this.promotionIndex < (this.headers.length - 1)) {
          this.promotionIndex++;
        }
    }
}
  return output;
}



convertPriceToNumber(price: string): number {
  return parseFloat(price.replace('$', ''));
}

formatPriceWithDollar(price: number): string {
  return `$${price.toFixed(2)}`;
}







styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}



pdfCreator(promotions: any[]) {
  if (!this.data || this.data.length === 0) {
    console.error('Data is not available');
    return;
  }

  const transformedDataArray = this.transformDataForPdfMake(promotions);
  console.log('transformedDataArray:', transformedDataArray);
  const styles: any = this.reportStylesService.getStyles();


  const documentDefinition:any = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `Promotion Summary ${this.selectedFilter}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },
               
                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };
  this.promotionIndex = 0;
  const filename = `Promotion_Summary_${this.filtered_by_promotion}_${this.selectedFilter}.pdf`;
  pdfMake.createPdf(documentDefinition).download(filename);
  this.loadingService.toggleLoading(false);
}



filtered_by_promotion: string = '';
async getPromoData(e: any) {
  if (e.groupSelected == null) {
    console.log("No data to filter.");
    return;
  }
  this.disclaimer = e.disclaimer;
  await this.crudService.getData(`${this.apiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).toPromise().then((result: any) => {
    const sortedHeaders = result.sort((a:any, b:any) => {
      const descriptionA = a.description || '';
      const descriptionB = b.description || '';
      return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
    });

    this.headers = sortedHeaders;
  
  this.selectedFilter = e.filtered.buying_group_name;
  this.filtered_by_promotion = e.promotionNameSelected;
  this.crudService.getData(`${this.mediaPlanUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&edlp=1`).subscribe((res: any) => {
    console.log("response data:",res)
    const promoDataEDLPChecked = res;
    //const firstHeaderId = res[0].promotion_header_id;
    // const promoDataEDLPChecked = res.map((promoProduct: any) => {
    //     return {
    //       ...promoProduct,
    //       promotion_header_id: promoProduct.promotion_header_id
    //     };
    //   });

      const firstEdlpRecord = promoDataEDLPChecked.find((record: any) => record.edlp === 1);

    if(firstEdlpRecord){
      const EdlpHeader = {
        id: firstEdlpRecord.promotion_header_id,
        description: firstEdlpRecord.promotion_name,
        buying_end: '2024-10-06',
        buying_group_id: e.groupSelected.value,
        buying_start: '2024-09-23',
        created_at: '2024-08-16T03:16:10.000000Z',
        deleted_at: null,
        edlp: 1,
        group_id: 0,
        location_id: 0,
        promotion_end: firstEdlpRecord.promotion_end,
        promotion_name: '2025 TC08',
        promotion_period_id: firstEdlpRecord.promotion_period_id,
        promotion_start: firstEdlpRecord.promotion_start,
        promotion_type: 7,
        rebates: [],
        status: 1,
        status_type: 'open',
        updated_at: '2024-08-16T03:17:19.000000Z'
      }
      this.headers.push(EdlpHeader);
    }
    
    
    if (Array.isArray(res)) {
      this.data = this.groupProductService.groupByUniqueThirsty(promoDataEDLPChecked, sortedHeaders);
      console.log("unique thirsty grouping: ", this.data)
    } else {
      console.error("Expected an array from the service, received:", promoDataEDLPChecked);
      this.data = []; // Set to empty array to prevent errors downstream
    }
    this.pdfCreator(this.data);
  });
});
}

@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }
}

