<div class="grid">
    <dx-data-grid #promotionBuyingDatesGrid
      [dataSource]="dataSource"
      [remoteOperations]="false"
      [allowColumnReordering]="false"
      [rowAlternationEnabled]="true"
      [height]="'90%'"
    >
    <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
            title="Promotion Buying Dates"
            [showTitle]="true"
            [width]="550"
            [height]="260">
        </dxo-popup>
        <dxo-form [colCount]="24">
            <dxi-item dataField="warehouse_id" [colSpan]="12"></dxi-item>
            <dxi-item dataField="promotion_type_id" [colSpan]="12"></dxi-item>
            <dxi-item dataField="start_offset" [colSpan]="5"></dxi-item>
            <dxi-item dataField="start_polarity" editorType="dxSelectBox" [editorOptions]="options" [colSpan]="7"></dxi-item>
            <dxi-item dataField="end_offset" [colSpan]="5"></dxi-item>
            <dxi-item #endDays dataField="end_polarity" editorType="dxSelectBox" [editorOptions]="options" [colSpan]="7"></dxi-item>
        </dxo-form>
      </dxo-editing>
      
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxi-column dataField="warehouse_id" dataType="string" caption="Warehouse">
        <dxo-lookup
            [dataSource]="warehouses"
            valueExpr="id"
            displayExpr="name">
        </dxo-lookup>
        <dxi-validation-rule type="required" message="warehouse is required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="promotion_type_id" dataType="string" caption="Promotion Type">
        <dxo-lookup
            [dataSource]="promotionTypes"
            valueExpr="id"
            displayExpr="name">
        </dxo-lookup>
        <dxi-validation-rule type="required" message="promotion type name is required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="start_offset" dataType="string" caption="Buying Starts">
        <dxi-validation-rule type="required" message="Buying start is required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="start_polarity" dataType="string" caption="Days"></dxi-column>
      <dxi-column dataField="end_offset" dataType="string" caption="Buying Ends">
        <dxi-validation-rule type="required" message="Buying end name is required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="end_polarity" dataType="string" caption="Days"></dxi-column>
    </dx-data-grid>
    
    </div>
