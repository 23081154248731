import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import { PromotionStatuses } from '../settings/promotion-status/promotion-status.component';
import { PromotionProductsService, PromotionProduct } from '../services/promotion-products.service';
import { DxDataGridComponent, DxPopupComponent, DxTooltipComponent } from 'devextreme-angular';
import { PromotionDetailsComponent } from '../promotion-details/promotion-details.component';
import { PromotionPeriodRow } from '../models/promotion-period.model';
import { Event, EventsService } from '../services/events.service';
import { LocalDataService } from 'src/app/services/local-data.service';
import { on } from "devextreme/events";
import { NotifyService } from '../services/notify.service';
import { Router} from '@angular/router';
import { ConfirmationService } from '../services/confirmation.service';
import { DateService } from '../services/date.service';
import { BuyingGroups } from '../models/buying-groups.model';
import { HorizontalMenuService } from '../services/horizontalmenu.service';
import { PromotionService } from '../services/promotions-data-sync.service';
import { Observable } from 'rxjs';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-promotion-header',
  templateUrl: './promotion-header.component.html',
  styleUrls: ['./promotion-header.component.css'],
  providers: [PromotionProductsService]
})
export class PromotionHeaderComponent {
  @ViewChild('promotionHeaders', {static: false}) promotionHeaders: DxDataGridComponent;
  @ViewChild('promoDetailsGrid', {static: false}) promoDetailsGrid: DxDataGridComponent;
  @ViewChild('detailsPopup', {static: false}) detailsPopup: DxPopupComponent;
  @ViewChild('promotionDetailsComponent') promotionDetailsComponent: PromotionDetailsComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  permissions:boolean = true; //Change this value to a value from the users details, to give them permissions on admin view for instance.
  dataSource: any;
  dataSource1: CustomStore;
  childDataSource: DataSource;
  promotionDetails: PromotionProduct[];
  promotionNames: any;
  promotionStatuses: PromotionStatuses[] | any;
  promotionPeriods: any;
  buyingGroups: any;
  promotionTypes: any;
  parentKey: number;
  selectedPromoID:number;
  header: Values;
  detailsTitle: string = '';
  promoDetailInfo: PromoDetailInfo[] = [];
  events: Event[] = [];
  tooltipData: any[] = [];
  tooltipTarget: string = '';
  clonePromotionData: boolean = false;
  rebatePopup: boolean = false;
  promotionsLoading: boolean = false;
  private promotionNamesLocal: any;
  private apiUrl: string = environment.baseApiUrl + 'promotion-headers';
  private baseUrl: string = environment.baseApiUrl;
  promotionPeriodsArray: PromotionPeriod[] = [];
  editRebate: Observable<boolean>;
  currentFilter: any;
  showFilterRow: boolean | null | undefined = false;
  statuses: PromotionStatuses[] = [];
  @ViewChild('gridContainer', { static: false }) gridContainer: DxDataGridComponent;
  //private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  @ViewChild(DxDataGridComponent, { static: false }) public dataGrid: DxDataGridComponent;
  constructor(private crudService: CrudService,
    private promotionProductService: PromotionProductsService,
    private localData: LocalDataService,
    private eventsService: EventsService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dateService: DateService,
    private cdr: ChangeDetectorRef,
    private notifyService: NotifyService,

    private horizontalMenuService: HorizontalMenuService, private promotionService:PromotionService) {
      this.currentFilter = 'auto';
      this.editRebate = this.horizontalMenuService.editRebate$;
      console.log(this.editRebate, "edit rebate")
    this.dataSource = new CustomStore<any>({
      key: 'id',
      cacheRawData: false,

      load: async () => {
        return await new Promise((resolve) => {
          this.promotionsLoading = true;
          this.crudService.getData(this.apiUrl).subscribe((result: any) => {
            localStorage.setItem('promotion-headers', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
            console.log(result, "data source");
          })
          console.log("loading...")

        })
    },
      insert: async (values: Values) => {
        try {
          const userDetails = localStorage.getItem('userDetails');
          const parsedDetails = JSON.parse(userDetails || '{}');
          values.group_id = parsedDetails.data.group_id;
          values.location_id = parsedDetails.data.location_id;
          this.formatDates(values);

          const result = await this.crudService.insertData(this.apiUrl, values).toPromise();
          this.localData.addData('promotion-headers', values);

          //this.notifyService.tellUser(result);
          return result;
        } catch (error:any) {
          // Check for the structure of the error if it's an object and has a message property
          //const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred";
          notify("Error saving data, please confirm fields are not empty.", 'error', 3000)
          this.notifyService.tellUser(`Error: Empty Values `);
          //console.error('Error during insert operation:', error);
          throw error; // This will ensure that the error is not swallowed if further handling is needed
        }
      },
      update: async (key, values: Values) => {
        console.log(values);
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        this.formatDates(values);
          const result: any = await this.crudService.updateData(this.apiUrl, key, values)
          .subscribe((result: any) => {
            this.localData.updateData('promotion-headers', key, values);
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return confirm('Are you sure you want to delete this promotion?<p><b>WARNING!<br>This process is not reversible</b></p><p><b>This will take a few minutes to complete</b></p>', 'Delete Promotion').then(async (result) => {
          if(result) {
            this.promotionsLoading = true;
            try {
              const deleted: any = await this.crudService.deleteData(this.apiUrl, key).toPromise();
              this.notifyService.tellUser(deleted);
            } catch (error) {
              console.error('Error deleting promotion:', error);
            } finally {
              this.promotionsLoading = false;
            }
          } else {
            this.dataGrid.instance.cancelEditData();
          }
        });
      }
    });
    this.promotionNames = {
      store: new CustomStore<any>({
        key:'id',
        load: async () => {
          const response = await this.crudService.getData(this.baseUrl + 'promotion-names').toPromise();
          this.promotionNamesLocal = response.data;
          return response;
        },
        byKey: async (key) => {
          const response = await this.crudService.getData(`${this.baseUrl}promotion-names/${key}`).toPromise();
          return response;
        }
      })
    }
    this.buyingGroups = {
      store: new CustomStore<any>({
        key:'id',
        load: async () => {
          const response = await this.crudService.getData(this.baseUrl + 'buying-groups').toPromise();
          //this.promotionNamesLocal = response.data;
          return response;
        },
        byKey: async (key) => {
          const response = await this.crudService.getData(`${this.baseUrl}buying-groups/${key}`).toPromise();
          return response;
        }
      })
    }
    this.promotionStatuses = {
      store: new CustomStore<any>({
        key:'status',
        load: async () => {
          const response = await this.crudService.getData(this.baseUrl + 'promotion-statuses').toPromise();
          console.log("promotion statuses", response);
          this.statuses = response;
          return response;
        },
        byKey: async (key) => {
          const response = await this.crudService.getData(`${this.baseUrl}promotion-statuses/${key}`).toPromise();
          return response;
        }
      })
    }
    this.promotionTypes = {
      store: new CustomStore<any>({
        key:'id',
        load: async () => {
          const response = await this.crudService.getData(this.baseUrl + 'promotion-types').toPromise();

          return response;
        },
        byKey: async (key) => {
          const response = await this.crudService.getData(`${this.baseUrl}promotion-types/${key}`).toPromise();
          return response;
        }
      })
    }
    this.promotionPeriods = {
      store: new CustomStore<any>({
        key:'id',
        load: async () => {
          const response = await this.crudService.getData(this.baseUrl + 'promotion-periods').toPromise();

          return response;
        }
      })
    }

    this.promotionPeriods.store.load().then((data:any) => {
      this.promotionPeriodsArray = data;
    });
    this.getEvents();


    this.promotionService.refreshGrid$.subscribe(() => {
      this.refreshDataGrid();

    });
  }
  shouldShowRebateButton(rowData:any): boolean {
    return rowData.rebates && rowData.rebates.length > 0;
  }
   // Function to toggle the filter row visibility
   toggleFilterRow(): void {
    this.showFilterRow = !this.showFilterRow;
    this.clearFilter();
  }
  clearFilter() {
    this.gridContainer.instance.clearFilter();
  }
  async getEvents() {
    this.events =  await this.eventsService.getEvents(20230101, 20250101);
  }
  async getRebates(){
    const response = await this.crudService.getData(this.baseUrl + 'rebate-dates').toPromise();
  }
  promotionNamePopupEvent(e: any){
    //prevents sorting from happening while clicki ngbutton in header
    const nativeEvent = e.event || e;
  if (nativeEvent.stopPropagation) {
    nativeEvent.stopPropagation();
  }
    this.horizontalMenuService.seteditPromotionNames(true);


  }
  promotionBuyingGroupPopupEvent(e: any){
    //prevents sorting from happening while clicki ngbutton in header
    const nativeEvent = e.event || e;
  if (nativeEvent.stopPropagation) {
    nativeEvent.stopPropagation();
  }
    this.horizontalMenuService.seteditBuyingGroups(true);

  }
 seteditPromotionPeriods:boolean;
  promotionStartPopupEvent(e: any){
    //prevents sorting from happening while clicki ngbutton in header
    const nativeEvent = e.event || e;
  if (nativeEvent.stopPropagation) {
    nativeEvent.stopPropagation();
  }
    this.horizontalMenuService.seteditPromotionPeriods(true);


  }


  promotionStatusPopupEvent(e: any){
    //prevents sorting from happening while clicki ngbutton in header
    const nativeEvent = e.event || e;
  if (nativeEvent.stopPropagation) {
    nativeEvent.stopPropagation();
  }
    this.horizontalMenuService.seteditStatus(true);

  }
  rebatePopupEvent = (e:any) =>{
    this.horizontalMenuService.seteditRebate(true);
    console.log(e.row.data.id, "data index");
    this.cdr.detectChanges();
    this.selectedPromoID = e.row.data.id;

    this.cdr.detectChanges();

  }


  promotionTypePopupEvent(e: any){
    //prevents sorting from happening while clicki ngbutton in header
    const nativeEvent = e.event || e;
  if (nativeEvent.stopPropagation) {
    nativeEvent.stopPropagation();
  }
    this.horizontalMenuService.seteditPromotionTypes(true);

  }
  contentReadyLoading(event:any){
    this.promotionsLoading = false; // End loading
  }
  formatDates(values: Values): Values
  {
    if(values.buying_end)
      values.buying_end = this.dateService.dateFormat(values.buying_end);
    if(values.buying_start)
      values.buying_start = this.dateService.dateFormat(values.buying_start);
    if(values.promotion_start)
      values.promotion_start = this.dateService.dateFormat(values.promotion_start);
    if(values.promotion_end)
      values.promotion_end = this.dateService.dateFormat(values.promotion_end);

    return values;
  }

  refreshDataGrid() {
    //this.promotionStatuses = localStorage.getItem('promotion-statuses');
    //this.promotionStatuses = JSON.parse(this.promotionStatuses);
    //this.promotionTypes = localStorage.getItem('promotion-types');
    //this.promotionTypes = JSON.parse(this.promotionTypes);
    //this.promotionHeaders.instance.refresh();
    const dataGridDataSource = this.promotionHeaders.instance.getDataSource();
        dataGridDataSource.reload();
    this.dataGrid.instance.refresh();
    this.cdr.detectChanges();
    console.log("refreshing...")
  }

  async filterData(e: any) {
    console.log(`FilterData: ${JSON.stringify(e.data)}`);
    this.promotionDetails = await this.promotionProductService.getPromoProducts(e.data.id);
    console.log(`${JSON.stringify(this.promotionDetails)}`);
    this.childDataSource = await this.getChildDataSource();
    this.childDataSource.load().then((value: any) => this.promotionDetails);
  }

  async getChildDataSource()
  {
    console.log('Get DataSource');
    return new DataSource({
      key: 'id',
      store: await this.getPromoProducts(),
      group: "product_group_name",
      searchOperation: "contains",
      searchExpr: "product_name"
    });
  }

  getPromoProducts(): PromotionProduct[]
  {
    console.log('getPromoProducts');
    return this.promotionDetails;
  }

  clearDataSource()
  {
    console.log('dispose');
    this.childDataSource.dispose();
  }

  navigateDetails(e:any){
    console.log(e);
    const configData = JSON.parse(sessionStorage.getItem('ConfigData') || '[]');
    const detailScreenConfig = configData.find((config: any) => config.config_key === 'DetailsScreen');
    this.header = e.row.data;
    this.buyingGroups.store.byKey(e.row.data.buying_group_id).then((data:any) => {
      console.log('Buying Group: ',data);
      console.log('Account Code: ',data[0].account_code);
      this.header.account_code = data[0].account_code;
      localStorage.setItem('selectedPromo', JSON.stringify(this.header));
      const navigationExtras = {
        state: {
          header: e.row.data
        }
      };
      if(detailScreenConfig) {
        this.router.navigate(['/promotion-details-by-group'], navigationExtras);
      } else {
        this.router.navigate(['/promotion-details'], navigationExtras);
      }
    });
  }

  navigateMediaPlan = (e:any) =>{
    this.header = e.row.data;

    localStorage.setItem('selectedPromo', JSON.stringify(this.header));
    this.router.navigate(['/media-planning']);
  }

  canDeactivate(): Promise<boolean> {
    if (this.dataGrid && this.dataGrid.instance.hasEditData()) {
      return this.confirmationService.confirmUnsavedChangesNavigation();
    } else {
      return Promise.resolve(true);
    }

  }

  loadData()
  {
    this.promotionDetailsComponent.loadData();
  }

  hasEvent(start: string = '2000-01-01', end:string = '2000-01-01'): string {
    let show = 'hidden';
    const startDate = this.createDateObjectFromString(start).getTime();
    const endDate = this.createDateObjectFromString(end).getTime();
    const hasEvent = this.events.some((event: any) => {
      const formatted = this.formatDate(event.date);
      const eventDate = new Date(formatted).getTime();
      return eventDate >= startDate && eventDate <= endDate;
    });
    if (hasEvent) {
      show = 'visible';
    }
  return show;
  }

  eventData(e: any) {
    if(e.rowType !== "header" && e.cellElement.innerText === "E") {
      const startDate = this.createDateObjectFromString(e.data.promotion_start).getTime();
      const endDate = this.createDateObjectFromString(e.data.promotion_end).getTime();
      const hasEvent = this.events.some((event: any) => {
        const eventDate = new Date(this.formatDate(event.date)).getTime();
        return eventDate >= startDate && eventDate <= endDate;
      });
      if (hasEvent) {
        on(e.cellElement, "mouseover", (arg: any) => {
          this.tooltipData = this.events.filter((event: any) => {
            const eventDate = new Date(this.formatDate(event.date)).getTime();
            return eventDate >= startDate && eventDate <= endDate;
          });
          this.tooltipTarget = '#eventColumn';
          this.tooltip.instance.show(arg.target);
        });

        on(e.cellElement, "mouseout", (arg: any) => {
          this.tooltip.instance.hide();
        });
      }
    }
  }


  formatDate(integerDate: number): string {
    const dateStr = integerDate.toString();
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);

    return `${year}-${month}-${day}`;
  }

  displayDate(integerDate: number): string {
    const dateStr = integerDate.toString();
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);

    return `${day}-${month}-${year}`;
  }

  createDateObjectFromString(dateStr:string = '2000-01-01'): Date {
    const dateParts = dateStr.split('-');
    if (dateParts.length === 3) {
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // JavaScript months are 0-indexed
      const day = parseInt(dateParts[2], 10);
      return new Date(year, month, day);
    } else {
      // Handle invalid date format
      return new Date('2023-01-01');
    }
  }

  setDateValue = (newData: Values, value: number) => {
    const promotionPeriod = this.promotionPeriodsArray.find((item: PromotionPeriod) => {
      return item.id === value; // Make sure to return the comparison result.
    });

    newData.promotion_period_id = value;
    newData.promotion_start = promotionPeriod?.start_date || '2000-01-01';
    newData.promotion_end = promotionPeriod?.end_date || '2000-01-01';
    newData.buying_start = this.BuyingDate(promotionPeriod?.start_date || '2000-01-01');
    newData.buying_end = promotionPeriod?.end_date || '2000-01-01';
    newData.status = 1;
    this.cdr.detectChanges();

}

  BuyingDate(startDate: string)
  {
    const date = new Date(startDate);
    date.setDate(date.getDate() - 7);
    return date.toString();
  }

  openCloneSelection= (e: any) => {
    const promotion_name = this.promotionNamesLocal.find((item: any) => item.id === e.row.data.promotion_period_id)?.promotion_name;
    e.row.data.promotion_name = promotion_name;
    if(localStorage.getItem('selected-promotion') !== null) {
      localStorage.removeItem('selected-promotion');
    }
    localStorage.setItem('selected-promotion', JSON.stringify(e.row.data));
    this.clonePromotionData = !this.clonePromotionData;
    this.cdr.detectChanges();
  }


  promotionSelectedID(){
    return this.selectedPromoID;

  }

  closeCloneSelection = () => {
    this.clonePromotionData = false;

    this.refreshDataGrid();
  }

  valueChanged = async(newData: any, value: number, currentRowData: any) => {
    const status = this.statuses.find((item: any) => item.status === value);
    if(value !== currentRowData.status && status?.type === 'closed') {
      confirm('Are you sure you want to change the status? <br> This status will <b>LOCK</b> the promotion and no further changes can be made.<br><p>Only OnTap Data support can unlock the promotion.</p>', 'Change Promotion Status').then((result) => {
        if(result) {
          this.crudService.getData(`${this.baseUrl}check-sell-price-set?promotion_header_id=${currentRowData.id}`).subscribe((result: any) => {
            if(result.data.length > 0) {
              confirm(`There are ${result.data.length} lines with $0 or NULL values, do you want to proceed?`, 'Change Promotion Status').then((result) => {
                if(result) {
                  this.dataGrid.instance.saveEditData();
                } else {
                  this.dataGrid.instance.cancelEditData();
                }
              });
            } else {
              this.dataGrid.instance.saveEditData();
            }
          });
        } else {
          this.dataGrid.instance.cancelEditData();
        }
      });
    }
    newData.status = value;
  }

  onEditingStart = (e: any) => {
    if(e.data.status === 3) {
      e.cancel = true;
    }
  }

}

export class Values
{
    promotion_period_id: number;
    promotion_type: number;
    description: string;
    promotion_start: string;
    promotion_end: string;
    buying_start: string;
    buying_end: string;
    status: number;
    status_type: string;
    group_id: number;
    location_id: number;
    buying_group_id: number;
    buying_group_name?: string;
    account_code?: string;
}
export class PromoDetailInfo{
  title: string;
  start_date: Date | null;
  end_date: Date | null;
}
interface PromotionPeriod {
  id: number;
  start_date: string;
  end_date: string;
}
