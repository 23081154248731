<div class="nav_id"><p>core-range > <u>details</u></p></div>
<app-custom-loader *ngIf="coreRangeDetailsLoading"></app-custom-loader>
<div class="buttonDisplay">
  <div [ngClass]="{ 'hidden': coreRangeDetailsLoading, 'visible': !coreRangeDetailsLoading }" id="core-range-header">
    <dx-button id="back-button" icon="arrowleft" [width]="32" [height]="32"(click)="returnToHeader($event)"></dx-button>
    <p><b>{{header.range_name}}: {{header.start_date}} - {{header.end_date}}</b></p>
  </div>
</div>
<div class="coreRangeDetailsGrid" >
  <dx-data-grid #coreRangeDetails [ngClass]="{ 'hidden': coreRangeDetailsLoading, 'visible': !coreRangeDetailsLoading }"
    [dataSource]="productDataSource"
    [showBorders]="true"
    [columnAutoWidth]="true"
    [rowAlternationEnabled]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [columnResizingMode]="'widget'"
    [wordWrapEnabled]="true"
    [height]="'90%'"
    [width]="'100%'"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
    (onEditingStart)="onEditingStart($event)"
    (onExporting)="onExporting($event)"
  >
    <dxo-editing
      mode="batch"
      [allowUpdating]="true"
      [allowDeleting]="true"
      >
    </dxo-editing>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
    <dxo-column-chooser
      #columnChooser
      [enabled]="true"
      mode="select"
    ></dxo-column-chooser>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="coreRangeDetailsState"
    ></dxo-state-storing>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-toolbar>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="plus" (onClick)="openProductSelection($event)"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="saveButton"></dxi-item>
      <dxi-item name="revertButton"></dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="money" hint="Update Costs" [disabled]="disabled" (onClick)="updateCosts()">
          </dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button hint="Copy Pricing" icon="copy" (onClick)="openCopySelection($event)"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>
    <dxi-column dataField="core_range_header_id" dataType="number" [visible]="false"></dxi-column>
    <dxi-column dataField="product_code" dataType="number" caption="Code" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="product_name" dataType="string" caption="Product Name" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="product_category_name" dataType="string" caption="Category" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="carton_quantity" dataType="number" caption="Carton Qty." [allowEditing]="false"></dxi-column>
    <dxi-column dataField="packs_per_carton" dataType="number" caption="Packs" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="sales_unit" dataType="number" caption="Sales Unit" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="product_size" dataType="number" caption="Volume" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="supplier_code" dataType="string" caption="Supplier Code" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="supplier_name" dataType="string" caption="Supplier" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="ordering_code" dataType="string" caption="Ordering Code" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="barcode" dataType="string" caption="Barcode" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="rebated" dataType="boolean" caption="Rebated" [allowEditing]="false" [visible]="false"></dxi-column>
    <div *dxTemplate="let data of 'rebateTemplate'">
      Rebates
      <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Promotion Rebates" (onClick)="rebatePopupEvent($event)"></dx-button>
    </div>
    <dxi-column caption="Rebates" alignment="center" headerCellTemplate="rebateTemplate">
      <ng-container *ngFor="let column of columns">
        <dxi-column
          [dataField]="column.dataField"
          [name]="column.dataField"
          [format]="currencyFormat"
          [dataType]="column.dataType"
          [allowEditing]="true">
      </dxi-column>
      </ng-container>
    </dxi-column>
    <dxi-column dataField="cost_ex_gst" [format]="currencyFormat" dataType="number" caption="Std Cost ex WET" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="cost_inc_gst" [format]="currencyFormat" dataType="number" caption="Cost Inc Tax" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="cost_inc_wet_only" [format]="currencyFormat" dataType="number" caption="Std Cost"  [allowEditing]="false"></dxi-column>
    <dxi-column dataField="new_cost" [format]="currencyFormat" caption="New Cost" [calculateCellValue]="calculateNewCost" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="nuc" [format]="currencyFormat" caption="NUC" [calculateCellValue]="calculateNUCost" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="sell_price" dataType="number" [allowEditing]="true" [format]="currencyFormat"></dxi-column>
    <dxi-column dataField="gp_percent" [format]="{type: 'fixedPoint', precision: 2}" minWidth="80" caption="GP %" [calculateCellValue]="calculateGpPercent" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="gp_amount" [format]="currencyFormat" minWidth="80" caption="GP $" [calculateCellValue]="calculateGpAmount" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="audit" dataType="boolean" caption="Audit"></dxi-column>
    <dxi-column dataField="liquorfile_number" dataType="number" caption="Liquorfile" [allowEditing]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="account_code" dataType="string" caption="Account" [allowEditing]="false" [visible]="false"></dxi-column>
  </dx-data-grid>
</div>

<!--Product Selection-->
<dx-popup #productSelection
  [(visible)]="productSelectionPopup.editProductSelection"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Products"
  height="75%"
  width="70%"
  [showCloseButton]="true"
  (onHiding)="productSelectionPopup.onHiding($event)">
    <app-product-selection #productSelectionPopup
    (closeChild)="closeProductSelection()"
    (loadParentData)="loadData()"
    [parent]="parent"
    [coreRangeHeaderId]="key"></app-product-selection>
</dx-popup>

<!--Promotion Rebates Setup-->
<dx-popup *ngIf="permissions"
  [(visible)]="promotionRebatesPopup.editPromotionRebates"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Promotion Rebates"
  height="50%"
  width="400px"
  [showCloseButton]="true"
  (onHiding)="promotionRebatesPopup.onHiding($event)">
    <app-promotion-rebates #promotionRebatesPopup (loadParentData)="loadData()"></app-promotion-rebates>
</dx-popup>

<!--Copy Pricing-->
<dx-popup
  [(visible)]="copyPricing"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Copy Pricing"
  height="200px"
  width="300px"
  [showCloseButton]="true">
    <app-copy-core-pricing #copyPricingPopup [copyTo]="key" (cloneCoreRangeValues)="closeCopySelection()" (loadParentData)="loadData()"></app-copy-core-pricing>
</dx-popup>
